import React, { useEffect, useState } from "react";
import "./ProfileInfo.scss";
// import Customcard from "../../components/customcard/Customcard";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { Loader } from "../../components/Loader";
import toast from "react-hot-toast";
import { Modal, Form } from "react-bootstrap";
import { MdDelete, MdAddBox } from "react-icons/md";
import AddDiagnosisModal from "./AddDiagnosisModal";
import AddTherapyModal from "./AddTherapyModal";
import {
  useGetSellerByIdQuery,
  useValidateSellerDataMutation,
  useAddNewTherapyMutation,
  useAddNewDiagnosisMutation,

} from "../../store/api";
import { set } from "date-fns";

const ProfileInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();



  const bidPage = location.state.bidPage;

  const senderId = location?.state?.senderId;

  // const showApproveButton = location.state?.from === "/notification";
  const [pathname, setPathname] = useState(location.pathname);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDiagnosisModal, setShowDiagnosisModal] = useState(false);
  const [newMmolDates, setNewMmolDates] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);



  const [selectedDiagnosis, setSelectedDiagnosis] = useState(null);


  const [updatedDiagnosisDates, setUpdatedDiagnosisDates] = useState({});
  // Therapy Modal States
  const [updatedTherapyDates, setUpdatedTherapyDates] = useState({});
  const [updatedAnalysisDates, setUpdatedAnalysisDates] = useState({});
  const [updatedMmolMolValues, setUpdatedMmolMolValues] = useState({});
  const [showTherapyModal, setShowTherapyModal] = useState(false);
  const [selectedTherapy, setSelectedTherapy] = useState(null);

  const [addNewTherapyMutation] = useAddNewTherapyMutation();
  const [addNewDiagnosisMutation] = useAddNewDiagnosisMutation();

  // const { pathname: previousPathname } = location.state?.referrer || {
  //   pathname: "/",
  // };

  const handleDiagnosisClick = (diagnosis) => {
    setSelectedDiagnosis(diagnosis);
    setShowModal(true);
  };


  useEffect(() => {
    setPathname(location.pathname);

    // You can use the previousPathname here as needed
  }, [location]);

  const addNewMmolDate = () => {
    setNewMmolDates((prev) => [
      ...prev,
      { startDate: "", pointsNumber: "" },
    ]);
  };

  // Function to update new mmol dates
  const updateNewMmolDates = (index, field, value) => {
    setNewMmolDates((prev) => {
      const updatedDates = [...prev];
      updatedDates[index] = {
        ...updatedDates[index],
        [field]: value,
      };
      return updatedDates;
    });
  };




  const { data, isLoading: isLoadingMedicalData } = useGetSellerByIdQuery(senderId);



  // Set state once data is available
  const [diagnosisData, setDiagnosisData] = useState([]);
  const [therapyData, setTherapyData] = useState([]);
  const [showAddTherapyModal, setShowAddTherapyModal] = useState(false);

  console.log("Diagnosis Date:::", diagnosisData)

  // Function to add therapy to the list
  const addTherapy = async (newTherapies) => {
    // Merge the new therapies with the existing ones
    const updatedTherapyList = [...therapyData, ...newTherapies].map((therapy) => ({
      value: therapy.value,
      displayName: therapy.displayName,
      startDate: therapy.startDate,
      endDate: therapy.endDate === true ? true : therapy.endDate,
    }));

    // Set the state to include the newly added therapies
    setTherapyData(updatedTherapyList);

    // Send the updated therapy list to the API
    try {
      await addNewTherapyMutation({
        sellerId: senderId,
        therapyBody: { therapy: updatedTherapyList },  // Send all therapies (existing + new)
      });
      toast.success("Therapy added successfully!");
      setShowAddTherapyModal(false);
    } catch (error) {
      toast.error("Failed to add therapy.");
    }
  };

  const handleUpdateTherapies = async () => {
    // Prepare the updated therapy list
    const updatedTherapyList = therapyData.map((therapy) =>
      therapy.value === selectedTherapy.value
        ? {
          ...therapy,
          startDate: updatedTherapyDates.startDate || therapy.startDate,
          endDate: updatedTherapyDates.isPrevious
            ? updatedTherapyDates.endDate
            : true, // Set endDate to true if it's Current
          displayName: updatedTherapyDates.isPrevious
            ? therapy.displayName.replace("Current", "Previous")
            : therapy.displayName.replace("Previous", "Current"),
        }
        : therapy
    );

    // Update the local state with the modified therapy list
    setTherapyData(updatedTherapyList);

    // Send the updated therapy list to the API
    try {
      await addNewTherapyMutation({
        sellerId: senderId,
        therapyBody: { therapy: updatedTherapyList }, // Send all therapies (updated and existing) to the backend
      }).unwrap();
      toast.success("Therapy updated successfully!");
    } catch (error) {
      toast.error("Failed to update therapy.");
      console.error(error);
    }

    // Close the modal
    setShowTherapyModal(false);
  };


  // useEffect to set the data
  useEffect(() => {
    if (data && !isLoadingMedicalData) {
      setDiagnosisData(data?.clinical?.diagnosis || []);
      setTherapyData(data?.clinical?.therapy || []);
    }
  }, [data, isLoadingMedicalData]);





  const addDiagnosis = async (newDiagnoses) => {
    const updatedDiagnosisList = [...diagnosisData, ...newDiagnoses].map(diagnosis => ({
      displayName: diagnosis.displayName,
      startDate: diagnosis.startDate,
      endDate: diagnosis.endDate === true ? true : diagnosis.endDate,
      bidValue: diagnosis.bidValue,
      value: diagnosis.value,
      analysis: diagnosis.bidValue === "Type 2 Diabetes" ? diagnosis.analysis : []
    }));

    // Send the updated list to the API
    try {
      await addNewDiagnosisMutation({
        sellerId: senderId,
        bodyToSend: { diagnosis: updatedDiagnosisList }
      }).unwrap();

      toast.success('Diagnosis added/updated successfully!');
      setDiagnosisData(updatedDiagnosisList); // Update the state with the new list
      setShowDiagnosisModal(false); // Close the modal after the action
    } catch (error) {
      toast.error('Failed to add/update diagnosis.');
      console.error(error);
    }
  };

  const handleDeleteDiagnosis = async (value) => {
    // Filter out the diagnosis that needs to be deleted
    const updatedDiagnosisData = diagnosisData.filter(diagnosis => diagnosis.value !== value);

    // Prepare the updated list of diagnoses to be sent to the API
    const bodyToSend = {
      diagnosis: updatedDiagnosisData.map(diagnosis => ({
        value: diagnosis.value,
        bidValue: diagnosis.bidValue,
        displayName: diagnosis?.displayName || 'Unknown',
        startDate: diagnosis.startDate,
        endDate: diagnosis.endDate === true ? true : diagnosis.endDate,
        analysis: diagnosis.analysis || [], // Including analysis if present
      })),
    };

    console.log("Body to SEND:::", bodyToSend);

    try {
      // Send the remaining diagnoses to the API
      await addNewDiagnosisMutation({
        sellerId: senderId,
        bodyToSend, // Send updated diagnosis list
      }).unwrap();

      // Show success message
      toast.success('Diagnosis updated successfully!');
      setDiagnosisData(updatedDiagnosisData);
    } catch (error) {
      // Handle the error
      toast.error('Failed to update diagnosis.');
      console.error(error);
    }


  };

  const handleUpdateDiagnosis = async () => {

    // Guard clause to prevent API call if no changes have been made
    if (!hasChanges) {
      return;
    }

    const updatedDiagnosisList = diagnosisData.map((diagnosis) => {
      if (diagnosis.value === selectedDiagnosis.value) {
        return {
          ...diagnosis,
          startDate: updatedDiagnosisDates.startDate || diagnosis.startDate,
          endDate: updatedDiagnosisDates.isPrevious ? updatedDiagnosisDates.endDate : true,
          displayName: updatedDiagnosisDates.isPrevious
            ? diagnosis.displayName.replace("Current", "Previous")
            : diagnosis.displayName.replace("Previous", "Current"),
          analysis: diagnosis.bidValue === "Type 2 Diabetes"
            ? [{
              dates: [
                // Keep existing dates
                ...(diagnosis.analysis?.[0]?.dates || []).map((d, idx) => ({
                  ...d,
                  startDate: updatedAnalysisDates[idx]?.startDate || d.startDate,
                  pointsNumber: updatedMmolMolValues[idx]?.pointsNumber || d.pointsNumber,
                })),
                // Add newly added mmol dates
                ...newMmolDates.map((newDate) => ({
                  startDate: newDate.startDate,
                  pointsNumber: newDate.pointsNumber,
                })),
              ]
            }]
            : [] // Only for Type 2 Diabetes
        };
      }
      return diagnosis;
    });

    // Send the updated list of diagnoses to the API
    try {
      await addNewDiagnosisMutation({
        sellerId: senderId,
        bodyToSend: { diagnosis: updatedDiagnosisList }
      }).unwrap();

      // Update diagnosis data in the state
      setDiagnosisData(updatedDiagnosisList);
      toast.success('Diagnosis updated successfully!');
      setHasChanges(false);
      setShowModal(false); // Close the modal
    } catch (error) {
      toast.error('Failed to update diagnosis.');
      console.error(error);
    }

    // Reset mmol dates after confirming
    setNewMmolDates([]);
  };








  //  Sending Newly Added Diagnosis to the API

  const handleTherapyClick = (therapy) => {
    setSelectedTherapy(therapy);
    setUpdatedTherapyDates({
      startDate: therapy.startDate,
      endDate: therapy.endDate !== true ? therapy.endDate : null,
      isPrevious: therapy.endDate !== true, // Determine if it's Previous or Current
    });
    setShowTherapyModal(true);
  };

  const closeTherapyModal = () => {
    setShowTherapyModal(false);
  };



  const handleDeleteTherapy = async (value) => {
    // Filter out the therapy that needs to be deleted
    const updatedTherapyData = therapyData.filter(therapy => therapy.value !== value);



    // Prepare the updated list of therapies to be sent to the API
    const therapyBody = {
      therapy: updatedTherapyData.map(therapy => ({
        value: therapy.value,
        displayName: therapy?.displayName || 'Unknown',
        startDate: therapy.startDate,
        endDate: therapy.endDate === true ? true : therapy.endDate,
      })),
    };

    // Send the updated therapy list to the API
    try {
      await addNewTherapyMutation({
        sellerId: senderId,
        therapyBody: therapyBody,
      }).unwrap();

      // Show success message
      toast.success('Therapy updated after deletion successfully!');
    } catch (error) {
      // Handle failure
      toast.error('Failed to delete therapy.');
      console.error(error);
    }

    // Update local state to remove the deleted therapy
    setTherapyData(updatedTherapyData);
  };








  const [
    VerifySellerInfo,
    {
      data: verifySellerData,
      isSuccess: verifySellerIsSuccess,
      isError: verifySellerIsError,
      error: verifySellerError,
    },
  ] = useValidateSellerDataMutation();
  useEffect(() => {
    if (verifySellerIsSuccess) {
      toast.success("Seller data validated successfully!");
      setTimeout(() => {
        navigate("/notification");
      }, 1000);
    } else if (verifySellerIsError) {
      toast.error("Error validating seller data. Please try again.");
    }
  }, [verifySellerIsSuccess, verifySellerIsError, navigate]);
  const handleSubmit = async () => {
    setIsLoading(true);  // Set loading state to true

    const dataToSend = {
      senderId,
      bodyToSend: {
        isUserDataVerified: true,
      },
    };

    try {
      await VerifySellerInfo(dataToSend);
    } catch (error) {
      console.error("Error validating seller data:", error);
    } finally {
      setIsLoading(false);  // Set loading state to false after the async call
    }
  };


  const user = useSelector((state) => state.auth?.user?.user);

  function formatDate(dateTimeString) {
    const date = new Date(dateTimeString);
    if (isNaN(date.getTime())) {
      return "Not Available";
    }
    return date.toISOString().split("T")[0];
  }

  // Show skeleton if data is still loading
  if (isLoadingMedicalData) {
    return (
      <div>
        <p>Loading diagnosis and therapy data...</p>
        <div className="skeleton-item"></div>
        <div className="skeleton-item"></div>
      </div>
    );
  }




  return (
    <>
      <div className="flexbox-container">
        <h3 className="headings">General</h3>
        <div className="output-field">
          <div className="label">Name:</div>
          <div className="value" style={{ alignItems: "" }}>
            {
              data?.username
              // ? data?.username : user
            }
          </div>
        </div>

        <div className="output-field">
          <div className="label">Gender:</div>
          <div className="value">
            {
              data?.general?.gender
              //  ? data?.general.gender : user
            }
          </div>
        </div>
        <div className="output-field">
          <div className="label">Country: </div>
          <div className="value">
            {
              data?.general?.country
              //  ? data?.general.country : user
            }
          </div>
        </div>
        <h3 className="headings ">Clinical</h3>
        <div className="output-field-box flex-column">


          <div className=" mb-1 inner-field flex-row ">
            <span className="box-label">
              Diagnosis
            </span>
            <span style={{ cursor: 'pointer' }} onClick={() => setShowDiagnosisModal(true)}>
              <MdAddBox color="#5b99a6" size={30} />
            </span>

          </div>

          <div style={{ width: '40rem', textAlign: 'center' }} className="modal-content">
            {diagnosisData.length ? (
              diagnosisData?.map((i) => (
                <div key={i.value} className="diagnosis-item" onClick={() => handleDiagnosisClick(i)}>
                  {/* <div className="diagnosis-content"> */}
                  <span className="diagnosis-name">{i.displayName || "Unknown Diagnosis"}</span>
                  <MdDelete
                    className="delete-icon"
                    color="#D70040"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent modal from opening on delete click
                      handleDeleteDiagnosis(i.value);
                    }}
                  />
                  {/* </div> */}
                  <div>
                    <span className="diagnosis-dates">
                      {i.startDate ? new Date(i.startDate).toLocaleDateString() : "No Start Date"} -
                      {i?.endDate === true ? " Present" : i.endDate ? new Date(i.endDate).toLocaleDateString() : "No End Date"}
                    </span>
                  </div>

                  {i.analysis?.map((a) => (
                    <div key={a.value} className="">
                      <span className="analysis-label">HbA1c</span>
                      <div className="dates">
                        {a.dates?.map((d, index) => (
                          <div key={index} className="">
                            <span>Date: {d.startDate ? new Date(d.startDate).toLocaleDateString() : "No Date"}</span>
                            <br />
                            mmol/mol: {d.pointsNumber}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              ))
            ) : (
              <p>No Diagnosis found.</p>
            )}
          </div>



        </div>




        {/* <div className="output-field">
          <div className="label">Date Of Diagnosis </div>
          <div className="value">
            {/* {formatDate(data?.clinical?.dateOfDiagnosis)} /}
          </div>
        </div> */}
        {/* <div className="output-field">
          <div className="label">Previous Therapy:</div>
          <div className="value">
            {data?.clinical?.previousTherapy?.therapyName}
          </div>
        </div> */}
        {/* <div className="output-field">
          <div className="label">Start Date:</div>
          <div className="value">
            {formatDate(data?.clinical?.previousTherapy?.startDate)}
          </div>
        </div>

        <div className="output-field">
          <div className="label">End Date:</div>
          <div className="value">
            {formatDate(data?.clinical?.previousTherapy?.endDate)}
          </div>
        </div> */}

        <div className="output-field-box flex-column">
          <div className=" mb-1 inner-field flex-row ">
            <span className="box-label">
              Therapies
            </span>
            <span style={{ cursor: 'pointer' }} onClick={() => setShowAddTherapyModal(true)}>
              <MdAddBox color="#5b99a6" size={30} />
            </span>

          </div>

          <div style={{ width: '40rem', textAlign: 'center' }} className="modal-content">
            {therapyData.length ? (
              therapyData.map((i) => (
                <div
                  key={i.value}
                  className="therapy-item"
                  onClick={() => handleTherapyClick(i)} // Open modal only when the row is clicked
                >
                  {/* <div className="therapy-content"> */}
                  <span className="therapy-name">{i.displayName}</span>
                  <MdDelete
                    color="#D70040"
                    className="delete-icon"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent modal from opening
                      handleDeleteTherapy(i.value);
                    }}
                  />
                  {/* </div> */}
                  <div>
                    <span className="therapy-dates">
                      {new Date(i.startDate).toLocaleDateString()} -
                      {i?.endDate === true ? " Present" : new Date(i.endDate).toLocaleDateString()}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <p>No Therapy found.</p>
            )}
          </div>
        </div>


        {/* <div className="output-field">
          <div className="label">Start Date: </div>
          <div className="value">
            {formatDate(senderId?.clinical?.currentTherapy?.startDate)}
          </div>
        </div> */}

        {!bidPage && (
          <div className="d-grid gap-2 ApproveBtn">
            <Button
              onClick={handleSubmit}
              className="buttonBuy"
              type="submit"
              disabled={isLoading}  // Disable the button while loading
            >
              {isLoading ? (
                <Loader color={"#fff"} />
              ) : (
                "Validate"
              )}
            </Button>
          </div>

        )}
      </div >

      {/* Diagnosis Add Modal */}
      <AddDiagnosisModal
        show={showDiagnosisModal}
        handleClose={() => setShowDiagnosisModal(false)}
        addDiagnosis={addDiagnosis}
        existingDiagnoses={diagnosisData}  // Pass the already fetched diagnoses
      />

      {/* Therapy Add Modal */}
      <AddTherapyModal
        show={showAddTherapyModal}
        handleClose={() => setShowAddTherapyModal(false)}
        addTherapy={addTherapy}
        existingTherapies={therapyData}  // Passing existing therapies to disable pre-selected ones
      />





      {/* Diagnosis Edit Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header style={{ border: 'none' }} closeButton>
          <Modal.Title>Update</Modal.Title>

        </Modal.Header>
        <Modal.Body>
          {selectedDiagnosis && (
            <>
              <div className="diagnosis-section">
                <Form.Group>
                  {/* <Form.Label>Diagnosis</Form.Label> */}
                  <p style={{ fontWeight: 'bold' }}>{selectedDiagnosis.displayName}</p>
                </Form.Group>

                <Form.Group>
                  <Form.Label>From (Start Date)</Form.Label>
                  <Form.Control
                    type="date"
                    defaultValue={new Date(selectedDiagnosis.startDate).toISOString().split('T')[0]}
                    onChange={(e) => {
                      setUpdatedDiagnosisDates((prev) => ({
                        ...prev,
                        startDate: e.target.value,
                      }));
                      setHasChanges(true);  // Mark that a change has occurred
                    }}
                  />

                </Form.Group>

                <Form.Group>
                  <Form.Label>To (End Date)</Form.Label>
                  {updatedDiagnosisDates.isPrevious ? (
                    <>
                      <Form.Control
                        type="date"
                        style={{ marginBottom: '1rem' }}
                        defaultValue={new Date().toISOString().split("T")[0]} // Default to today's date
                        onChange={(e) =>
                          setUpdatedDiagnosisDates((prev) => ({
                            ...prev,
                            endDate: e.target.value,
                          }))
                        }
                      />
                      <span
                        className="toggleButton"
                        onClick={() => {
                          setUpdatedDiagnosisDates((prev) => ({
                            ...prev,
                            isPrevious: false,
                            endDate: null,
                          }))
                          setSelectedDiagnosis((prev) => ({
                            ...prev,
                            displayName: prev.displayName.replace("Previous", "Current"),
                          }))

                        }

                        }

                      >
                        Switch to Current
                      </span>
                    </>
                  ) : (
                    <>
                      <Form.Control
                        type="text"
                        value="Current"
                        style={{ marginBottom: '1rem' }}
                        readOnly
                      />
                      <span
                        className="toggleButton"

                        onClick={() => {
                          setUpdatedDiagnosisDates((prev) => ({
                            ...prev,
                            isPrevious: !prev.isPrevious,
                            endDate: prev.isPrevious ? null : new Date().toISOString().split("T")[0],
                          }));
                          setSelectedDiagnosis((prev) => ({
                            ...prev,
                            displayName: prev.displayName.includes("Previous")
                              ? prev.displayName.replace("Previous", "Current")
                              : prev.displayName.replace("Current", "Previous"),
                          }));
                          setHasChanges(true);  // Mark that a change has occurred
                        }}

                      >
                        Switch to Previous
                      </span>
                    </>
                  )}
                </Form.Group>
              </div>

              {/* Show mmol/mol fields only for Type 2 Diabetes */}
              {(selectedDiagnosis.displayName === "Current - Type 2 Diabetes" || selectedDiagnosis.displayName === "Previous - Type 2 Diabetes")
                && (
                  <div className="analysis-section">
                    {selectedDiagnosis.analysis?.map((analysis, analysisIndex) => (
                      <div key={analysisIndex}>
                        <span style={{ fontWeight: 'bold' }}>HbA1c</span>
                        {analysis.dates?.map((d, dateIndex) => (
                          <div
                            key={dateIndex}
                            className="analysis-content"
                          // style={{ border: "1px solid #ccc", padding: "10px", marginBottom: "10px" }}
                          >

                            <Form.Group>
                              <Form.Label className="analysis-dates">Date of Analysis</Form.Label>
                              <Form.Control
                                type="date"
                                defaultValue={new Date(d.startDate).toISOString().split("T")[0]}
                                onChange={(e) =>
                                  setUpdatedAnalysisDates((prev) => ({
                                    ...prev,
                                    [dateIndex]: {
                                      ...prev[dateIndex],
                                      startDate: e.target.value,
                                    },
                                  }))
                                }
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.Label style={{ color: '#5b99a6', marginTop: '0.3rem', fontWeight: 'bold' }}>mmol/mol</Form.Label>
                              <Form.Control
                                type="number"
                                value={updatedMmolMolValues[dateIndex]?.pointsNumber ?? d.pointsNumber}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (value.length <= 3) {
                                    setUpdatedMmolMolValues((prev) => ({
                                      ...prev,
                                      [dateIndex]: {
                                        ...prev[dateIndex],
                                        pointsNumber: value,
                                      },
                                    }));
                                    setHasChanges(true);  // Mark that a change has occurred
                                  }
                                }}
                              />

                            </Form.Group>
                          </div>
                        ))}
                      </div>
                    ))}

                    {/* New mmol/mol functionality for Type 2 Diabetes */}
                    {newMmolDates.map((newDate, index) => {
                      const analysisCount = selectedDiagnosis.analysis?.[0]?.dates.length || 0;
                      return (
                        <div key={`new-${index}`} className="date-range" style={{ border: "1px solid #ccc", padding: "10px", marginBottom: "10px" }}>
                          <Form.Group>
                            <Form.Label>New Analysis Date {analysisCount + index + 1}</Form.Label>
                            <Form.Control
                              type="date"
                              value={newDate.startDate}
                              onChange={(e) => updateNewMmolDates(index, "startDate", e.target.value)}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>mmol/mol</Form.Label>
                            <Form.Control
                              type="number"
                              value={newDate.pointsNumber}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (value.length <= 3) {
                                  updateNewMmolDates(index, "pointsNumber", value);
                                }
                              }}
                              maxLength="3"
                            />
                          </Form.Group>
                        </div>
                      );
                    })}
                    <span className="custom-button-text" onClick={addNewMmolDate} style={{ color: '#5b99a6', cursor: 'pointer', textDecoration: 'underline', fontWeight: 'bold', textAlign: 'center' }}>Add Another Date +</span>
                  </div>
                )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer style={{ border: 'none' }}>

          <span
            className="confirmBtn"
            onClick={handleUpdateDiagnosis}
            disabled={!hasChanges}  // Disable if no changes have been made
            style={{
              backgroundColor: !hasChanges ? '#ccc' : '#5b99a6',  // Gray out when disabled
              cursor: !hasChanges ? 'not-allowed' : 'pointer',
            }}
          >
            Confirm
          </span>

        </Modal.Footer>


      </Modal>

      {/* Therapies Edit Modal */}
      <Modal show={showTherapyModal} onHide={closeTherapyModal}>
        <Modal.Header style={{ border: 'none' }} closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedTherapy && (
            <>
              <div className="therapy-section">
                <Form.Group>
                  <Form.Label>Therapy Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    defaultValue={new Date(selectedTherapy.startDate).toISOString().split('T')[0]}
                    onChange={(e) =>
                      setUpdatedTherapyDates((prev) => ({
                        ...prev,
                        startDate: e.target.value,
                      }))
                    }
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Therapy End Date</Form.Label>
                  {updatedTherapyDates.isPrevious ? (
                    <>
                      <Form.Control
                        type="date"
                        style={{ marginBottom: '1rem' }}
                        defaultValue={new Date().toISOString().split("T")[0]}
                        onChange={(e) =>
                          setUpdatedTherapyDates((prev) => ({
                            ...prev,
                            endDate: e.target.value,
                          }))
                        }
                      />
                      <span
                        className="toggleButton"
                        variant="link"
                        onClick={() => {
                          setUpdatedTherapyDates((prev) => ({
                            ...prev,
                            isPrevious: false,
                            endDate: null,
                          }))
                          setSelectedTherapy((prev) => ({
                            ...prev,
                            displayName: prev.displayName.replace("Previous", "Current"),
                          }))

                        }
                        }
                      >
                        Switch to Current
                      </span>
                    </>
                  ) : (
                    <>
                      <Form.Control
                        type="text"
                        value="Current"
                        style={{ marginBottom: '1rem' }}
                        readOnly
                      />
                      <span
                        className="toggleButton"
                        variant="link"
                        onClick={() => {
                          setUpdatedTherapyDates((prev) => ({
                            ...prev,
                            isPrevious: true,
                            endDate: new Date().toISOString().split("T")[0], // Set end date to today's date
                          }))
                          setSelectedTherapy((prev) => ({
                            ...prev,
                            displayName: prev.displayName.replace("Current", "Previous"),
                          }))
                        }
                        }
                      >
                        Switch to Previous
                      </span>
                    </>
                  )}
                </Form.Group>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer style={{ border: 'none' }}>

          <span
            className="confirmBtn"
            onClick={handleUpdateTherapies}
          >
            Confirm
          </span>
        </Modal.Footer>
      </Modal>






    </>
  );
};
export default ProfileInfo;
