import React, { useEffect, useState, useRef, useCallback } from "react";
import { Modal, Button } from "react-bootstrap";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import api, {
  useGetBidsQuery,
  useSearchBidQuery,
  useFilterDataMutation,
  useLazyGetAllUsersQuery,
  useGetAllInstitutesQuery,
  useGetAllDepartmentsQuery
} from "../../store/api";
import FilterContent from "./FilterContent";
import BidsListItem from "./BidsListItem";
import "./style.scss";
import { RangeSlider, InputGroup, InputNumber } from "rsuite";
import * as Yup from "yup";
import CustomButton from "../../components/custombutton/CustomButton";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useFormik } from "formik";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch } from "react-redux";
import { AiOutlineReload } from "react-icons/ai";
import Customcard from "../../components/customcard/Customcard";

import { Sidenav, Nav, Dropdown } from "rsuite";
import { de } from "date-fns/locale";

const BidSection = ({ searchTerm, isOpen, selectedMenu, activeIndex }) => {
  const [skip, setSkip] = useState(0);
  const [limitForTotalUsers, setLimitForTotalUsers] = useState(10);
  const [loadingMore, setLoadingMore] = useState(false);
  const PAGE_SIZE = 6;
  const [selectedUserType, setSelectedUserType] = useState("Sharer");
  // const [filteredData, setFilteredData] = useState(null);
  const [filteredResults, setFilteredResults] = useState(null);
  const [selectedRole, setSelectedRole] = useState("seller");
  // const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [showArrowUp, setShowArrowUp] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  const postalCodeRef = useRef(null);
  const countryRef = useRef(null);
  const dateRef = useRef(null);
  const priceRef = useRef(null);
  const diagnosisRef = useRef(null);
  const countryUserRef = useRef(null);
  const postalCodeUserRef = useRef(null);
  const BirthYearRef = useRef(null);
  const diagnosisUserRef = useRef(null);
  const UserOrRequesterRef = useRef(null);
  const [openUserDropdown, setOpenUserDropdown] = useState("");
  const [showArrowUpUser, setShowArrowUpUser] = useState(false);

  const handleUserTypeSelection = (type) => {
    setSelectedUserType(type);
    setSelectedFilter(""); // Reset filter when user type changes
  };

  const params = {
    limit: PAGE_SIZE,
    skip,
  };

  const { data, isError, isLoading } = useGetBidsQuery(params);



  const { data: InstitutesData } = useGetAllInstitutesQuery();
  const { data: DepartmentsData } = useGetAllDepartmentsQuery();


  const [
    FilterData,
    {
      data: filterData,
      isError: filterDataisError,
      isSuccess: filterDataisSuccess,
      isLoading: filterDataisLoading,
      error: filterDataError,
    },
  ] = useFilterDataMutation();

  console.log("Filter data :::::: ", filterData);


  useEffect(() => {
    if (selectedMenu === "User") {
      const valuesToSend = {
        filterType: selectedMenu === "Data" ? "data" : "user",
        filter: {
          role: "seller", // Set default role to 'seller'
        },
      };
      handleFilter(valuesToSend);
    } else if (selectedMenu === "Data") {
      const valuesToSend = {
        filterType: selectedMenu === "Data" ? "data" : "user",
        filter: {},
      };
      handleFilter(valuesToSend);
    }
    setDebouncedSearchTerm('')
  }, [selectedMenu, limitForTotalUsers]);

  const handleRoleSelection = (role) => {
    setSelectedRole(role);
    const valuesToSend = {
      filterType: selectedMenu === "Data" ? "data" : "user",
      filter: {
        role: role, // Use the selected role here
      },
    };
    handleFilter(valuesToSend);
  };

  const handleFilter = async (valuesToSend) => {
    const filteredData = await FilterData(valuesToSend).unwrap();
    // console.log("filteredDataLLLLLLL", filteredData);
    setFilteredResults(filteredData);
  };

  const dispatch = useDispatch();

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  // const [value, setValue] = useState([10, 50]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 1000);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchTerm]);

  useEffect(() => {
    setSkip(0); // Reset skip when searchTerm changes
  }, [debouncedSearchTerm]);

  useEffect(() => {
    return () => {
      dispatch(api.util.resetApiState());
    };
  }, []);

  const handleLoadMore = async () => {
    if (!isLoading && !isError) {
      setLoadingMore(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setSkip((prevSkip) => prevSkip + PAGE_SIZE);
      setLoadingMore(false);
    }
  };




  useEffect(() => {
    const handleClickOutside = (event) => {
      console.log("in outside???????");
      if (
        postalCodeRef.current &&
        !postalCodeRef.current.contains(event.target) &&
        countryRef.current &&
        !countryRef.current.contains(event.target) &&
        dateRef.current &&
        !dateRef.current.contains(event.target) &&
        priceRef.current &&
        !priceRef.current.contains(event.target)
      ) {
        setSelectedFilter("");
        setShowArrowUp(false);
      } else if (
        countryUserRef.current &&
        !countryUserRef.current.contains(event.target) &&
        postalCodeUserRef.current &&
        !postalCodeUserRef.current.contains(event.target) &&
        BirthYearRef.current &&
        !BirthYearRef.current.contains(event.target) &&
        UserOrRequesterRef.current &&
        !UserOrRequesterRef.current.contains(event.target)
      ) {
        setSelectedFilter("");
        setShowArrowUp(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [isOpenCountry, setIsOpenCountry] = useState(false);

  const dropdownMedicalRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownMedicalRef.current &&
        !dropdownMedicalRef.current.contains(event.target)
      ) {
        setIsOpenCountry(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      if (dropdownMedicalRef.current) {
        document.removeEventListener("click", handleOutsideClick);
      }
    };
  }, [dropdownMedicalRef]);


  const handleSubOptionClick = (type, item) => {
    const currentSelected = formik.values[`selected${type}`] || [];
    const updatedSelection = currentSelected.includes(item)
      ? currentSelected.filter((i) => i !== item)
      : [...currentSelected, item];

    formik.setFieldValue(`selected${type}`, updatedSelection);

    if (updatedSelection.includes(item)) {
      setSelectedSubOptions([...selectedSubOptions, item]);
    } else {
      setSelectedSubOptions(selectedSubOptions.filter((i) => i !== item));
    }

  };


  const handleSubmit = async (values) => {
    console.log("submitting form", values);
    const {
      priceRange,
      selectedTherapies,
      selectedApplications,
      selectedQuestionnaires,
      selectedAnalysis,
      institution,
      department,


    } = values;
    const [minPrice, maxPrice] = priceRange;

    const valuesToSend = {
      filterType: selectedMenu === "Data" ? "data" : "user",
      filter: {
        // diagnosis:
        //   values.DiagnosisOptions && values.DiagnosisOptions.length > 0
        //     ? values.DiagnosisOptions
        //     : undefined,
        diagnosis: values.diagnosis || undefined,
        priceFrom: minPrice,
        priceTo: maxPrice,
        country: values.country || undefined,
        DateFrom: values.fromDate || undefined,
        toDate: values.toDate || undefined,
        postalCodeFrom: values.postalCodeFrom || undefined,
        postalCodeTo: values.postalCodeTo || undefined,
        birthYearFrom: values.birthYearFrom || undefined,
        birthYearTo: values.birthYearTo || undefined,
        therapies: selectedTherapies && selectedTherapies.length > 0 ? selectedTherapies : undefined,
        applications: selectedApplications && selectedApplications.length > 0 ? selectedApplications : undefined,
        questionnaires: selectedQuestionnaires && selectedQuestionnaires.length > 0 ? selectedQuestionnaires : undefined,
        analysis: selectedAnalysis && selectedAnalysis.length > 0 ? selectedAnalysis : undefined,

        // role: selectedRole,
      },
    };

    // Conditionally include the institution and department if they have values
    if (institution && institution.length > 0) {
      valuesToSend.filter.institution = [institution];
    }

    if (department && department.length > 0) {
      valuesToSend.filter.department = [department];
    }

    // Conditionally include the role if neither institution nor department is present
    if (!(institution && institution.length > 0) && !(department && department.length > 0)) {
      valuesToSend.filter.role = selectedRole;
    }




    console.log("valuesToSend", valuesToSend);

    // if (filterData !== null) {
    console.log("Sending: ", valuesToSend);
    try {
      const filteredData = await FilterData(valuesToSend).unwrap();
      console.log("filteredData", filteredData);
      setFilteredResults(filteredData);
    } catch (error) {
      console.error("Error fetching filtered data: ", error);
    }
    // }
  };


  useEffect(() => {
    if (filterDataisSuccess) {
      console.log("filterData", filterData);
    } else if (filterDataisError) {
      console.log("Errrooror::", filterDataError.message);
    }
  }, [filterDataisSuccess]);

  const validationSchema = Yup.object().shape({
    postalCodeFrom: Yup.string().matches(
      /^\d{4}$/,
      "Postal Code must be 4 digits"
    ),
    postalCodeTo: Yup.string().matches(
      /^\d{4}$/,
      "Postal Code must be 4 digits"
    ),
    birthYearFrom: Yup.string().matches(
      /^\d{4}$/,
      "Postal Code must be 4 digits"
    ),
    birthYearTo: Yup.string().matches(
      /^\d{4}$/,
      "Postal Code must be 4 digits"
    ),
  });

  const handleReset = async () => {
    formik.resetForm();
    setSelectedSubOptions([]);
    // setFilteredResults(null);
    setDebouncedSearchTerm(""); // Clear any search term to refetch the original data
    const valuesToSend = {
      filterType: selectedMenu === "Data" ? "data" : "user",
      filter: {
        role: "seller",
      },
    };
    handleFilter(valuesToSend);
  };

  const initialValues = {
    priceRange: [10, 100000],
    country: "",
    postalCodeFrom: "",
    diagnosis: "",
    postalCodeTo: "",
    DiagnosisOptions: [],
    fromDate: "",
    toDate: "",
    institution: [],
    department: [],
    selectedTherapies: [],
    selectedApplications: [],
    selectedQuestionnaires: [],
    selectedAnalysis: [],
  };


  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
  });

  const medicalArray = [
    "Therapies",
    "Applications",
    "Questionnaires",
    "Analysis",
    "Country",
    "Institution",
    "Department",
  ];

  const dropdownMedical = [
    ["Pharmaceutical", "Surgical", "Psychological", "Rehabilitation"],
    ["Health", "Location"],
    ["Quality"],
    ["Analysis"],
    [], // Country of Residence
    [], // Institution
    [], // Department
  ];

  const subOptions = {
    Pharmaceutical: ["Tablet", "Injection", "Infusion"],
    Surgical: ["Scalpel Incisions", "Laparoscopic", "Suture Closure"],
    Psychological: [
      "Cognitive Behavioral Therapy (CBT)",
      "Humanistic Therapy",
      "Psychodrama",
    ],
    Rehabilitation: ["Rehab1", "Rehab2"],
    Health: ["Steps", "FlightsClimbed", "Sleep", "Elevation"],
    Location: [
      "Raw",
      "Activity segments",
      "Place visits",
      "Number of places visited",
    ],
    Quality: ["SF-36", "MSQOL-54"],
    Analysis: ["Genetic", "Radiology", "Lab"],
  };




  const [selectedMedicalItem, setSelectedMedicalItem] = useState(null);
  const [selectedDropdownItem, setSelectedDropdownItem] = useState(null);
  const [subOptionsContent, setSubOptionsContent] = useState([]);
  const [selectedSubOptions, setSelectedSubOptions] = useState([]);

  const handleFilterClick = useCallback(
    (filter) => {

      if (selectedMenu === "Data" && activeIndex === 1) {


        // setSelectedMedicalItem(filter);
        // setSubOptionsContent(dropdownMedical[medicalArray.indexOf(filter)] || []);
        // setSelectedDropdownItem(null); // Reset the selected dropdown item
        // setShowModal(true);
        setOpenDropdown((prevOpenDropdown) =>
          prevOpenDropdown === filter ? null : filter
        );

        setSelectedMedicalItem((prevOpenDropdown) =>
          prevOpenDropdown === filter ? null : filter
        );
        setSubOptionsContent(dropdownMedical[medicalArray.indexOf(filter)] || []);
        setSelectedDropdownItem(null); // Reset the selected dropdown item

      } else {
        if (filter === selectedFilter) {
          setSelectedFilter("");
          setShowArrowUp(!showArrowUp);
        } else {
          setSelectedFilter(filter);
          setShowArrowUp(true);
        }
      }
    },
    [dropdownMedical, medicalArray, selectedMenu, activeIndex, selectedFilter, showArrowUp]

  );

  const searchResult = useSearchBidQuery({
    query: debouncedSearchTerm,
    identifier: selectedMenu
  }, {
    skip: debouncedSearchTerm === "",
  });

  const results = debouncedSearchTerm ? searchResult.data : filterData;
  const hasMoreBids = results?.length >= skip + PAGE_SIZE;

  const handleUserFilterClick = useCallback(
    (filter) => {
      if (selectedMenu === "User" && activeIndex === 1) {
        if (filter === openUserDropdown) {
          setOpenUserDropdown("");
          setShowArrowUpUser(false);
        } else {
          setOpenUserDropdown(filter);
          setShowArrowUpUser(true);
        }
        setOpenDropdown(""); // Close Data dropdowns when User is selected
        setShowArrowUp(false); // Close Data arrows when User is selected
      }
    },
    [selectedMenu, activeIndex, openUserDropdown, showArrowUpUser]
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check for Data dropdowns
      if (openDropdown && !document.getElementById(openDropdown)?.contains(event.target)) {
        setOpenDropdown("");
        setShowArrowUp(false);
      }
      // Check for User dropdowns
      if (openUserDropdown && !document.getElementById(openUserDropdown)?.contains(event.target)) {
        setOpenUserDropdown("");
        setShowArrowUpUser(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openDropdown, openUserDropdown]);


  return (
    <section className="bids_sec">
      <Container>
        {selectedMenu === "User" ? (
          <div className="data-requests">
            <h1>Users</h1>
          </div>
        ) : (
          <div className="data-requests">
            <h1>Data Requests</h1>
          </div>
        )}
        {selectedMenu === "Data" && activeIndex === 1 && (
          <div className="filters-wrapper">
            <div className="filters">
              <div className="inside-filter">


                {medicalArray.map((item, index) => (
                  <div key={index} className="data-filters" id={item}>
                    <p onClick={() => handleFilterClick(item)}>
                      {item}{" "}
                      {openDropdown === item ? <IoIosArrowUp /> : <IoIosArrowDown />}
                    </p>
                    {openDropdown === item && (
                      <FilterContent
                        item={item}
                        subOptionsContent={subOptionsContent}
                        selectedSubOptions={selectedSubOptions}
                        handleSubOptionClick={handleSubOptionClick}
                        applyFilters={formik.handleSubmit}
                        handleReset={handleReset}
                        subOptions={subOptions}
                        institutesData={InstitutesData}
                        departmentsData={DepartmentsData}
                        selectedDropdownItem={selectedDropdownItem}
                        formik={formik}
                        setSelectedDropdownItem={setSelectedDropdownItem}
                      />
                    )}
                  </div>
                ))}
                <div ref={diagnosisRef} className="data-filters" id="Data-Diagnosis">
                  <p onClick={() => handleFilterClick("Diagnosis")}>
                    Diagnosis{" "}
                    {selectedMedicalItem === "Diagnosis" ? (
                      showArrowUp ? (
                        <IoIosArrowUp />
                      ) : (
                        <IoIosArrowDown />
                      )
                    ) : (
                      <IoIosArrowDown />
                    )}
                  </p>
                  {selectedMedicalItem === "Diagnosis" && (
                    <div className="content">
                      <Form
                        onSubmit={formik.handleSubmit}
                        className=""
                        style={{ padding: "25px" }}
                      >
                        <Row>
                          <Col xs={12}>
                            <Form.Group>
                              {/* <Form.Label>Diagnosis</Form.Label> */}
                              <Form.Select
                                name="diagnosis"
                                id="diagnosis"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.diagnosis}
                              >
                                <option value="" disabled>
                                  Select a diagnosis
                                </option>
                                <option value="Healthy">Healthy</option>
                                <option value="Multiple Sclerosis – Relapsing Remitting">
                                  Multiple Sclerosis – Relapsing Remitting
                                </option>
                                <option value="Multiple Sclerosis – Secondary Progressive">
                                  Multiple Sclerosis – Secondary Progressive
                                </option>
                                <option value="Multiple Sclerosis – Primary Progressive">
                                  Multiple Sclerosis – Primary Progressive
                                </option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col xs={6}>
                            <CustomButton
                              className="Btn1"
                              label={"Save"}
                              color={"#333333"}
                              textcolor={"white"}
                              width={"100%"}
                              backgroundColor={"#333333"}
                              type="submit"
                            />
                          </Col>
                          <Col xs={6}>
                            <CustomButton
                              className="btn1"
                              label={"Reset"}
                              color={"#333333"}
                              textcolor={"black"}
                              width={"100%"}
                              backgroundColor={"white"}
                              border={"2px solid black"}
                              onClick={handleReset}
                            />
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  )}
                </div>

                <div ref={priceRef} className="data-filters" id="Price">
                  <p onClick={() => handleFilterClick("Price")}>
                    Price{" "}
                    {selectedMedicalItem === "Price" ? (
                      showArrowUp ? (
                        <IoIosArrowUp />
                      ) : (
                        <IoIosArrowDown />
                      )
                    ) : (
                      <IoIosArrowDown />
                    )}
                  </p>
                  {selectedMedicalItem === "Price" && (
                    <div className="content">
                      <Form
                        onSubmit={formik.handleSubmit}
                        className=""
                        style={{ padding: "25px" }}
                      >
                        <Row>
                          <Col xs={12}>
                            <Form.Group controlId="priceRange" name="priceRange">
                              <Form.Label>Price Range</Form.Label>
                              <RangeSlider
                                progress
                                style={{ marginTop: 16 }}
                                value={formik.values.priceRange}
                                onChange={(value) => {
                                  formik.setFieldValue("priceRange", value);
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12}>
                            <Form.Group
                              controlId="priceInputs"
                              style={{ marginTop: "5vh" }}
                            >
                              <InputGroup>
                                <InputNumber
                                  value={formik.values.priceRange[0]}
                                  onChange={(nextValue) => {
                                    const [start, end] = formik.values.priceRange;
                                    if (nextValue > end) {
                                      return;
                                    }
                                    formik.setFieldValue("priceRange", [
                                      nextValue,
                                      end,
                                    ]);
                                  }}
                                />
                                <InputGroup.Addon>to</InputGroup.Addon>
                                <InputNumber
                                  value={formik.values.priceRange[1]}
                                  onChange={(nextValue) => {
                                    const [start, end] = formik.values.priceRange;
                                    if (
                                      start > nextValue ||
                                      nextValue > 100000
                                    ) {
                                      return;
                                    }
                                    formik.setFieldValue("priceRange", [
                                      start,
                                      nextValue,
                                    ]);
                                  }}
                                />
                              </InputGroup>
                            </Form.Group>
                          </Col>
                          <Col xs={6}>
                            <CustomButton
                              className="Btn1"
                              label={"Save"}
                              color={"#333333"}
                              textcolor={"white"}
                              width={"100%"}
                              backgroundColor={"#333333"}
                              type="submit"
                            />
                          </Col>
                          <Col xs={6}>
                            <CustomButton
                              className="btn1"
                              label={"Reset"}
                              color={"#333333"}
                              textcolor={"black"}
                              width={"100%"}
                              backgroundColor={"white"}
                              border={"2px solid black"}
                              onClick={handleReset}
                            />
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  )}
                </div>
                {/* <div ref={countryRef} className="data-filters" id="Country of Residence">
                  <p onClick={() => handleFilterClick("Country of Residence")}>
                    Country of Requestsser{" "}
                    {selectedMedicalItem === "Country of Residence" ? (
                      showArrowUp ? (
                        <IoIosArrowUp />
                      ) : (
                        <IoIosArrowDown />
                      )
                    ) : (
                      <IoIosArrowDown />
                    )}
                  </p>
                  {selectedMedicalItem === "Country of Residence" && (
                    <div className="content">
                      <Form
                        onSubmit={formik.handleSubmit}
                        className=""
                        style={{ padding: "25px" }}
                      >
                        <Row>
                          <Col xs={12}>
                            <Form.Group>
                              <Form.Label>Country</Form.Label>
                              <Form.Select
                                name="country"
                                id="country"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.country}
                              >
                                <option value="" disabled>
                                  Select a country
                                </option>
                                <option value="Netherlands">Netherlands</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col xs={6}>
                            <CustomButton
                              className="Btn1"
                              label={"Save"}
                              color={"#333333"}
                              textcolor={"white"}
                              width={"100%"}
                              backgroundColor={"#333333"}
                              type="submit"
                            />
                          </Col>
                          <Col xs={6}>
                            <CustomButton
                              className="btn1"
                              label={"Reset"}
                              color={"#333333"}
                              textcolor={"black"}
                              width={"100%"}
                              backgroundColor={"white"}
                              border={"2px solid black"}
                              onClick={handleReset}
                            />
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
          </div>
        )}








        {activeIndex === 3 && (
          <div className="filters-wrapper">
            <div className="filters">
              {selectedMenu === "Data" ? (
                <div className="inside-filter">
                  <div ref={postalCodeRef} className="data-filters" id="Data-Postal Code">
                    <p onClick={() => handleFilterClick("Postal code")}>
                      Postal code{" "}
                      {selectedFilter === "Postal code" ? (
                        showArrowUp ? (
                          <IoIosArrowUp />
                        ) : (
                          <IoIosArrowDown />
                        )
                      ) : (
                        <IoIosArrowDown />
                      )}
                    </p>
                    {selectedFilter === "Postal code" && (
                      <div className="content">
                        <Form
                          onSubmit={formik.handleSubmit}
                          className=""
                          style={{ padding: "25px" }}
                        >
                          <Row>
                            <Col xs={12}>
                              <Form.Group>
                                <Form.Label>From</Form.Label>
                                <Form.Control
                                  id="postalCodeFrom"
                                  name="postalCodeFrom"
                                  type="number"
                                  value={formik.values.postalCodeFrom}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                {formik.touched.postalCodeFrom &&
                                  formik.errors.postalCodeFrom ? (
                                  <div className="error">
                                    {formik.errors.postalCodeFrom}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </Col>
                            <Col xs={12}>
                              <Form.Group>
                                <Form.Label>To</Form.Label>
                                <Form.Control
                                  id="postalCodeTo"
                                  name="postalCodeTo"
                                  type="number"
                                  value={formik.values.postalCodeTo}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                {formik.touched.postalCodeTo &&
                                  formik.errors.postalCodeTo ? (
                                  <div className="error">
                                    {formik.errors.postalCodeTo}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </Col>
                            <Col xs={12}>
                              <Row>
                                <Col xs={6}>
                                  <CustomButton
                                    className="Btn1"
                                    label={"Save"}
                                    color={"#333333"}
                                    textcolor={"white"}
                                    width={"100%"}
                                    backgroundColor={"#333333"}
                                    style={{ border: "1px solid black" }}
                                    // disabled={isLoading}
                                    type="submit"
                                  />
                                </Col>
                                <Col xs={6}>
                                  <CustomButton
                                    className="btn1"
                                    label={"Reset"}
                                    color={"#333333"}
                                    textcolor={"black"}
                                    width={"100%"}
                                    backgroundColor={"white"}
                                    border={"2px solid black"}
                                    onClick={handleReset}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    )}
                  </div>

                  <div ref={countryRef} className="data-filters" id="Data-Country of Residence">
                    <p
                      onClick={() => handleFilterClick("Country of Residence")}
                    >
                      Country of Residence{" "}
                      {selectedFilter === "Country of Residence" ? (
                        showArrowUp ? (
                          <IoIosArrowUp />
                        ) : (
                          <IoIosArrowDown />
                        )
                      ) : (
                        <IoIosArrowDown />
                      )}
                    </p>
                    {selectedFilter === "Country of Residence" && (
                      <div className="content">
                        {" "}
                        <Form
                          onSubmit={formik.handleSubmit}
                          className=""
                          style={{ padding: "25px" }}
                        >
                          <Row>
                            <Col xs={12}>
                              <Form.Group>
                                <Form.Label>Country</Form.Label>

                                <Form.Select
                                  name="country"
                                  id="country"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.country}
                                >
                                  <option value="" disabled>
                                    Select a country
                                  </option>
                                  <option value="Netherlands">
                                    Netherlands
                                  </option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col xs={6}>
                              <CustomButton
                                className="Btn1"
                                label={"Save"}
                                color={"#333333"}
                                textcolor={"white"}
                                width={"100%"}
                                backgroundColor={"#333333"}
                                // disabled={isLoading}

                                type="submit"
                              />
                            </Col>

                            <Col xs={6}>
                              <CustomButton
                                className="btn1"
                                label={"Reset"}
                                color={"#333333"}
                                textcolor={"black"}
                                width={"100%"}
                                backBgroundColor={"white"}
                                border={"2px solid black"}
                                onClick={handleReset}
                              />
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    )}
                  </div>

                  <div ref={dateRef} className="data-filters" id="Data-Date">
                    <p onClick={() => handleFilterClick("Date")}>
                      Date{" "}
                      {selectedFilter === "Date" ? (
                        showArrowUp ? (
                          <IoIosArrowUp />
                        ) : (
                          <IoIosArrowDown />
                        )
                      ) : (
                        <IoIosArrowDown />
                      )}
                    </p>
                    {selectedFilter === "Date" && (
                      <div className="content">
                        {" "}
                        <Form
                          onSubmit={formik.handleSubmit}
                          className=""
                          style={{ padding: "25px" }}
                        >
                          <Col xs={12}>
                            <Form.Group>
                              <Form.Label>From</Form.Label>
                              <Form.Control
                                id="fromDate"
                                name="fromDate"
                                type="date"
                                value={formik.values.fromDate}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12}>
                            <Form.Group>
                              <Form.Label>To</Form.Label>
                              <Form.Control
                                id="toDate"
                                name="toDate"
                                type="date"
                                value={formik.values.toDate}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </Form.Group>
                          </Col>

                          <Col xs={12}>
                            <Row>
                              <Col xs={6}>
                                <CustomButton
                                  className="Btn1"
                                  label={"Save"}
                                  color={"#333333"}
                                  textcolor={"white"}
                                  width={"100%"}
                                  backgroundColor={"#333333"}
                                  style={{ border: "1px solid black" }}
                                  // disabled={isLoading}
                                  type="submit"
                                />
                              </Col>
                              <Col xs={6}>
                                <CustomButton
                                  className="btn1"
                                  label={"Reset"}
                                  color={"#333333"}
                                  textcolor={"black"}
                                  width={"100%"}
                                  backgroundColor={"white"}
                                  border={"2px solid black"}
                                  onClick={handleReset}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Form>
                      </div>
                    )}
                  </div>

                  <div ref={priceRef} className="data-filters" id="Data-Price">
                    <p onClick={() => handleFilterClick("Price")}>
                      Price{" "}
                      {selectedFilter === "Price" ? (
                        showArrowUp ? (
                          <IoIosArrowUp />
                        ) : (
                          <IoIosArrowDown />
                        )
                      ) : (
                        <IoIosArrowDown />
                      )}
                    </p>
                    {selectedFilter === "Price" && (
                      <div className="content">
                        {" "}
                        <Form
                          onSubmit={formik.handleSubmit}
                          className=""
                          style={{ padding: "25px" }}
                        >
                          <Row>
                            <Col xs={12}>
                              <Form.Group
                                controlId="priceRange"
                                name="priceRange"
                              >
                                <Form.Label>Price Range</Form.Label>
                                <RangeSlider
                                  progress
                                  style={{ marginTop: 16 }}
                                  value={formik.values.priceRange}
                                  onChange={(value) => {
                                    formik.setFieldValue("priceRange", value);
                                  }}
                                />
                              </Form.Group>
                            </Col>
                            <Col xs={12}>
                              <Form.Group
                                controlId="priceInputs"
                                style={{ marginTop: "5vh" }}
                              >
                                <InputGroup>
                                  <InputNumber
                                    // min={10}
                                    // max={100000}
                                    value={formik.values.priceRange[0]}
                                    onChange={(nextValue) => {
                                      const [start, end] =
                                        formik.values.priceRange;
                                      if (nextValue > end) {
                                        return;
                                      }
                                      formik.setFieldValue("priceRange", [
                                        nextValue,
                                        end,
                                      ]);
                                    }}
                                  />
                                  <InputGroup.Addon>to</InputGroup.Addon>
                                  <InputNumber
                                    // min={10}
                                    // max={100000}
                                    value={formik.values.priceRange[1]}
                                    onChange={(nextValue) => {
                                      const [start, end] =
                                        formik.values.priceRange;
                                      if (
                                        start > nextValue ||
                                        nextValue > 100000
                                      ) {
                                        // Adjusted condition
                                        return;
                                      }
                                      formik.setFieldValue("priceRange", [
                                        start,
                                        nextValue,
                                      ]);
                                    }}
                                  />
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col xs={6}>
                              <CustomButton
                                className="Btn1"
                                label={"Save"}
                                color={"#333333"}
                                textcolor={"white"}
                                width={"100%"}
                                backgroundColor={"#333333"}
                                // disabled={isLoading}

                                type="submit"
                              />
                            </Col>

                            <Col xs={6}>
                              <CustomButton
                                className="btn1"
                                label={"Reset"}
                                color={"#333333"}
                                textcolor={"black"}
                                width={"100%"}
                                backgroundColor={"white"}
                                border={"2px solid black"}
                                onClick={handleReset}
                              />
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="inside-filter">
                  <div ref={countryUserRef} className="data-filters" id="User-Country of Residence">
                    <p
                      onClick={() => handleFilterClick("Country of Residence")}
                    >
                      Country of Residence{" "}
                      {selectedFilter === "Country of Residence" ? (
                        showArrowUp ? (
                          <IoIosArrowUp />
                        ) : (
                          <IoIosArrowDown />
                        )
                      ) : (
                        <IoIosArrowDown />
                      )}
                    </p>
                    {selectedFilter === "Country of Residence" && (
                      <div className="content">
                        {" "}
                        <Form
                          onSubmit={formik.handleSubmit}
                          className=""
                          style={{ padding: "25px" }}
                        >
                          <Row>
                            <Col xs={12}>
                              <Form.Group>
                                <Form.Label>Country</Form.Label>

                                <Form.Select
                                  name="country"
                                  id="country"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.country}
                                >
                                  <option value="" disabled>
                                    Select a country
                                  </option>
                                  <option value="Netherlands">
                                    Netherlands
                                  </option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col xs={6}>
                              <CustomButton
                                className="Btn1"
                                label={"Save"}
                                color={"#333333"}
                                textcolor={"white"}
                                width={"100%"}
                                backgroundColor={"#333333"}
                                // disabled={isLoading}

                                type="submit"
                              />
                            </Col>

                            <Col xs={6}>
                              <CustomButton
                                className="btn1"
                                label={"Reset"}
                                color={"#333333"}
                                textcolor={"black"}
                                width={"100%"}
                                backgroundColor={"white"}
                                border={"2px solid black"}
                                onClick={handleReset}
                              />
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    )}
                  </div>

                  <div ref={postalCodeUserRef} className="data-filters" id="User-Postal-Code">
                    <p onClick={() => handleFilterClick("Postal code")}>
                      Postal code{" "}
                      {selectedFilter === "Postal code" ? (
                        showArrowUp ? (
                          <IoIosArrowUp />
                        ) : (
                          <IoIosArrowDown />
                        )
                      ) : (
                        <IoIosArrowDown />
                      )}
                    </p>
                    {selectedFilter === "Postal code" && (
                      <div className="content">
                        <Form
                          onSubmit={formik.handleSubmit}
                          className=""
                          style={{ padding: "25px" }}
                        >
                          <Row>
                            <Col xs={12}>
                              <Form.Group>
                                <Form.Label>From</Form.Label>
                                <Form.Control
                                  id="postalCodeFrom"
                                  name="postalCodeFrom"
                                  type="number"
                                  value={formik.values.postalCodeFrom}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                {formik.touched.postalCodeFrom &&
                                  formik.errors.postalCodeFrom ? (
                                  <div className="error">
                                    {formik.errors.postalCodeFrom}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </Col>
                            <Col xs={12}>
                              <Form.Group>
                                <Form.Label>To</Form.Label>
                                <Form.Control
                                  id="postalCodeTo"
                                  name="postalCodeTo"
                                  type="number"
                                  value={formik.values.postalCodeTo}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                {formik.touched.postalCodeTo &&
                                  formik.errors.postalCodeTo ? (
                                  <div className="error">
                                    {formik.errors.postalCodeTo}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </Col>
                            <Col xs={12}>
                              <Row>
                                <Col xs={6}>
                                  <CustomButton
                                    className="Btn1"
                                    label={"Save"}
                                    color={"#333333"}
                                    textcolor={"white"}
                                    width={"100%"}
                                    backgroundColor={"#333333"}
                                    style={{ border: "1px solid black" }}
                                    // disabled={isLoading}
                                    type="submit"
                                  />
                                </Col>
                                <Col xs={6}>
                                  <CustomButton
                                    className="btn1"
                                    label={"Reset"}
                                    color={"#333333"}
                                    textcolor={"black"}
                                    width={"100%"}
                                    backgroundColor={"white"}
                                    border={"2px solid black"}
                                    onClick={handleReset}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    )}
                  </div>

                  <div ref={BirthYearRef} className="data-filters" id="User-Birth-Year">
                    <p onClick={() => handleFilterClick("Birth Year")}>
                      Birth Year{" "}
                      {selectedFilter === "Birth Year" ? (
                        showArrowUp ? (
                          <IoIosArrowUp />
                        ) : (
                          <IoIosArrowDown />
                        )
                      ) : (
                        <IoIosArrowDown />
                      )}
                    </p>
                    {selectedFilter === "Birth Year" && (
                      <div className="content">
                        {" "}
                        <Form
                          onSubmit={formik.handleSubmit}
                          className=""
                          style={{ padding: "25px" }}
                        >
                          <Row>
                            <Col xs={12}>
                              <Form.Group>
                                <Form.Label>From</Form.Label>
                                <Form.Control
                                  id="birthYearFrom"
                                  name="birthYearFrom"
                                  type="number"
                                  value={formik.values.birthYearFrom}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                {formik.touched.birthYearFrom &&
                                  formik.errors.birthYearFrom ? (
                                  <div className="error">
                                    {formik.errors.birthYearFrom}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </Col>
                            <Col xs={12}>
                              <Form.Group>
                                <Form.Label>To</Form.Label>
                                <Form.Control
                                  id="birthYearTo"
                                  name="birthYearTo"
                                  type="number"
                                  value={formik.values.birthYearTo}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                {formik.touched.birthYearTo &&
                                  formik.errors.birthYearTo ? (
                                  <div className="error">
                                    {formik.errors.birthYearTo}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </Col>
                            <Col xs={6}>
                              <CustomButton
                                className="Btn1"
                                label={"Save"}
                                color={"#333333"}
                                textcolor={"white"}
                                width={"100%"}
                                backgroundColor={"#333333"}
                                // disabled={isLoading}

                                type="submit"
                              />
                            </Col>

                            <Col xs={6}>
                              <CustomButton
                                className="btn1"
                                label={"Reset"}
                                color={"#333333"}
                                textcolor={"black"}
                                width={"100%"}
                                backgroundColor={"white"}
                                border={"2px solid black"}
                                onClick={handleReset}
                              />
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {activeIndex === 1 && (
          <div className="filters-wrapper">
            <div className="filters">
              {selectedMenu === "User" ? (
                <>
                  <div ref={UserOrRequesterRef} className="data-filters">
                    <p
                      onClick={() => handleFilterClick("Users")}
                      className="filter-label"
                    >
                      Users ({selectedRole || ""})
                      {selectedFilter === "Users" ? (
                        showArrowUp ? (
                          <IoIosArrowUp />
                        ) : (
                          <IoIosArrowDown />
                        )
                      ) : (
                        <IoIosArrowDown />
                      )}
                    </p>
                    {selectedFilter === "Users" && (
                      <div className="content-sharer">
                        <div
                          className={`checkbox-container-sharer ${selectedUserType === "Requester" ? "active" : ""
                            }`}
                          onClick={() => {
                            handleRoleSelection("buyer");
                            handleUserTypeSelection("Requester");
                          }}
                        >
                          Requester
                        </div>
                        <div
                          className={`checkbox-container-sharer ${selectedUserType === "Sharer" ? "active" : ""
                            }`}
                          onClick={() => {
                            handleRoleSelection("seller");
                            handleUserTypeSelection("Sharer");
                          }}
                        >
                          Sharer
                        </div>
                      </div>
                    )}
                  </div>

                  {selectedUserType === "Sharer" ? (
                    <>
                      {/* Existing filters */}
                      <div ref={countryUserRef} className="data-filters" id="User-Country of Residence">
                        <p
                          onClick={() =>
                            handleFilterClick("Country of Residence")
                          }
                        >
                          Country of Residence{" "}
                          {selectedFilter === "Country of Residence" ? (
                            showArrowUp ? (
                              <IoIosArrowUp />
                            ) : (
                              <IoIosArrowDown />
                            )
                          ) : (
                            <IoIosArrowDown />
                          )}
                        </p>
                        {selectedFilter === "Country of Residence" && (
                          <div className="content">
                            <Form
                              onSubmit={formik.handleSubmit}
                              className=""
                              style={{ padding: "25px" }}
                            >
                              <Row>
                                <Col xs={12}>
                                  <Form.Group>
                                    <Form.Label>Country</Form.Label>
                                    <Form.Select
                                      name="country"
                                      id="country"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.country}
                                    >
                                      <option value="" disabled>
                                        Select a country
                                      </option>
                                      <option value="Netherlands">
                                        Netherlands
                                      </option>
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col xs={6}>
                                  <CustomButton
                                    className="Btn1"
                                    label={"Save"}
                                    color={"#333333"}
                                    textcolor={"white"}
                                    width={"100%"}
                                    backgroundColor={"#333333"}
                                    type="submit"
                                  />
                                </Col>
                                <Col xs={6}>
                                  <CustomButton
                                    className="btn1"
                                    label={"Reset"}
                                    color={"#333333"}
                                    textcolor={"black"}
                                    width={"100%"}
                                    backgroundColor={"white"}
                                    border={"2px solid black"}
                                    onClick={handleReset}
                                  />
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        )}
                      </div>

                      {/* Postal Code Filter */}
                      <div ref={postalCodeUserRef} className="data-filters">
                        <p onClick={() => handleFilterClick("Postal code")}>
                          Postal code{" "}
                          {selectedFilter === "Postal code" ? (
                            showArrowUp ? (
                              <IoIosArrowUp />
                            ) : (
                              <IoIosArrowDown />
                            )
                          ) : (
                            <IoIosArrowDown />
                          )}
                        </p>
                        {selectedFilter === "Postal code" && (
                          <div className="content">
                            <Form
                              onSubmit={formik.handleSubmit}
                              className=""
                              style={{ padding: "25px" }}
                            >
                              <Row>
                                <Col xs={12}>
                                  <Form.Group>
                                    <Form.Label>From</Form.Label>
                                    <Form.Control
                                      id="postalCodeFrom"
                                      name="postalCodeFrom"
                                      type="number"
                                      value={formik.values.postalCodeFrom}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.postalCodeFrom &&
                                      formik.errors.postalCodeFrom ? (
                                      <div className="error">
                                        {formik.errors.postalCodeFrom}
                                      </div>
                                    ) : null}
                                  </Form.Group>
                                </Col>
                                <Col xs={12}>
                                  <Form.Group>
                                    <Form.Label>To</Form.Label>
                                    <Form.Control
                                      id="postalCodeTo"
                                      name="postalCodeTo"
                                      type="number"
                                      value={formik.values.postalCodeTo}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.postalCodeTo &&
                                      formik.errors.postalCodeTo ? (
                                      <div className="error">
                                        {formik.errors.postalCodeTo}
                                      </div>
                                    ) : null}
                                  </Form.Group>
                                </Col>
                                <Col xs={6}>
                                  <CustomButton
                                    className="Btn1"
                                    label={"Save"}
                                    color={"#333333"}
                                    textcolor={"white"}
                                    width={"100%"}
                                    backgroundColor={"#333333"}
                                    type="submit"
                                  />
                                </Col>
                                <Col xs={6}>
                                  <CustomButton
                                    className="btn1"
                                    label={"Reset"}
                                    color={"#333333"}
                                    textcolor={"black"}
                                    width={"100%"}
                                    backgroundColor={"white"}
                                    border={"2px solid black"}
                                    onClick={handleReset}
                                  />
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        )}
                      </div>

                      {/* Birth Year Filter */}
                      <div ref={BirthYearRef} className="data-filters" id="User-Birth-Year">
                        <p onClick={() => handleFilterClick("Birth Year")}>
                          Birth Year{" "}
                          {selectedFilter === "Birth Year" ? (
                            showArrowUp ? (
                              <IoIosArrowUp />
                            ) : (
                              <IoIosArrowDown />
                            )
                          ) : (
                            <IoIosArrowDown />
                          )}
                        </p>
                        {selectedFilter === "Birth Year" && (
                          <div className="content">
                            <Form
                              onSubmit={formik.handleSubmit}
                              className=""
                              style={{ padding: "25px" }}
                            >
                              <Row>
                                <Col xs={12}>
                                  <Form.Group>
                                    <Form.Label>From</Form.Label>
                                    <Form.Control
                                      id="birthYearFrom"
                                      name="birthYearFrom"
                                      type="number"
                                      value={formik.values.birthYearFrom}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.birthYearFrom &&
                                      formik.errors.birthYearFrom ? (
                                      <div className="error">
                                        {formik.errors.birthYearFrom}
                                      </div>
                                    ) : null}
                                  </Form.Group>
                                </Col>
                                <Col xs={12}>
                                  <Form.Group>
                                    <Form.Label>To</Form.Label>
                                    <Form.Control
                                      id="birthYearTo"
                                      name="birthYearTo"
                                      type="number"
                                      value={formik.values.birthYearTo}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.birthYearTo &&
                                      formik.errors.birthYearTo ? (
                                      <div className="error">
                                        {formik.errors.birthYearTo}
                                      </div>
                                    ) : null}
                                  </Form.Group>
                                </Col>
                                <Col xs={6}>
                                  <CustomButton
                                    className="Btn1"
                                    label={"Save"}
                                    color={"#333333"}
                                    textcolor={"white"}
                                    width={"100%"}
                                    backgroundColor={"#333333"}
                                    type="submit"
                                  />
                                </Col>
                                <Col xs={6}>
                                  <CustomButton
                                    className="btn1"
                                    label={"Reset"}
                                    color={"#333333"}
                                    textcolor={"black"}
                                    width={"100%"}
                                    backgroundColor={"white"}
                                    border={"2px solid black"}
                                    onClick={handleReset}
                                  />
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        )}
                      </div>

                      {/* Diagnosis Filter */}
                      <div ref={diagnosisUserRef} className="data-filters" id="User-Diagnosis">
                        <p onClick={() => handleUserFilterClick("Diagnosis")}>
                          Diagnosis{" "}
                          {openUserDropdown === "Diagnosis" ? (
                            showArrowUpUser ? (
                              <IoIosArrowUp />
                            ) : (
                              <IoIosArrowDown />
                            )
                          ) : (
                            <IoIosArrowDown />
                          )}
                        </p>
                        {openUserDropdown === "Diagnosis" && (
                          <div className="content">
                            <Form
                              onSubmit={formik.handleSubmit}
                              className=""
                              style={{ padding: "25px" }}
                            >
                              <Row>
                                <Col xs={12}>
                                  <Form.Group>
                                    <Form.Label>Diagnosis</Form.Label>
                                    <Form.Select
                                      name="diagnosis"
                                      id="diagnosis"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.diagnosis}
                                    >
                                      <option value="" disabled>
                                        Select a diagnosis
                                      </option>
                                      <option value="Healthy">Healthy</option>
                                      <option value="Multiple Sclerosis – Relapsing Remitting">
                                        Multiple Sclerosis – Relapsing Remitting
                                      </option>
                                      <option value="Multiple Sclerosis – Secondary Progressive">
                                        Multiple Sclerosis – Secondary Progressive
                                      </option>
                                      <option value="Multiple Sclerosis – Primary Progressive">
                                        Multiple Sclerosis – Primary Progressive
                                      </option>
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col xs={6}>
                                  <CustomButton
                                    className="Btn1"
                                    label={"Save"}
                                    color={"#333333"}
                                    textcolor={"white"}
                                    width={"100%"}
                                    backgroundColor={"#333333"}
                                    type="submit"
                                  />
                                </Col>
                                <Col xs={6}>
                                  <CustomButton
                                    className="btn1"
                                    label={"Reset"}
                                    color={"#333333"}
                                    textcolor={"black"}
                                    width={"100%"}
                                    backgroundColor={"white"}
                                    border={"2px solid black"}
                                    onClick={handleReset}
                                  />
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        )}
                      </div>




                      {/* Institution Filter */}
                      <div className="data-filters">
                        <p onClick={() => handleFilterClick("Institution")}>
                          Institution{" "}
                          {selectedFilter === "Institution" ? (
                            showArrowUp ? (
                              <IoIosArrowUp />
                            ) : (
                              <IoIosArrowDown />
                            )
                          ) : (
                            <IoIosArrowDown />
                          )}
                        </p>
                        {selectedFilter === "Institution" && (
                          <div className="content">
                            <Form
                              onSubmit={formik.handleSubmit}
                              className=""
                              style={{ padding: "25px" }}
                            >
                              <Row>
                                <Col xs={12}>
                                  {/* Add your institution filter inputs here */}

                                </Col>
                                <Col xs={6}>
                                  <CustomButton
                                    className="Btn1"
                                    label={"Save"}
                                    color={"#333333"}
                                    textcolor={"white"}
                                    width={"100%"}
                                    backgroundColor={"#333333"}
                                    type="submit"
                                  />
                                </Col>
                                <Col xs={6}>
                                  <CustomButton
                                    className="btn1"
                                    label={"Reset"}
                                    color={"#333333"}
                                    textcolor={"black"}
                                    width={"100%"}
                                    backgroundColor={"white"}
                                    border={"2px solid black"}
                                    onClick={handleReset}
                                  />
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        )}
                      </div>

                      {/* Department Filter */}
                      <div className="data-filters">
                        <p onClick={() => handleFilterClick("Department")}>
                          Department{" "}
                          {selectedFilter === "Department" ? (
                            showArrowUp ? (
                              <IoIosArrowUp />
                            ) : (
                              <IoIosArrowDown />
                            )
                          ) : (
                            <IoIosArrowDown />
                          )}
                        </p>
                        {selectedFilter === "Department" && (
                          <div className="content">
                            <Form
                              onSubmit={formik.handleSubmit}
                              className=""
                              style={{ padding: "25px" }}
                            >
                              <Row>
                                <Col xs={12}>
                                  {/* Add your department filter inputs here */}
                                </Col>
                                <Col xs={6}>
                                  <CustomButton
                                    className="Btn1"
                                    label={"Save"}
                                    color={"#333333"}
                                    textcolor={"white"}
                                    width={"100%"}
                                    backgroundColor={"#333333"}
                                    type="submit"
                                  />
                                </Col>
                                <Col xs={6}>
                                  <CustomButton
                                    className="btn1"
                                    label={"Reset"}
                                    color={"#333333"}
                                    textcolor={"black"}
                                    width={"100%"}
                                    backgroundColor={"white"}
                                    border={"2px solid black"}
                                    onClick={handleReset}
                                  />
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        )}
                      </div>



                    </>
                  ) : selectedUserType === "Requester" ? (
                    <>
                      {/* Existing filters */}
                      <div ref={countryUserRef} className="data-filters">
                        <p
                          onClick={() =>
                            handleFilterClick("Country of Residence")
                          }
                        >
                          Country of Residence{" "}
                          {selectedFilter === "Country of Residence" ? (
                            showArrowUp ? (
                              <IoIosArrowUp />
                            ) : (
                              <IoIosArrowDown />
                            )
                          ) : (
                            <IoIosArrowDown />
                          )}
                        </p>
                        {selectedFilter === "Country of Residence" && (
                          <div className="content">
                            <Form
                              onSubmit={formik.handleSubmit}
                              className=""
                              style={{ padding: "25px" }}
                            >
                              <Row>
                                <Col xs={12}>
                                  <Form.Group>
                                    <Form.Label>Country</Form.Label>
                                    <Form.Select
                                      name="country"
                                      id="country"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.country}
                                    >
                                      <option value="" disabled>
                                        Select a country
                                      </option>
                                      <option value="Netherlands">
                                        Netherlands
                                      </option>
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col xs={6}>
                                  <CustomButton
                                    className="Btn1"
                                    label={"Save"}
                                    color={"#333333"}
                                    textcolor={"white"}
                                    width={"100%"}
                                    backgroundColor={"#333333"}
                                    type="submit"
                                  />
                                </Col>
                                <Col xs={6}>
                                  <CustomButton
                                    className="btn1"
                                    label={"Reset"}
                                    color={"#333333"}
                                    textcolor={"black"}
                                    width={"100%"}
                                    backgroundColor={"white"}
                                    border={"2px solid black"}
                                    onClick={handleReset}
                                  />
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        )}
                      </div>

                      {/* Institution Filter */}
                      <div className="data-filters">
                        <p onClick={() => handleFilterClick("Institution")}>
                          Institution{" "}
                          {selectedFilter === "Institution" ? (
                            showArrowUp ? (
                              <IoIosArrowUp />
                            ) : (
                              <IoIosArrowDown />
                            )
                          ) : (
                            <IoIosArrowDown />
                          )}
                        </p>
                        {selectedFilter === "Institution" && (
                          <div className="content">
                            <Form
                              onSubmit={formik.handleSubmit}
                              className=""
                              style={{ padding: "25px" }}
                            >
                              <Row>
                                <Col xs={12}>
                                  {/* Add your institution filter inputs here */}
                                </Col>
                                <Col xs={6}>
                                  <CustomButton
                                    className="Btn1"
                                    label={"Save"}
                                    color={"#333333"}
                                    textcolor={"white"}
                                    width={"100%"}
                                    backgroundColor={"#333333"}
                                    type="submit"
                                  />
                                </Col>
                                <Col xs={6}>
                                  <CustomButton
                                    className="btn1"
                                    label={"Reset"}
                                    color={"#333333"}
                                    textcolor={"black"}
                                    width={"100%"}
                                    backgroundColor={"white"}
                                    border={"2px solid black"}
                                    onClick={handleReset}
                                  />
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        )}
                      </div>

                      {/* Department Filter */}
                      <div className="data-filters">
                        <p onClick={() => handleFilterClick("Department")}>
                          Department{" "}
                          {selectedFilter === "Department" ? (
                            showArrowUp ? (
                              <IoIosArrowUp />
                            ) : (
                              <IoIosArrowDown />
                            )
                          ) : (
                            <IoIosArrowDown />
                          )}
                        </p>
                        {selectedFilter === "Department" && (
                          <div className="content">
                            <Form
                              onSubmit={formik.handleSubmit}
                              className=""
                              style={{ padding: "25px" }}
                            >
                              <Row>
                                <Col xs={12}>
                                  {/* Add your department filter inputs here */}
                                </Col>
                                <Col xs={6}>
                                  <CustomButton
                                    className="Btn1"
                                    label={"Save"}
                                    color={"#333333"}
                                    textcolor={"white"}
                                    width={"100%"}
                                    backgroundColor={"#333333"}
                                    type="submit"
                                  />
                                </Col>
                                <Col xs={6}>
                                  <CustomButton
                                    className="btn1"
                                    label={"Reset"}
                                    color={"#333333"}
                                    textcolor={"black"}
                                    width={"100%"}
                                    backgroundColor={"white"}
                                    border={"2px solid black"}
                                    onClick={handleReset}
                                  />
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        )}
                      </div>
                    </>
                  ) : null}
                </>
              ) : selectedMenu === "Data" && activeIndex == 3 ? (
                <div className="inside-filter">
                  <div ref={postalCodeRef} className="data-filters">
                    <p onClick={() => handleFilterClick("Postal code")}>
                      Postal code{" "}
                      {selectedFilter === "Postal code" ? (
                        showArrowUp ? (
                          <IoIosArrowUp />
                        ) : (
                          <IoIosArrowDown />
                        )
                      ) : (
                        <IoIosArrowDown />
                      )}
                    </p>
                    {selectedFilter === "Postal code" && (
                      <div className="content">
                        <Form
                          onSubmit={formik.handleSubmit}
                          className=""
                          style={{ padding: "25px" }}
                        >
                          <Row>
                            <Col xs={12}>
                              <Form.Group>
                                <Form.Label>From</Form.Label>
                                <Form.Control
                                  id="postalCodeFrom"
                                  name="postalCodeFrom"
                                  type="number"
                                  value={formik.values.postalCodeFrom}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                {formik.touched.postalCodeFrom &&
                                  formik.errors.postalCodeFrom ? (
                                  <div className="error">
                                    {formik.errors.postalCodeFrom}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </Col>
                            <Col xs={12}>
                              <Form.Group>
                                <Form.Label>To</Form.Label>
                                <Form.Control
                                  id="postalCodeTo"
                                  name="postalCodeTo"
                                  type="number"
                                  value={formik.values.postalCodeTo}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                {formik.touched.postalCodeTo &&
                                  formik.errors.postalCodeTo ? (
                                  <div className="error">
                                    {formik.errors.postalCodeTo}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </Col>
                            <Col xs={6}>
                              <CustomButton
                                className="Btn1"
                                label={"Save"}
                                color={"#333333"}
                                textcolor={"white"}
                                width={"100%"}
                                backgroundColor={"#333333"}
                                type="submit"
                              />
                            </Col>
                            <Col xs={6}>
                              <CustomButton
                                className="btn1"
                                label={"Reset"}
                                color={"#333333"}
                                textcolor={"black"}
                                width={"100%"}
                                backgroundColor={"white"}
                                border={"2px solid black"}
                                onClick={handleReset}
                              />
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    )}
                  </div>
                  <div ref={countryRef} className="data-filters">
                    <p
                      onClick={() => handleFilterClick("Country of Residence")}
                    >
                      Country of Residence{" "}
                      {selectedFilter === "Country of Residence" ? (
                        showArrowUp ? (
                          <IoIosArrowUp />
                        ) : (
                          <IoIosArrowDown />
                        )
                      ) : (
                        <IoIosArrowDown />
                      )}
                    </p>
                    {selectedFilter === "Country of Residence" && (
                      <div className="content">
                        <Form
                          onSubmit={formik.handleSubmit}
                          className=""
                          style={{ padding: "25px" }}
                        >
                          <Row>
                            <Col xs={12}>
                              <Form.Group>
                                <Form.Label>Country</Form.Label>
                                <Form.Select
                                  name="country"
                                  id="country"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.country}
                                >
                                  <option value="" disabled>
                                    Select a country
                                  </option>
                                  <option value="Netherlands">
                                    Netherlands
                                  </option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col xs={6}>
                              <CustomButton
                                className="Btn1"
                                label={"Save"}
                                color={"#333333"}
                                textcolor={"white"}
                                width={"100%"}
                                backgroundColor={"#333333"}
                                type="submit"
                              />
                            </Col>
                            <Col xs={6}>
                              <CustomButton
                                className="btn1"
                                label={"Reset"}
                                color={"#333333"}
                                textcolor={"black"}
                                width={"100%"}
                                backgroundColor={"white"}
                                border={"2px solid black"}
                                onClick={handleReset}
                              />
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    )}
                  </div>
                  <div ref={dateRef} className="data-filters">
                    <p onClick={() => handleFilterClick("Date")}>
                      Date{" "}
                      {selectedFilter === "Date" ? (
                        showArrowUp ? (
                          <IoIosArrowUp />
                        ) : (
                          <IoIosArrowDown />
                        )
                      ) : (
                        <IoIosArrowDown />
                      )}
                    </p>
                    {selectedFilter === "Date" && (
                      <div className="content">
                        <Form
                          onSubmit={formik.handleSubmit}
                          className=""
                          style={{ padding: "25px" }}
                        >
                          <Col xs={12}>
                            <Form.Group>
                              <Form.Label>From</Form.Label>
                              <Form.Control
                                id="fromDate"
                                name="fromDate"
                                type="date"
                                value={formik.values.fromDate}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12}>
                            <Form.Group>
                              <Form.Label>To</Form.Label>
                              <Form.Control
                                id="toDate"
                                name="toDate"
                                type="date"
                                value={formik.values.toDate}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={6}>
                            <CustomButton
                              className="Btn1"
                              label={"Save"}
                              color={"#333333"}
                              textcolor={"white"}
                              width={"100%"}
                              backgroundColor={"#333333"}
                              type="submit"
                            />
                          </Col>
                          <Col xs={6}>
                            <CustomButton
                              className="btn1"
                              label={"Reset"}
                              color={"#333333"}
                              textcolor={"black"}
                              width={"100%"}
                              backgroundColor={"white"}
                              border={"2px solid black"}
                              onClick={handleReset}
                            />
                          </Col>
                        </Form>
                      </div>
                    )}
                  </div>
                  <div ref={priceRef} className="data-filters">
                    <p onClick={() => handleFilterClick("Price")}>
                      Price{" "}
                      {selectedFilter === "Price" ? (
                        showArrowUp ? (
                          <IoIosArrowUp />
                        ) : (
                          <IoIosArrowDown />
                        )
                      ) : (
                        <IoIosArrowDown />
                      )}
                    </p>
                    {selectedFilter === "Price" && (
                      <div className="content">
                        <Form
                          onSubmit={formik.handleSubmit}
                          className=""
                          style={{ padding: "25px" }}
                        >
                          <Row>
                            <Col xs={12}>
                              <Form.Group
                                controlId="priceRange"
                                name="priceRange"
                              >
                                <Form.Label>Price Range</Form.Label>
                                <RangeSlider
                                  progress
                                  style={{ marginTop: 16 }}
                                  value={formik.values.priceRange}
                                  onChange={(value) => {
                                    formik.setFieldValue("priceRange", value);
                                  }}
                                />
                              </Form.Group>
                            </Col>
                            <Col xs={12}>
                              <Form.Group
                                controlId="priceInputs"
                                style={{ marginTop: "5vh" }}
                              >
                                <InputGroup>
                                  <InputNumber
                                    value={formik.values.priceRange[0]}
                                    onChange={(nextValue) => {
                                      const [start, end] =
                                        formik.values.priceRange;
                                      if (nextValue > end) {
                                        return;
                                      }
                                      formik.setFieldValue("priceRange", [
                                        nextValue,
                                        end,
                                      ]);
                                    }}
                                  />
                                  <InputGroup.Addon>to</InputGroup.Addon>
                                  <InputNumber
                                    value={formik.values.priceRange[1]}
                                    onChange={(nextValue) => {
                                      const [start, end] =
                                        formik.values.priceRange;
                                      if (
                                        start > nextValue ||
                                        nextValue > 100000
                                      ) {
                                        return;
                                      }
                                      formik.setFieldValue("priceRange", [
                                        start,
                                        nextValue,
                                      ]);
                                    }}
                                  />
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col xs={6}>
                              <CustomButton
                                className="Btn1"
                                label={"Save"}
                                color={"#333333"}
                                textcolor={"white"}
                                width={"100%"}
                                backgroundColor={"#333333"}
                                type="submit"
                              />
                            </Col>
                            <Col xs={6}>
                              <CustomButton
                                className="btn1"
                                label={"Reset"}
                                color={"#333333"}
                                textcolor={"black"}
                                width={"100%"}
                                backgroundColor={"white"}
                                border={"2px solid black"}
                                onClick={handleReset}
                              />
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}

        <Container style={{ marginBlock: "10vh" }}>
          <Row style={{ marginTop: "12vh" }}>
            {filterDataisLoading || searchResult.isLoading ? (
              [...Array(6)].map((_, index) => (
                <Col
                  key={index}
                  lg={4}
                  md={6}
                  xs={12}
                  className="mb-3"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Skeleton
                    height={20}
                    width={160}
                    style={{ marginBottom: "5px" }}
                  />
                  <Skeleton height={200} />
                </Col>
              ))
            ) : selectedMenu === "Data" ? (


              results && results.length > 0 ? (
                results?.map((filteredBid) => (
                  <Col
                    key={filteredBid.id}
                    lg={4}
                    md={6}
                    xs={12}
                    className="mb-3"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <span>
                      {filteredBid.owner ? filteredBid.owner.username : ""}
                    </span>
                    <Customcard bid={filteredBid} />
                  </Col>
                ))
              ) : (
                <Col lg={12} className="mb-5 results">
                  <p>No results found</p>
                </Col>
              )
            ) : selectedMenu === "User" ? (
              <>
                {filterDataisLoading || searchResult.isLoading ? (
                  [...Array(10)].map((_, index) => (
                    <Col
                      key={index}
                      lg={12}
                      md={12}
                      xs={12}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Skeleton
                        height={40}
                        width={1200}
                        style={{ marginBottom: "5px" }}
                      />
                    </Col>
                  ))
                ) : results && results.length > 0 ? (
                  <BidsListItem users={results} selectedUserType={selectedUserType} />
                ) : (

                  <BidsListItem users={results} selectedUserType={selectedUserType} />
                )}
              </>
            ) : null}
          </Row>

          <div className="" style={{ marginTop: "20px" }} />
          {selectedMenu === "Data" && (hasMoreBids || !filterDataisSuccess) && (
            <div className="bid-button">
              <button
                onClick={handleLoadMore}
                className="button"
                disabled={loadingMore}
              >
                {loadingMore ? "Loading..." : "Load More"}
                {!loadingMore && (
                  <i>
                    <AiOutlineReload />
                  </i>
                )}
              </button>
            </div>
          )}
        </Container>
      </Container>
    </section>
  );
};

export default React.memo(BidSection);