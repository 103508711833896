// import io from "socket.io-client";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setNotificationCount } from "./reducers/notificationsSlice";

// import socket from "./actions/socket";

const BASE_URL = "https://api.liberdat.io/v1/";
// const BASE_URL = "http://192.168.18.10:3000/v1/";  //AXON
// const BASE_URL = "http://192.168.1.14:3000/v1/";
// const BASE_URL = "http://192.168.1.12:3000/v1/"; 







const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth?.user?.tokens?.access?.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "auth/login",
        method: "POST",
        body: credentials,
      }),
    }),
    forgetPassword: builder.mutation({
      query: (credentials) => ({
        url: "auth/forgot-password",
        method: "POST",
        body: credentials,
      }),
    }),
    // Creating Forget
    resetPassword: builder.mutation({
      query: (credentials) => ({
        url: "auth/reset-password",
        method: "POST",
        body: credentials,
      }),
    }),
    signup: builder.mutation({
      query: (userData) => ({
        url: "auth/register",
        method: "POST",
        body: userData,
      }),
    }),
    configure: builder.mutation({
      query: ({ vts, id }) => ({
        url: `bids/configure/${id}`,
        method: "POST",
        body: vts,
      }),
    }),
    createQuestionnaire: builder.mutation({
      query: (questionnaire) => ({
        url: "questions/createQuestions",
        method: "POST",
        body: questionnaire,
      }),
    }),
    getMultiCascade: builder.mutation({
      query: (therapyObject) => ({
        url: "data-item/getDataItem",
        method: "POST",
        body: therapyObject,
      }),
    }),
    updateQuestionnaire: builder.mutation({
      query: (questionnaireId, updatedQuestionnaire) => ({
        url: "questions/updateQuestions/:questionnaireId",
        method: "POST",
        body: updatedQuestionnaire,
      }),
    }),

    getQuestionnaire: builder.query({
      query: () => ({
        url: "questions/getQuestionnaire",
        method: "GET",
      }),
    }),
    //
    deleteUserById: builder.mutation({
      query: (userId) => ({
        url: `/users/${userId}`,
        method: "DELETE",
      }),
    }),
    deleteUserbyEmail: builder.mutation({
      query: (email) => ({
        url: `/users/deleteLoggedOutUser`,
        method: "DELETE",
        body: email,
      }),
    }),

    autoMatch: builder.mutation({
      query: ({ bidId, bid }) => ({
        url: `/assign-user/autoMatch/${bidId}`,
        method: "POST",
        body: bid,
      }),
    }),
    createPaymentMethod: builder.mutation({
      query: (paymentMethodPayloadd) => ({
        url: "payment/payment-method/attach",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(paymentMethodPayloadd),
      }),
    }),

    paymentSheet: builder.mutation({
      query: (selectedUserIdd) => ({
        url: `payment/payment-sheet/${selectedUserIdd}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),

    removePaymentMethod: builder.mutation({
      query: () => ({
        url: "payment/payment-method/detach",
        method: "PUT",
      }),
    }),
    cardDetails: builder.mutation({
      query: (valuesToSend) => ({
        url: "/payment/connect-account",
        method: "PUT",
        // headers: {
        //   'Content-Type': 'application/json',
        // },
        body: JSON.stringify(valuesToSend),
      }),
    }),

    getBids: builder.query({
      query: ({ limit, skip }) => `bids?limit=${limit}&skip=${skip}`,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        currentCache.push(...newItems);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),

    getMyBids: builder.query({
      query: (accessToken) => ({
        url: "bids/me",
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getAllQuestionnaire: builder.query({
      query: () => ({
        url: "questions/getAllQuestionnaire",
        method: "GET",
      }),
    }),
    assignFromSurveyTitle: builder.mutation({
      query: ({ bidId, questionnaireId }) => ({
        url: `questions/selectQuestionnaire/${bidId}/${questionnaireId}`,
        method: "POST",
      }),
    }),
    createBid: builder.mutation({
      query: (bidData) => ({
        url: "/bids",
        method: "POST",
        body: bidData,
      }),
    }),
    updateBid: builder.mutation({
      query: ({ bidData, id }) => ({
        url: `/bids/${id}`,
        method: "PUT",
        body: bidData,
      }),
    }),
    searchBid: builder.query({
      query: ({ identifier, query }) => ({
        url: `/bids/search/${query}/${identifier}`,
        method: "GET",
      }),
    }),
    assignUser: builder.mutation({
      query: (userdata) => ({
        url: "/assign-user",
        method: "POST",
        body: userdata,
      }),
    }),
    sendPaymentStripeId: builder.mutation({
      query: (stripeId) => ({
        url: `/payment/payment-method/get/${stripeId}`,
        method: "POST",
      }),
    }),
    filterData: builder.mutation({
      query: (valuesToSend) => ({
        url: "/bids/filterSearch",
        method: "POST",
        body: valuesToSend,
      }),
    }),
    getHealthProffessionals: builder.mutation({
      query: (valuesToSend) => ({
        url: "/bids/getHealhCareProfessionals",
        method: "POST",
        body: valuesToSend,
      }),
    }),
    getByUsername: builder.query({
      query: (getusernameQuery) => ({
        url: `/users/getByUsername/${getusernameQuery}`,
        method: "GET",
      }),
    }),

    getAllUsers: builder.query({
      query: ({ limitForTotalUsers: limit, skipUsers: skip }) => ({
        url: `users/getAllUsers/?limit=${limit}&skip=${skip}`,
        method: "GET",
      }),
    }),

    addBidUser: builder.mutation({
      query: (assignData) => ({
        url: `assign-user`,
        method: "POST",
        body: assignData,
      }),
    }),

    getSellers: builder.query({
      query: (query) => ({
        url: `/users/get-sellers/${query}`,
        method: "GET",
      }),
    }),
    updateSellerGraphNotification: builder.mutation({
      query: (sellerId) => ({
        url: `/users/notifySellerToUpdate`,
        method: "POST",
        body: sellerId,
      }),
    }),
    updateSellersGraphNotifications: builder.mutation({
      query: (sellers) => ({
        url: `/users/sendUpdateNotificationToAssignedSellers`,
        method: "POST",
        body: sellers,
      }),
    }),


    verifyEmailAddress: builder.mutation({
      query: (token) => ({
        url: `auth/verify-email?token=${token}`,
        method: "POST",
        //  params: token
      }),
    }),

    verifyAccountDelete: builder.mutation({
      query: (token) => ({
        url: `users/confirm-delete?token=${token}`,
        method: "POST",
      }),
    }),

    getAcceptedUserData: builder.query({
      // query: ( args ) => ({

      //   url: `/assign-user/accepted-users/${bidRef}/${selRef}`,

      //   method: "GET",
      // }),
      query: (args) => {
        // Destructuring Object
        const [bidRef, selRef] = args;
        return {
          // Returns url with multiple args
          url: `/assign-user/accepted-users/${bidRef}/${selRef}`,
          method: "GET",
        };
      },
    }),

    getUser: builder.query({
      query: (userId) => ({
        url: `/users/${userId}`,
        method: "GET",
      }),
    }),
    assignBid: builder.mutation({
      query: ({ bidData, bidId }) => ({
        url: `assign-user/${bidId}`,
        method: "PUT",
        body: bidData,
      }),
    }),
    getAddedUsers: builder.query({
      query: (bidId) => ({
        url: `/assign-user/added-users/${bidId}`,
        method: "GET",
      }),
    }),
    getPlacesVisited: builder.mutation({
      query: (data) => ({
        url: `/users/getPlaces`,
        method: "POST",
        body: data,
      }),
    }),
    getFileForBackend: builder.mutation({
      query: (data) => ({
        url: `/users/downloadAndUnzipFile`,
        method: "POST",
        body: data,
      }),
    }),
    getRequest: builder.query({
      query: (getrequest) => ({
        url: `/assign-user/requests/${getrequest}`,
        method: "GET",
      }),
    }),
    getSellerById: builder.query({
      query: (senderId) => ({
        url: `/users/getSellerById/${senderId}`,
        method: "GET",
      }),
    }),
    validateSellerData: builder.mutation({
      query: ({ senderId, bodyToSend }) => ({
        url: `/users/validateSellerData/${senderId}`,
        method: "POST",
        body: bodyToSend,
      }),
    }),
    getSellerData: builder.query({
      query: () => ({
        url: `/users/getsellerdata`,
        method: "GET",
      }),
    }),
    getNotification: builder.query({
      query: () => ({
        url: `/notification/getAllNotifications`,
        method: "GET",
      }),
    }),

    sendNotification: builder.mutation({
      query: (notificationId) => ({
        url: `/notification/getNotificationById/${notificationId}`,
        method: "POST",
      }),
    }),
    deleteNotification: builder.mutation({
      query: (notificationId) => ({
        url: `/notification/deleteNotification/${notificationId}`,
        method: "DELETE",
      }),
    }),
    getBuyerData: builder.query({
      query: () => ({
        url: `/users/getBuyerData`,
        method: "GET",
      }),
    }),
    submitGeneralInfoForms: builder.mutation({
      query: (data) => ({
        url: `/users/updateSellerData`,
        method: "PUT",
        body: data,
      }),
    }),



    getAllInstitutes: builder.query({
      query: () => ({
        url: `/bids/getAllInstitutes`,
        method: "GET",
      }),
    }),
    getInstitutionalUsers: builder.mutation({
      query: (usersList) => ({
        url: `/bids/getInstitutionalUsers`,
        body: usersList,
        method: "POST",
      }),
    }),

    getAllDepartments: builder.query({
      query: () => ({
        url: `/bids/getAllDepartments`,
        method: "GET",
      }),
    }),
    submitBuyerInfoForms: builder.mutation({
      query: (data) => ({
        url: `/users/updateBuyerData`,
        method: "POST",
        body: data,
      }),
    }),
    addTherapy: builder.mutation({
      query: (data) => ({
        url: `/users/addTherapiesData`,
        method: "POST",
        body: data,
      }),
    }),
    updateTherapy: builder.mutation({
      query: (data) => ({
        url: `/users/updateTherapiesData`,
        method: "POST",
        body: data,
      }),
    }),

    getAllNotification: builder.query({
      query: () => ({
        url: `/notification/getAllNotifications`,
        method: "GET",
      }),
      providesTags: ["Notifications"],
    }),

    getSellerPlacesVisited: builder.mutation({
      query: ({ sellerRef, assignedBidId }) => ({
        url: `/assign-user/getPlacesVisits/${sellerRef}/${assignedBidId}`,
        method: "GET",
      }),
    }),


    getPredictedDataofUser: builder.query({
      query: (userId) => ({
        url: `/users/sendPredictedDataofUser/${userId}`,
        method: "GET",
      }),
    }),
    addNewDiagnosis: builder.mutation({
      query: ({ sellerId, bodyToSend }) => ({
        url: `/users/updateSellerData/${sellerId}`,
        method: "PUT",
        body: bodyToSend,
      }),
      invalidatesTags: ['Notifications']
    }),
    addNewTherapy: builder.mutation({
      query: ({ sellerId, therapyBody }) => ({
        url: `/users/addTherapiesData/${sellerId}`,
        method: 'POST',
        body: therapyBody,
      }),
    }),

    // getNotificationsCount: builder.query({

    //   queryFn: () => `/`,
    //   async onCacheEntryAdded(
    //       _, // arg
    //       { updateCachedData, cacheEntryRemoved,dispatch,cacheDataLoaded }
    //   ) {
    //     await cacheDataLoaded
    //       const socketNew = socket.getSocket();
    //       console.log('====================================');
    //       console.log("socketsocketsocket",socketNew);
    //       console.log('====================================');
    //       try {
    //         socketNew.on('notificationCount', (count) => {
    //           updateCachedData(() => {
    //             console.log("COUNTTTT",count);
    //             dispatch(setNotificationCount(count?.count))
    //             return count;
    //           })
    //         })
    //       } catch (error) {
    //         console.log("NOTIFICATIONNNCOUNTTTTT",error);
    //       }
    //       await cacheEntryRemoved
    //       socketNew.close()
    //       // console.log("SOCKETTTTT",socket);

    //   }
    //       // try {
    //       //     socket.on(ServerEvents.InitGame, (newGameState: any) => {
    //       //         updateCachedData((draft) => {
    //       //             draft = newGameState
    //       //         })
    //       //     })






    //   // query: () => `/`,
    //   // async onCacheEntryAdded(
    //   //   arg,
    //   //   { updateCachedData, cacheDataLoaded, cacheEntryRemoved,dispatch }
    //   // ) {
    //   //   const state = store.getState();
    //   //   const currentUser = state.auth.user.user.id;
    //   //   console.log('ccc',currentUser);
    //   //   const ws = new WebSocket('ws://192.168.18.10:3000')
    //   //   console.log("WSSS",ws);
    //   //   try {
    //   //     await cacheDataLoaded

    //   //     const listener = (event) => {
    //   //       const data = JSON.parse(event.data)
    //   //       // if (!isMessage(data) || data.channel !== arg) return

    //   //       updateCachedData((draft) => {
    //   //         console.log('draftt',draft)
    //   //         // messagesAdapter.upsertOne(draft, data)
    //   //         // draft.push(data)
    //   //         dispatch(setNotificationCount(data))
    //   //         // setNotificationCount(dara)
    //   //       })
    //   //     }
    //   //     ws.emit('notificationCount', {currentUser});
    //   //     ws.on('notificationCount', (payload) => {
    //   //       console.log('Joined room!');
    //   //       console.log(payload);
    //   //       listener(payload);
    //   //     });
    //   //     // ws.addEventListener('notificationCount', listener)
    //   //   } catch {

    //   //   }
    //   //   await cacheEntryRemoved
    //   //   ws.close()
    //   // },



    // }),
  }),

});

export const {
  useAddBidUserMutation,
  useUpdateSellerGraphNotificationMutation,
  useAssignFromSurveyTitleMutation,
  useGetAllQuestionnaireQuery,
  useValidateSellerDataMutation,
  useDeleteUserByIdMutation,
  // useLazyGetDeleteAccountQuery,

  useGetNotificationQuery,
  useSendNotificationMutation,
  useDeleteNotificationMutation,

  useVerifyEmailAddressMutation,
  useVerifyAccountDeleteMutation,
  useAddNewTherapyMutation,
  useAddNewDiagnosisMutation,

  useAssignBidMutation,
  useGetAcceptedUserDataQuery,
  useGetUserQuery,
  useGetAddedUsersQuery,
  useLazyGetAddedUsersQuery,
  useGetSellersQuery,
  useGetRequestQuery,
  useGetSellerByIdQuery,
  useLoginMutation,
  useForgetPasswordMutation,
  useResetPasswordMutation,
  useCardDetailsMutation,
  useSignupMutation,
  useConfigureMutation,
  useGetBidsQuery,
  useLazyGetBidsQuery,
  useCreateBidMutation,
  useCreatePaymentMethodMutation,
  useGetMyBidsQuery,
  useUpdateBidMutation,
  useRemovePaymentMethodMutation,
  useLazyGetMyBidsQuery,
  useLazySearchBidQuery,
  useLazyGetSellersQuery,
  useGetByUsernameQuery,
  useGetSellerDataQuery,
  useGetBuyerDataQuery,
  useAssignUserMutation,
  useSubmitGeneralInfoFormsMutation,
  useSubmitBuyerInfoFormsMutation,
  usePaymentSheetMutation,
  useCreateQuestionnaireMutation,
  useUpdateQuestionnaireMutation,
  useLazyGetQuestionnaireQuery,
  useGetMultiCascadeMutation,
  useFilterDataMutation,
  useSearchBidQuery,
  useLazyGetAllUsersQuery,
  useAddTherapyMutation,
  useUpdateTherapyMutation,
  // useGetNotificationsCountQuery,
  useGetPlacesVisitedMutation,
  useGetSellerPlacesVisitedMutation,
  useGetFileForBackendMutation,
  useAutoMatchMutation,
  useGetAllNotificationQuery,
  useGetAllInstitutesQuery,
  useGetInstitutionalUsersMutation,
  useGetPredictedDataofUserQuery,
  useGetAllDepartmentsQuery,
  useGetHealthProffessionalsMutation,
  useUpdateSellersGraphNotificationsMutation,
  useSendPaymentStripeIdMutation,
  useDeleteUserbyEmailMutation
} = api;

export default api;
