const dataItemsForTherapies = () => {
  const dataItemsForTherapies =
    [
      {
        label: 'Therapies',
        multiSelect: true,
        value: 'Therapies',
        children: [
          {
            label: 'Current Therapies',
            multiSelect: false,
            value: 'Therapies_Current',
            parent: 'Therapies',
            children: [
              {
                label: 'Pharmaceutical',
                multiSelect: false,
                value: 'Therapies_Current_Pharmaceutical',
                parent: 'Therapies_Current',
                children: [
                  {
                    label: 'Tablet',
                    multiSelect: false,
                    value: 'Therapies_Current_Pharmaceutical_Tablet',
                    parent: 'Therapies_Current_Pharmaceutical',
                    children: [
                      {
                        label: 'No other',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_No other',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        displayName: 'No other tablet',
                        children: [],
                      },

                      {
                        label: 'Pyridostigmine bromide (Mestinon®)',
                        displayName: 'Current - Pyridostigmine bromide (Mestinon®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Mestinon',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Mestinon_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Mestinon',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Mestinon® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mestinon_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mestinon_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Mestinon® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mestinon_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mestinon_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mestinon_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Mestinon® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mestinon_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mestinon® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mestinon_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mestinon® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mestinon_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mestinon® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mestinon_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Mestinon® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mestinon_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mestinon_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Mestinon',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Mestinon® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mestinon_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Mestinon® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mestinon_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mestinon_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Mestinon® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mestinon® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mestinon® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mestinon® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Mestinon® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mestinon_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Mestinon',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Mestinon® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mestinon_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Mestinon® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mestinon_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mestinon_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Mestinon® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mestinon® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mestinon® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mestinon® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Mestinon® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mestinon_Third_therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Pyridostigmine bromide (Timespan®)',
                        displayName: 'Current - Pyridostigmine bromide (Timespan®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Timespan',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Timespan_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Timespan',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Timespan® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Timespan_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Timespan_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Timespan® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Timespan_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Timespan_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Timespan_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Timespan_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Timespan® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Timespan_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Timespan_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Timespan® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Timespan_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Timespan_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Timespan® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Timespan_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Timespan_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Timespan® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Timespan_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Timespan_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Timespan® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Timespan_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Timespan_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Timespan_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Timespan',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Timespan® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Timespan_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Timespan_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Timespan® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Timespan_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Timespan_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Timespan_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Timespan_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Timespan® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Timespan_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Timespan_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Timespan® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Timespan_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Timespan_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Timespan® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Timespan_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Timespan_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Timespan® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Timespan_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Timespan_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Timespan® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Timespan_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Timespan_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Timespan_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Timespan',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Timespan® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Timespan_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Timespan_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Timespan® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Timespan_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Timespan_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Timespan_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Timespan_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Timespan® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Timespan_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Timespan_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Timespan® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Timespan_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Timespan_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Timespan® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Timespan_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Timespan_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Timespan® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Timespan_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Timespan_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Timespan® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Timespan_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Timespan_Third_therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Prednisone (Generic)',
                        displayName: 'Current - Prednisone (Generic)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Prednisone) (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Prednisone) (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Prednisone) (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Prednisone) (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Prednisone) (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Prednisone) (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Prednisone) (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Prednisone) (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Prednisone) (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Prednisone) (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Prednisone) (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Prednisone) (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Prednisone) (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Prednisone) (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Prednisone) (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Prednisone) (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Prednisone) (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Prednisone) (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Prednisone) (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Prednisone) (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Prednisone) (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)_Third_therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Azathioprine (Imuran®)',
                        displayName: 'Current - Azathioprine (Imuran®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Imuran',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Imuran_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Imuran',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Imuran® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Imuran_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Imuran_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Imuran® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Imuran_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Imuran_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Imuran_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Imuran_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Imuran® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Imuran_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Imuran_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Imuran® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Imuran_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Imuran_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Imuran® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Imuran_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Imuran_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Imuran® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Imuran_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Imuran_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Imuran® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Imuran_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Imuran_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Imuran_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Imuran',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Imuran® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Imuran_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Imuran_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Imuran® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Imuran_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Imuran_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Imuran_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Imuran_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Imuran® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Imuran_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Imuran_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Imuran® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Imuran_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Imuran_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Imuran® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Imuran_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Imuran_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Imuran® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Imuran_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Imuran_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Imuran® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Imuran_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Imuran_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Imuran_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Imuran',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Imuran® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Imuran_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Imuran_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Imuran® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Imuran_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Imuran_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Imuran_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Imuran_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Imuran® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Imuran_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Imuran_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Imuran® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Imuran_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Imuran_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Imuran® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Imuran_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Imuran_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Imuran® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Imuran_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Imuran_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Imuran® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Imuran_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Imuran_Third_therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Azathioprine (Generic)',
                        displayName: 'Current - Azathioprine (Generic)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Azathioprine) (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Azathioprine) (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Azathioprine) (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Azathioprine) (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Azathioprine) (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Azathioprine) (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Azathioprine) (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Azathioprine) (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Azathioprine) (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Azathioprine) (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Azathioprine) (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Azathioprine) (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Azathioprine) (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Azathioprine) (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Azathioprine) (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Azathioprine) (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Azathioprine) (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Azathioprine) (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Azathioprine) (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Azathioprine) (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Azathioprine) (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)_Third_therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Mycofenolaatmofetil (Cellcept®)',
                        displayName: 'Current - Mycofenolaatmofetil (Cellcept®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Cellcept',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Cellcept_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Cellcept',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Cellcept® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Cellcept_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Cellcept_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Cellcept® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Cellcept_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Cellcept_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Cellcept_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Cellcept® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Cellcept_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Cellcept® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Cellcept_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Cellcept® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Cellcept_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Cellcept® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Cellcept_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Cellcept® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Cellcept_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Cellcept_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Cellcept',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Cellcept® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Cellcept_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Cellcept® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Cellcept_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Cellcept_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Cellcept® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Cellcept® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Cellcept® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Cellcept® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Cellcept® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Cellcept_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Cellcept',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Cellcept® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Cellcept_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Cellcept® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Cellcept_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Cellcept_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Cellcept® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Cellcept® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Cellcept® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Cellcept® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Cellcept® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Cellcept_Third_therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Mycofenolaatmofetil (Generic)',
                        displayName: 'Current - Mycofenolaatmofetil (Generic)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Mycofenolaatmofetil) (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Mycofenolaatmofetil) (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Mycofenolaatmofetil) (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Mycofenolaatmofetil) (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Mycofenolaatmofetil) (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Mycofenolaatmofetil) (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Mycofenolaatmofetil) (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Mycofenolaatmofetil) (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Mycofenolaatmofetil) (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Mycofenolaatmofetil) (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Mycofenolaatmofetil) (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Mycofenolaatmofetil) (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Mycofenolaatmofetil) (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Mycofenolaatmofetil) (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Mycofenolaatmofetil) (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Mycofenolaatmofetil) (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Mycofenolaatmofetil) (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Mycofenolaatmofetil) (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Mycofenolaatmofetil) (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Mycofenolaatmofetil) (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Mycofenolaatmofetil) (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Third_therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Cyclosporin (Generic)',
                        displayName: 'Current - Cyclosporin (Generic)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Cyclosporin) (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Cyclosporin) (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Cyclosporin) (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Cyclosporin) (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Cyclosporin) (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Cyclosporin) (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Cyclosporin) (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Cyclosporin) (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Cyclosporin) (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Cyclosporin) (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Cyclosporin) (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Cyclosporin) (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Cyclosporin) (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Cyclosporin) (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Cyclosporin) (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Cyclosporin) (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Cyclosporin) (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Cyclosporin) (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Cyclosporin) (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Cyclosporin) (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Cyclosporin) (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)_Third_therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },

                      {
                        label: 'Semaglutide (Ozempic®)',
                        displayName: 'Current - Semaglutide (Ozempic®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Ozempic',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Ozempic_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Ozempic',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Ozempic® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ozempic_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ozempic_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ozempic® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ozempic_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ozempic_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ozempic_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ozempic® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ozempic_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ozempic® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ozempic_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ozempic® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ozempic_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ozempic® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ozempic_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ozempic® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ozempic_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ozempic_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Ozempic',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Ozempic® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ozempic_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ozempic® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ozempic_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ozempic_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ozempic® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ozempic® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ozempic® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ozempic® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ozempic® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ozempic_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Ozempic',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Ozempic® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ozempic_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ozempic® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ozempic_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ozempic_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ozempic® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ozempic® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ozempic® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ozempic® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ozempic® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ozempic_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ozempic_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Metformin (Generic)',
                        displayName: 'Current - Metformin (Generic)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Metformin) (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Metformin) (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Metformin) (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Metformin) (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Metformin) (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Metformin) (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Metformin) (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Metformin) (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Metformin) (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Metformin) (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Metformin) (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Metformin) (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Metformin) (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Metformin) (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Metformin) (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Metformin) (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Metformin) (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Metformin) (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Metformin) (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Metformin) (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Metformin) (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Tolbutamide (Generic)',
                        displayName: 'Current - Tolbutamide (Generic)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Tolbutamide) (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Tolbutamide) (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Tolbutamide) (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Tolbutamide) (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Tolbutamide) (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Tolbutamide) (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Tolbutamide) (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Tolbutamide) (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Tolbutamide) (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Tolbutamide) (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Tolbutamide) (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (tolbutamide) (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (tolbutamide) (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (tolbutamide) (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (tolbutamide) (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (tolbutamide) (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (tolbutamide) (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (tolbutamide) (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (tolbutamide) (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (tolbutamide) (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (tolbutamide) (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Glimepiride (Amaryl®)',
                        displayName: 'Current - Glimepiride (Amaryl®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Amaryl',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Amaryl_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Amaryl',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Amaryl® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Amaryl_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Amaryl_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Amaryl® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Amaryl_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Amaryl_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Amaryl_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Amaryl® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Amaryl_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Amaryl® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Amaryl_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Amaryl® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Amaryl_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Amaryl® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Amaryl_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Amaryl® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Amaryl_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Amaryl_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Amaryl',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Amaryl® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Amaryl_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Amaryl® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Amaryl_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Amaryl_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Amaryl® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Amaryl® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Amaryl® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Amaryl® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Amaryl® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Amaryl_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Amaryl',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Amaryl® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Amaryl_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Amaryl® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Amaryl_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Amaryl_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Amaryl® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Amaryl® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Amaryl® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Amaryl® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Amaryl® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Amaryl_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Amaryl_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Glimepiride (Generic)',
                        displayName: 'Current - Glimepiride (Generic)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (glimepiride) (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (glimepiride) (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (glimepiride) (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glimepiride) (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glimepiride) (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glimepiride) (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (glimepiride) (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (glimepiride) (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (glimepiride) (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (glimepiride) (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glimepiride) (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glimepiride) (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glimepiride) (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (glimepiride) (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (glimepiride) (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (glimepiride) (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (glimepiride) (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glimepiride) (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glimepiride) (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glimepiride) (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (glimepiride) (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Glipizide (Glucotrol®)',
                        displayName: 'Current - Glipizide (Glucotrol®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Glucotrol',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Glucotrol',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glucotrol® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glucotrol® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glucotrol® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glucotrol® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Glucotrol',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glucotrol® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glucotrol® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glucotrol® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glucotrol® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Glucotrol',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glucotrol® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glucotrol® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glucotrol® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glucotrol® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Glipizide (Glucotrol XL®)',
                        displayName: 'Current - Glipizide (Glucotrol XL®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glucotrol XL® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glucotrol XL® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glucotrol XL® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol XL® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol XL® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol XL® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glucotrol XL® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glucotrol XL® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glucotrol XL® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glucotrol XL® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol XL® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol XL® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol XL® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glucotrol XL® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glucotrol XL® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glucotrol XL® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glucotrol XL® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol XL® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol XL® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol XL® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glucotrol XL® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Glipizide (Generic)',
                        displayName: 'Current - Glipizide (Generic)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (glipizide) (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (glipizide) (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (glipizide) (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glipizide) (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glipizide) (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glipizide) (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (glipizide) (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (glipizide) (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (glipizide) (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (glipizide) (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glipizide) (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glipizide) (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glipizide) (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (glipizide) (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (glipizide) (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (glipizide) (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (glipizide) (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glipizide) (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glipizide) (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glipizide) (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (glipizide) (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Glyburide (Micronase®)',
                        displayName: 'Current - Glyburide (Micronase®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Micronase',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Micronase_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Micronase',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Micronase® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Micronase_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Micronase_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Micronase® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Micronase_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Micronase_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Micronase_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Micronase_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Micronase® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Micronase_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Micronase_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Micronase® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Micronase_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Micronase_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Micronase® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Micronase_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Micronase_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Micronase® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Micronase_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Micronase_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Micronase® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Micronase_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Micronase_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Micronase_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Micronase',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Micronase® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Micronase_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Micronase_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Micronase® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Micronase_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Micronase_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Micronase_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Micronase_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Micronase® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Micronase_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Micronase_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Micronase® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Micronase_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Micronase_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Micronase® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Micronase_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Micronase_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Micronase® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Micronase_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Micronase_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Micronase® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Micronase_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Micronase_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Micronase_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Micronase',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Micronase® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Micronase_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Micronase_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Micronase® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Micronase_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Micronase_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Micronase_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Micronase_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Micronase® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Micronase_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Micronase_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Micronase® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Micronase_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Micronase_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Micronase® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Micronase_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Micronase_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Micronase® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Micronase_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Micronase_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Micronase® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Micronase_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Micronase_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Glyburide (DiaBeta®)',
                        displayName: 'Current - Glyburide (DiaBeta®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_DiaBeta',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_DiaBeta',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'DiaBeta® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'DiaBeta® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'DiaBeta® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'DiaBeta® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'DiaBeta® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'DiaBeta® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'DiaBeta® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_DiaBeta',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'DiaBeta® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'DiaBeta® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'DiaBeta® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'DiaBeta® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'DiaBeta® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'DiaBeta® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'DiaBeta® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_DiaBeta',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'DiaBeta® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'DiaBeta® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'DiaBeta® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'DiaBeta® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'DiaBeta® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'DiaBeta® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'DiaBeta® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_DiaBeta_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Glyburide (Generic)',
                        displayName: 'Current - Glyburide (Generic)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (glyburide) (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (glyburide) (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (glyburide) (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide) (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide) (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide) (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (glyburide) (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (glyburide) (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (glyburide) (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (glyburide) (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide) (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide) (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide) (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (glyburide) (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (glyburide) (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (glyburide) (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (glyburide) (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide) (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide) (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide) (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (glyburide) (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Glyburide, Micronized (Glynase PresTab®)',
                        displayName:
                          'Current - Glyburide, Micronized (Glynase PresTab®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glynase PresTab® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glynase PresTab® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glynase PresTab® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glynase PresTab® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glynase PresTab® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glynase PresTab® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glynase PresTab® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glynase PresTab® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glynase PresTab® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glynase PresTab® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glynase PresTab® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glynase PresTab® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glynase PresTab® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glynase PresTab® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glynase PresTab® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glynase PresTab® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glynase PresTab® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glynase PresTab® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glynase PresTab® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glynase PresTab® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glynase PresTab® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Glyburide, Micronized (Generic)',
                        displayName: 'Current - Glyburide, Micronized (Generic)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (glyburide, micronized) (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (glyburide, micronized) (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (glyburide, micronized) (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide, micronized) (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide, micronized) (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide, micronized) (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (glyburide, micronized) (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (glyburide, micronized) (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (glyburide, micronized) (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (glyburide, micronized) (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide, micronized) (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide, micronized) (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide, micronized) (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (glyburide, micronized) (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (glyburide, micronized) (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (glyburide, micronized) (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (glyburide, micronized) (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide, micronized) (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide, micronized) (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide, micronized) (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (glyburide, micronized) (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Metformin (Glucophage®)',
                        displayName: 'Current - Metformin (Glucophage®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Glucophage',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Glucophage_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Glucophage',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glucophage® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glucophage® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glucophage® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glucophage® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Glucophage',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glucophage® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glucophage® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glucophage® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glucophage® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Glucophage',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glucophage® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glucophage® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glucophage® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glucophage® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Metformin (Glucophage XR®)',
                        displayName: 'Current - Metformin (Glucophage XR®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glucophage XR® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glucophage XR® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glucophage XR® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage XR® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage XR® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage XR® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glucophage XR® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glucophage XR® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glucophage XR® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glucophage XR® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage XR® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage XR® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage XR® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glucophage XR® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glucophage XR® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glucophage XR® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glucophage XR® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage XR® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage XR® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage XR® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glucophage XR® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Metformin (Fortamet®)',
                        displayName: 'Current - Metformin (Fortamet®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Fortamet',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Fortamet_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Fortamet',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Fortamet® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Fortamet_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Fortamet_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Fortamet® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Fortamet_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Fortamet_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Fortamet_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Fortamet® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Fortamet_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fortamet® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Fortamet_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fortamet® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Fortamet_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fortamet® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Fortamet_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Fortamet® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Fortamet_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Fortamet_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Fortamet',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Fortamet® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Fortamet_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Fortamet® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Fortamet_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Fortamet_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Fortamet® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fortamet® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fortamet® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fortamet® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Fortamet® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Fortamet_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Fortamet',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Fortamet® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Fortamet_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Fortamet® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Fortamet_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Fortamet_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Fortamet® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fortamet® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fortamet® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fortamet® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Fortamet® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Fortamet_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Fortamet_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Metformin (Glumetza®)',
                        displayName: 'Current - Metformin (Glumetza®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Glumetza',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Glumetza_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Glumetza',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glumetza® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glumetza_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glumetza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glumetza® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glumetza_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glumetza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glumetza_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glumetza® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glumetza_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glumetza® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glumetza_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glumetza® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glumetza_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glumetza® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glumetza_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glumetza® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glumetza_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glumetza_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Glumetza',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glumetza® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glumetza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glumetza® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glumetza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glumetza_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glumetza® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glumetza® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glumetza® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glumetza® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glumetza® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glumetza_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Glumetza',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glumetza® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glumetza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glumetza® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glumetza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glumetza_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glumetza® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glumetza® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glumetza® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glumetza® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glumetza® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glumetza_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Glumetza_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Metformin (Riomet®)',
                        displayName: 'Current - Metformin (Riomet®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Riomet',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Riomet_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Riomet',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Riomet® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Riomet_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Riomet_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Riomet® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Riomet_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Riomet_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Riomet_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Riomet_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Riomet® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Riomet_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Riomet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Riomet® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Riomet_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Riomet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Riomet® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Riomet_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Riomet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Riomet® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Riomet_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Riomet_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Riomet® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Riomet_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Riomet_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Riomet_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Riomet',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Riomet® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Riomet_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Riomet_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Riomet® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Riomet_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Riomet_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Riomet_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Riomet_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Riomet® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Riomet_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Riomet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Riomet® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Riomet_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Riomet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Riomet® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Riomet_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Riomet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Riomet® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Riomet_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Riomet_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Riomet® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Riomet_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Riomet_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Riomet_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Riomet',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Riomet® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Riomet_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Riomet_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Riomet® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Riomet_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Riomet_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Riomet_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Riomet_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Riomet® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Riomet_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Riomet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Riomet® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Riomet_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Riomet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Riomet® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Riomet_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Riomet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Riomet® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Riomet_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Riomet_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Riomet® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Riomet_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Riomet_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Pioglitazone (Actos®)',
                        displayName: 'Current - Pioglitazone (Actos®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Actos',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Actos_First therapy',
                            parent: 'Therapies_Current_Pharmaceutical_Tablet_Actos',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Actos® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Actos_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Actos_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Actos® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Actos_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Actos_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Actos_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Actos_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Actos® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Actos_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Actos_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actos® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Actos_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Actos_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actos® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Actos_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Actos_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actos® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Actos_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Actos_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Actos® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Actos_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Actos_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Actos_Second therapy',
                            parent: 'Therapies_Current_Pharmaceutical_Tablet_Actos',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Actos® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Actos_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Actos_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Actos® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Actos_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Actos_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Actos_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Actos_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Actos® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Actos_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Actos_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actos® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Actos_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Actos_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actos® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Actos_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Actos_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actos® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Actos_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Actos_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Actos® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Actos_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Actos_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Actos_Third therapy',
                            parent: 'Therapies_Current_Pharmaceutical_Tablet_Actos',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Actos® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Actos_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Actos_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Actos® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Actos_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Actos_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Actos_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Actos_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Actos® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Actos_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Actos_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actos® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Actos_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Actos_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actos® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Actos_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Actos_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actos® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Actos_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Actos_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Actos® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Actos_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Actos_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Rosiglitazone (Avandia®)',
                        displayName: 'Current - Rosiglitazone (Avandia®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Avandia',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Avandia_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Avandia',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Avandia® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Avandia_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Avandia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Avandia® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Avandia_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Avandia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Avandia_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Avandia_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Avandia® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Avandia_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Avandia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avandia® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Avandia_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Avandia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avandia® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Avandia_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Avandia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avandia® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Avandia_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Avandia_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Avandia® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Avandia_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Avandia_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Avandia_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Avandia',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Avandia® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Avandia_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Avandia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Avandia® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Avandia_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Avandia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Avandia_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Avandia_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Avandia® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Avandia_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Avandia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avandia® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Avandia_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Avandia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avandia® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Avandia_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Avandia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avandia® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Avandia_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Avandia_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Avandia® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Avandia_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Avandia_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Avandia_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Avandia',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Avandia® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Avandia_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Avandia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Avandia® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Avandia_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Avandia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Avandia_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Avandia_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Avandia® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Avandia_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Avandia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avandia® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Avandia_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Avandia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avandia® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Avandia_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Avandia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avandia® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Avandia_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Avandia_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Avandia® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Avandia_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Avandia_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Alogliptin (Nesina®)',
                        displayName: 'Current - Alogliptin (Nesina®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Nesina',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Nesina_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Nesina',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Nesina® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Nesina_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Nesina_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Nesina® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Nesina_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Nesina_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Nesina_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Nesina_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Nesina® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Nesina_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Nesina_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nesina® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Nesina_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Nesina_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nesina® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Nesina_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Nesina_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nesina® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Nesina_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Nesina_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Nesina® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Nesina_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Nesina_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Nesina_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Nesina',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Nesina® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Nesina_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Nesina_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Nesina® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Nesina_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Nesina_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Nesina_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Nesina_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Nesina® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Nesina_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Nesina_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nesina® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Nesina_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Nesina_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nesina® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Nesina_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Nesina_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nesina® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Nesina_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Nesina_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Nesina® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Nesina_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Nesina_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Nesina_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Nesina',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Nesina® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Nesina_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Nesina_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Nesina® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Nesina_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Nesina_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Nesina_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Nesina_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Nesina® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Nesina_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Nesina_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nesina® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Nesina_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Nesina_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nesina® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Nesina_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Nesina_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nesina® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Nesina_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Nesina_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Nesina® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Nesina_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Nesina_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Sitagliptin (Januvia®)',
                        displayName: 'Current - Sitagliptin (Januvia®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Januvia',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Januvia_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Januvia',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Januvia® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Januvia_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Januvia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Januvia® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Januvia_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Januvia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Januvia_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Januvia_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Januvia® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Januvia_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Januvia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Januvia® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Januvia_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Januvia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Januvia® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Januvia_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Januvia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Januvia® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Januvia_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Januvia_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Januvia® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Januvia_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Januvia_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Januvia_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Januvia',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Januvia® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Januvia_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Januvia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Januvia® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Januvia_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Januvia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Januvia_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Januvia_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Januvia® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Januvia_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Januvia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Januvia® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Januvia_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Januvia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Januvia® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Januvia_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Januvia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Januvia® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Januvia_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Januvia_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Januvia® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Januvia_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Januvia_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Januvia_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Januvia',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Januvia® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Januvia_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Januvia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Januvia® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Januvia_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Januvia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Januvia_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Januvia_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Januvia® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Januvia_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Januvia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Januvia® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Januvia_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Januvia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Januvia® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Januvia_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Januvia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Januvia® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Januvia_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Januvia_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Januvia® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Januvia_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Januvia_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Saxagliptin (Onglyza®)',
                        displayName: 'Current - Saxagliptin (Onglyza®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Onglyza',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Onglyza_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Onglyza',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Onglyza® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Onglyza_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Onglyza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Onglyza® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Onglyza_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Onglyza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Onglyza_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Onglyza® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Onglyza_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Onglyza® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Onglyza_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Onglyza® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Onglyza_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Onglyza® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Onglyza_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Onglyza® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Onglyza_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Onglyza_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Onglyza',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Onglyza® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Onglyza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Onglyza® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Onglyza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Onglyza_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Onglyza® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Onglyza® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Onglyza® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Onglyza® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Onglyza® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Onglyza_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Onglyza',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Onglyza® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Onglyza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Onglyza® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Onglyza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Onglyza_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Onglyza® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Onglyza® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Onglyza® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Onglyza® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Onglyza® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Onglyza_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Onglyza_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Linagliptin (Tradjenta®)',
                        displayName: 'Current - Linagliptin (Tradjenta®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Tradjenta',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Tradjenta',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Tradjenta® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tradjenta® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tradjenta® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tradjenta® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tradjenta® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tradjenta® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tradjenta® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Tradjenta',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Tradjenta® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tradjenta® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tradjenta® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tradjenta® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tradjenta® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tradjenta® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tradjenta® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Tradjenta',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Tradjenta® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tradjenta® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tradjenta® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tradjenta® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tradjenta® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tradjenta® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tradjenta® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tradjenta_Third therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Canagliflozin (Invokana®)',
                        displayName: 'Current - Canagliflozin (Invokana®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Invokana',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Invokana_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Invokana',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Invokana® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Invokana_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Invokana_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Invokana® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Invokana_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Invokana_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Invokana_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Invokana_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Invokana® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Invokana_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Invokana_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Invokana® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Invokana_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Invokana_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Invokana® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Invokana_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Invokana_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Invokana® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Invokana_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Invokana_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Invokana® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Invokana_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Invokana_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Invokana_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Invokana',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Invokana® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Invokana_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Invokana_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Invokana® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Invokana_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Invokana_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Invokana_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Invokana_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Invokana® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Invokana_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Invokana_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Invokana® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Invokana_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Invokana_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Invokana® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Invokana_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Invokana_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Invokana® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Invokana_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Invokana_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Invokana® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Invokana_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Invokana_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Invokana_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Invokana',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Invokana® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Invokana_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Invokana_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Invokana® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Invokana_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Invokana_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Invokana_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Invokana_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Invokana® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Invokana_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Invokana_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Invokana® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Invokana_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Invokana_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Invokana® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Invokana_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Invokana_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Invokana® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Invokana_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Invokana_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Invokana® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Invokana_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Invokana_Third therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Dapagliflozin (Farxiga®)',
                        displayName: 'Current - Dapagliflozin (Farxiga®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Farxiga',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Farxiga_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Farxiga',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Farxiga® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Farxiga_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Farxiga_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Farxiga® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Farxiga_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Farxiga_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Farxiga_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Farxiga® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Farxiga_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Farxiga® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Farxiga_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Farxiga® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Farxiga_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Farxiga® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Farxiga_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Farxiga® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Farxiga_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Farxiga_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Farxiga',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Farxiga® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Farxiga_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Farxiga® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Farxiga_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Farxiga_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Farxiga® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Farxiga® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Farxiga® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Farxiga® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Farxiga® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Farxiga_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Farxiga',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Farxiga® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Farxiga_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Farxiga® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Farxiga_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Farxiga_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Farxiga® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Farxiga® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Farxiga® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Farxiga® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Farxiga® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Farxiga_Third therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Empagliflozin (Jardiance®)',
                        displayName: 'Current - Empagliflozin (Jardiance®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Jardiance',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Jardiance_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Jardiance',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Jardiance® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Jardiance_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Jardiance_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Jardiance® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Jardiance_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Jardiance_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Jardiance_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Jardiance® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Jardiance_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Jardiance® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Jardiance_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Jardiance® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Jardiance_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Jardiance® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Jardiance_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Jardiance® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Jardiance_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Jardiance_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Jardiance',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Jardiance® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Jardiance_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Jardiance® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Jardiance_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Jardiance_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Jardiance® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Jardiance® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Jardiance® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Jardiance® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Jardiance® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Jardiance_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Jardiance',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Jardiance® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Jardiance_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Jardiance® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Jardiance_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Jardiance_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Jardiance® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Jardiance® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Jardiance® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Jardiance® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Jardiance® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Jardiance_Third therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },

                      {
                        label: 'Cladribine (Mavenclad®) 10mg',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Mavenclad',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Mavenclad',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Mavenclad® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Mavenclad® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Mavenclad® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mavenclad® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mavenclad® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mavenclad® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Mavenclad® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Mavenclad',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Mavenclad® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Mavenclad® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Mavenclad® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mavenclad® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mavenclad® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mavenclad® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Mavenclad® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Mavenclad',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Mavenclad® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Mavenclad® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Mavenclad® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mavenclad® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mavenclad® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mavenclad® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Mavenclad® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mavenclad_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Dimethyl Fumarate (Tecfidera®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Tecfidera',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Tecfidera',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Tecfidera® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tecfidera® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tecfidera® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tecfidera® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tecfidera® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tecfidera® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tecfidera® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Tecfidera',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Tecfidera® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tecfidera® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tecfidera® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tecfidera® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tecfidera® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tecfidera® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tecfidera® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Tecfidera',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Tecfidera® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tecfidera® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tecfidera® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tecfidera® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tecfidera® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tecfidera® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tecfidera® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tecfidera_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Diroximel Fumarate (Vumerity®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Vumerity',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Vumerity_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Vumerity',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Vumerity® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Vumerity_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Vumerity_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Vumerity® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Vumerity_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Vumerity_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Vumerity_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Vumerity® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Vumerity_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vumerity® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Vumerity_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vumerity® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Vumerity_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vumerity® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Vumerity_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Vumerity® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Vumerity_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Vumerity_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Vumerity',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Vumerity® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Vumerity_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Vumerity® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Vumerity_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Vumerity_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Vumerity® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vumerity® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vumerity® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vumerity® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Vumerity® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Vumerity_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Vumerity',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Vumerity® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Vumerity_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Vumerity® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Vumerity_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Vumerity_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Vumerity® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vumerity® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vumerity® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vumerity® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Vumerity® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Vumerity_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Vumerity_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Fingolimod (Gilenya®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Gilenya',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Gilenya_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Gilenya',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Gilenya® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Gilenya_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Gilenya_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Gilenya® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Gilenya_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Gilenya_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Gilenya_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Gilenya® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Gilenya_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Gilenya® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Gilenya_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Gilenya® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Gilenya_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Gilenya® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Gilenya_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Gilenya® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Gilenya_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Gilenya_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Gilenya',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Gilenya® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Gilenya_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Gilenya® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Gilenya_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Gilenya_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Gilenya® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Gilenya® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Gilenya® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Gilenya® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Gilenya® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Gilenya_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Gilenya',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Gilenya® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Gilenya_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Gilenya® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Gilenya_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Gilenya_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Gilenya® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Gilenya® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Gilenya® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Gilenya® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Gilenya® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Gilenya_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Gilenya_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Fingolimod (Tascenso®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Tascenso',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Tascenso_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Tascenso',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Tascenso® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tascenso_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tascenso_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tascenso® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tascenso_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tascenso_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tascenso_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tascenso® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tascenso_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tascenso® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tascenso_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tascenso® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tascenso_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tascenso® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tascenso_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tascenso® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tascenso_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tascenso_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Tascenso',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Tascenso® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tascenso_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tascenso® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tascenso_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tascenso_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tascenso® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tascenso® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tascenso® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tascenso® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tascenso® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tascenso_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Tascenso',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Tascenso® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tascenso_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tascenso® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tascenso_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tascenso_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tascenso® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tascenso® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tascenso® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tascenso® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tascenso® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tascenso_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Tascenso_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Monomethyl Fumarate (Bafiertam®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Bafiertam',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Bafiertam',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Bafiertam® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Bafiertam® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Bafiertam® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Bafiertam® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Bafiertam® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Bafiertam® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Bafiertam® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Bafiertam',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Bafiertam® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Bafiertam® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Bafiertam® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Bafiertam® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Bafiertam® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Bafiertam® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Bafiertam® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Bafiertam',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Bafiertam® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Bafiertam® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Bafiertam® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Bafiertam® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Bafiertam® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Bafiertam® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Bafiertam® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Bafiertam_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Ozanimod (Zeposia®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Zeposia',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Zeposia_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Zeposia',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Zeposia® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Zeposia_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Zeposia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Zeposia® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Zeposia_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Zeposia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Zeposia_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Zeposia® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Zeposia_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zeposia® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Zeposia_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zeposia® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Zeposia_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zeposia® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Zeposia_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Zeposia® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Zeposia_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Zeposia_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Zeposia',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Zeposia® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Zeposia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Zeposia® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Zeposia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Zeposia_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Zeposia® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zeposia® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zeposia® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zeposia® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Zeposia® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Zeposia_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Zeposia',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Zeposia® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Zeposia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Zeposia® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Zeposia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Zeposia_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Zeposia® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zeposia® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zeposia® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zeposia® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Zeposia® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Zeposia_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Zeposia_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Ponesimod (Ponvory®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Ponvory',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Ponvory_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Ponvory',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Ponvory® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ponvory_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ponvory_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ponvory® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ponvory_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ponvory_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ponvory_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ponvory® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ponvory_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ponvory® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ponvory_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ponvory® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ponvory_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ponvory® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ponvory_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ponvory® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ponvory_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ponvory_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Ponvory',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Ponvory® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ponvory_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ponvory® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ponvory_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ponvory_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ponvory® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ponvory® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ponvory® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ponvory® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ponvory® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ponvory_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Ponvory',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Ponvory® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ponvory_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ponvory® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ponvory_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ponvory_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ponvory® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ponvory® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ponvory® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ponvory® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ponvory® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ponvory_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Ponvory_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Siponimod (Mayzent®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Mayzent',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Mayzent_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Mayzent',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Mayzent® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mayzent_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mayzent_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Mayzent® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mayzent_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mayzent_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mayzent_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Mayzent® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mayzent_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mayzent® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mayzent_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mayzent® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mayzent_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mayzent® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mayzent_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Mayzent® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mayzent_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mayzent_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Mayzent',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Mayzent® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mayzent_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Mayzent® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mayzent_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mayzent_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Mayzent® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mayzent® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mayzent® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mayzent® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Mayzent® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mayzent_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Mayzent',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Mayzent® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mayzent_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Mayzent® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mayzent_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mayzent_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Mayzent® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mayzent® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mayzent® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mayzent® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Mayzent® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mayzent_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Mayzent_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Teriflunomide (Aubagio®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet_Aubagio',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Aubagio_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Aubagio',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Aubagio® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Aubagio_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Aubagio_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Aubagio® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Aubagio_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Aubagio_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Aubagio_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Aubagio® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Aubagio_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Aubagio® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Aubagio_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Aubagio® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Aubagio_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Aubagio® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Aubagio_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Aubagio® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Aubagio_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Aubagio_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Aubagio',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Aubagio® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Aubagio_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Aubagio® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Aubagio_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Aubagio_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Aubagio® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Aubagio® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Aubagio® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Aubagio® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Aubagio® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Aubagio_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Aubagio',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Aubagio® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Aubagio_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Aubagio® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Aubagio_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Aubagio_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Aubagio® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Aubagio® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Aubagio® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Aubagio® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Aubagio® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Aubagio_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Aubagio_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Unspecified',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Tablet_Unspecified',
                        parent: 'Therapies_Current_Pharmaceutical_Tablet',
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Unspecified_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Unspecified',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Unspecified (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Unspecified_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Unspecified_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Unspecified (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Unspecified_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Unspecified_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Unspecified_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Unspecified (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Unspecified_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Unspecified (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Unspecified_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Unspecified (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Unspecified_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Unspecified (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Unspecified_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Unspecified (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Unspecified_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Unspecified_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Unspecified',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Unspecified (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Unspecified_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Unspecified (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Unspecified_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Unspecified_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Unspecified (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Unspecified (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Unspecified (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Unspecified (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Unspecified (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Unspecified_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Tablet_Unspecified',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Unspecified (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Unspecified_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Unspecified (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Unspecified_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Unspecified_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Unspecified (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Unspecified (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Unspecified (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Unspecified (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Unspecified (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Tablet_Unspecified_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Tablet_Unspecified_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    label: 'Injection',
                    multiSelect: false,
                    value: 'Therapies_Current_Pharmaceutical_Injection',
                    parent: 'Therapies_Current_Pharmaceutical',
                    children: [
                      {
                        label: 'No other',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Injection_No other',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        displayName: 'No other injection',
                        children: [],
                      },

                      {
                        label: 'Rozanolixizumab-noli (Rystiggo®)',
                        displayName: 'Current - Rozanolixizumab-noli (Rystiggo®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Injection_Rystiggo',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Rystiggo_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Rystiggo',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Rystiggo® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Rystiggo_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Rystiggo_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Rystiggo® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Rystiggo_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Rystiggo_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Rystiggo_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Rystiggo® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Rystiggo_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rystiggo® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Rystiggo_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rystiggo® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Rystiggo_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rystiggo® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Rystiggo_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Rystiggo® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Rystiggo_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Rystiggo_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Rystiggo',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Rystiggo® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Rystiggo_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Rystiggo® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Rystiggo_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Rystiggo_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Rystiggo® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rystiggo® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rystiggo® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rystiggo® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Rystiggo® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Rystiggo_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Rystiggo',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Rystiggo® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Rystiggo_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Rystiggo® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Rystiggo_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Rystiggo_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Rystiggo® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rystiggo® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rystiggo® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rystiggo® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Rystiggo® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Rystiggo_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Rystiggo_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Zilucoplan (Zilbrysq®)',
                        displayName: 'Current - Zilucoplan (Zilbrysq®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Injection_Zilbrysq',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Zilbrysq',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Zilbrysq® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Zilbrysq® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Zilbrysq® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zilbrysq® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zilbrysq® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zilbrysq® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Zilbrysq® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Zilbrysq',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Zilbrysq® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Zilbrysq® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Zilbrysq® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zilbrysq® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zilbrysq® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zilbrysq® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Zilbrysq® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Zilbrysq',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Zilbrysq® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Zilbrysq® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Zilbrysq® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zilbrysq® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zilbrysq® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zilbrysq® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Zilbrysq® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Zilbrysq_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },

                      {
                        label: 'Exenatide (Byetta®)',
                        displayName: 'Current - Exenatide (Byetta®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Injection_Byetta',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Byetta_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Byetta',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Byetta® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Byetta_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Byetta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Byetta® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Byetta_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Byetta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Byetta_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Byetta_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Byetta® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Byetta_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Byetta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Byetta® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Byetta_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Byetta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Byetta® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Byetta_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Byetta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Byetta® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Byetta_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Byetta_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Byetta® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Byetta_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Byetta_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Byetta_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Byetta',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Byetta® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Byetta_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Byetta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Byetta® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Byetta_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Byetta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Byetta_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Byetta_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Byetta® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Byetta_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Byetta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Byetta® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Byetta_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Byetta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Byetta® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Byetta_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Byetta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Byetta® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Byetta_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Byetta_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Byetta® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Byetta_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Byetta_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Byetta_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Byetta',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Byetta® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Byetta_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Byetta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Byetta® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Byetta_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Byetta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Byetta_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Byetta_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Byetta® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Byetta_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Byetta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Byetta® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Byetta_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Byetta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Byetta® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Byetta_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Byetta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Byetta® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Byetta_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Byetta_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Byetta® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Byetta_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Byetta_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Liraglutide (Victoza®)',
                        displayName: 'Current - Liraglutide (Victoza®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Injection_Victoza',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Victoza_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Victoza',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Victoza® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Victoza_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Victoza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Victoza® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Victoza_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Victoza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Victoza_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Victoza_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Victoza® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Victoza_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Victoza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Victoza® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Victoza_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Victoza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Victoza® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Victoza_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Victoza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Victoza® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Victoza_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Victoza_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Victoza® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Victoza_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Victoza_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Victoza_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Victoza',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Victoza® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Victoza_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Victoza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Victoza® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Victoza_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Victoza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Victoza_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Victoza_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Victoza® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Victoza_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Victoza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Victoza® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Victoza_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Victoza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Victoza® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Victoza_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Victoza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Victoza® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Victoza_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Victoza_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Victoza® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Victoza_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Victoza_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Victoza_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Victoza',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Victoza® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Victoza_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Victoza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Victoza® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Victoza_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Victoza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Victoza_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Victoza_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Victoza® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Victoza_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Victoza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Victoza® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Victoza_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Victoza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Victoza® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Victoza_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Victoza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Victoza® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Victoza_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Victoza_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Victoza® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Victoza_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Victoza_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Albiglutide (Tanzeum®)',
                        displayName: 'Current - Albiglutide (Tanzeum®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Injection_Tanzeum',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Tanzeum_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Tanzeum',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Tanzeum® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Tanzeum_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Tanzeum_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tanzeum® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Tanzeum_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Tanzeum_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Tanzeum_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tanzeum® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Tanzeum_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tanzeum® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Tanzeum_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tanzeum® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Tanzeum_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tanzeum® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Tanzeum_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tanzeum® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Tanzeum_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Tanzeum_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Tanzeum',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Tanzeum® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Tanzeum_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tanzeum® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Tanzeum_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Tanzeum_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tanzeum® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tanzeum® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tanzeum® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tanzeum® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tanzeum® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Tanzeum_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Tanzeum',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Tanzeum® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Tanzeum_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tanzeum® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Tanzeum_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Tanzeum_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tanzeum® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tanzeum® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tanzeum® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tanzeum® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tanzeum® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Tanzeum_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Tanzeum_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Dulaglutide (Trulicity®)',
                        displayName: 'Current - Dulaglutide (Trulicity®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Injection_Trulicity',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Trulicity_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Trulicity',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Trulicity® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Trulicity_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Trulicity_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Trulicity® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Trulicity_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Trulicity_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Trulicity_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Trulicity_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Trulicity® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Trulicity_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Trulicity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Trulicity® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Trulicity_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Trulicity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Trulicity® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Trulicity_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Trulicity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Trulicity® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Trulicity_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Trulicity_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Trulicity® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Trulicity_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Trulicity_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Trulicity_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Trulicity',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Trulicity® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Trulicity_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Trulicity_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Trulicity® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Trulicity_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Trulicity_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Trulicity_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Trulicity_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Trulicity® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Trulicity_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Trulicity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Trulicity® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Trulicity_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Trulicity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Trulicity® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Trulicity_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Trulicity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Trulicity® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Trulicity_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Trulicity_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Trulicity® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Trulicity_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Trulicity_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Trulicity_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Trulicity',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Trulicity® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Trulicity_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Trulicity_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Trulicity® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Trulicity_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Trulicity_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Trulicity_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Trulicity_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Trulicity® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Trulicity_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Trulicity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Trulicity® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Trulicity_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Trulicity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Trulicity® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Trulicity_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Trulicity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Trulicity® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Trulicity_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Trulicity_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Trulicity® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Trulicity_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Trulicity_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },


                      {
                        label: 'Insulin Glulisine (Apidra®)',
                        displayName: 'Current - Insulin Glulisine (Apidra®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Injection_Apidra',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Apidra_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Apidra',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Apidra® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Apidra_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Apidra_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Apidra® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Apidra_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Apidra_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Apidra_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Apidra_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Apidra® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Apidra_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Apidra_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Apidra® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Apidra_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Apidra_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Apidra® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Apidra_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Apidra_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Apidra® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Apidra_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Apidra_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Apidra® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Apidra_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Apidra_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Apidra_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Apidra',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Apidra® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Apidra_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Apidra_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Apidra® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Apidra_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Apidra_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Apidra_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Apidra_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Apidra® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Apidra_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Apidra_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Apidra® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Apidra_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Apidra_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Apidra® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Apidra_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Apidra_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Apidra® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Apidra_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Apidra_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Apidra® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Apidra_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Apidra_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Apidra_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Apidra',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Apidra® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Apidra_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Apidra_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Apidra® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Apidra_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Apidra_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Apidra_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Apidra_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Apidra® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Apidra_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Apidra_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Apidra® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Apidra_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Apidra_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Apidra® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Apidra_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Apidra_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Apidra® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Apidra_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Apidra_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Apidra® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Apidra_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Apidra_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Lispro (Humalog®)',
                        displayName: 'Current - Insulin Lispro (Humalog®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Injection_Humalog',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Humalog_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Humalog',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Humalog® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Humalog_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Humalog_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Humalog® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Humalog_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Humalog_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Humalog_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Humalog_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Humalog® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Humalog_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Humalog_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humalog® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Humalog_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Humalog_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humalog® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Humalog_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Humalog_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humalog® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Humalog_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Humalog_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Humalog® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Humalog_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Humalog_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Humalog_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Humalog',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Humalog® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Humalog_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Humalog_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Humalog® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Humalog_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Humalog_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Humalog_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Humalog_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Humalog® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Humalog_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Humalog_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humalog® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Humalog_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Humalog_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humalog® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Humalog_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Humalog_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humalog® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Humalog_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Humalog_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Humalog® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Humalog_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Humalog_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Humalog_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Humalog',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Humalog® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Humalog_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Humalog_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Humalog® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Humalog_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Humalog_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Humalog_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Humalog_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Humalog® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Humalog_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Humalog_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humalog® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Humalog_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Humalog_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humalog® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Humalog_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Humalog_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humalog® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Humalog_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Humalog_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Humalog® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Humalog_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Humalog_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Aspart (Novorapid®)',
                        displayName: 'Current - Insulin Aspart (Novorapid®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Injection_Novorapid',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Novorapid_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Novorapid',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Novorapid® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Novorapid_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Novorapid_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Novorapid® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Novorapid_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Novorapid_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Novorapid_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Novorapid_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Novorapid® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Novorapid_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Novorapid_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novorapid® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Novorapid_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Novorapid_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novorapid® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Novorapid_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Novorapid_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novorapid® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Novorapid_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Novorapid_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Novorapid® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Novorapid_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Novorapid_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Novorapid_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Novorapid',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Novorapid® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Novorapid_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Novorapid_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Novorapid® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Novorapid_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Novorapid_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Novorapid_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Novorapid_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Novorapid® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Novorapid_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Novorapid_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novorapid® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Novorapid_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Novorapid_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novorapid® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Novorapid_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Novorapid_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novorapid® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Novorapid_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Novorapid_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Novorapid® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Novorapid_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Novorapid_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Novorapid_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Novorapid',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Novorapid® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Novorapid_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Novorapid_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Novorapid® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Novorapid_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Novorapid_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Novorapid_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Novorapid_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Novorapid® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Novorapid_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Novorapid_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novorapid® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Novorapid_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Novorapid_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novorapid® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Novorapid_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Novorapid_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novorapid® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Novorapid_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Novorapid_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Novorapid® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Novorapid_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Novorapid_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Aspart (Fiasp®)',
                        displayName: 'Current - Insulin Aspart (Fiasp®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Injection_Fiasp',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Fiasp_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Fiasp',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Fiasp® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Fiasp_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Fiasp_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Fiasp® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Fiasp_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Fiasp_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Fiasp_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Fiasp_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Fiasp® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Fiasp_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Fiasp_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fiasp® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Fiasp_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Fiasp_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fiasp® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Fiasp_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Fiasp_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fiasp® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Fiasp_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Fiasp_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Fiasp® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Fiasp_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Fiasp_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Fiasp_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Fiasp',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Fiasp® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Fiasp_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Fiasp_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Fiasp® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Fiasp_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Fiasp_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Fiasp_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Fiasp_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Fiasp® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Fiasp_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Fiasp_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fiasp® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Fiasp_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Fiasp_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fiasp® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Fiasp_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Fiasp_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fiasp® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Fiasp_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Fiasp_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Fiasp® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Fiasp_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Fiasp_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Fiasp_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Fiasp',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Fiasp® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Fiasp_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Fiasp_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Fiasp® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Fiasp_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Fiasp_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Fiasp_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Fiasp_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Fiasp® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Fiasp_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Fiasp_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fiasp® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Fiasp_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Fiasp_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fiasp® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Fiasp_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Fiasp_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fiasp® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Fiasp_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Fiasp_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Fiasp® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Fiasp_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Fiasp_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Aspart (Insulin Aspart Sanofi®)',
                        displayName: 'Current - Insulin Aspart (Insulin Aspart Sanofi®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Insulin_Aspart_Sanofi® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Insulin_Aspart_Sanofi® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Insulin_Aspart_Sanofi® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Aspart_Sanofi® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Aspart_Sanofi® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Aspart_Sanofi® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Insulin_Aspart_Sanofi® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Insulin_Aspart_Sanofi® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Insulin_Aspart_Sanofi® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Insulin_Aspart_Sanofi® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Aspart_Sanofi® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Aspart_Sanofi® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Aspart_Sanofi® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Insulin_Aspart_Sanofi® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Insulin_Aspart_Sanofi® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Insulin_Aspart_Sanofi® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Insulin_Aspart_Sanofi® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Aspart_Sanofi® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Aspart_Sanofi® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Aspart_Sanofi® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Insulin_Aspart_Sanofi® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Aspart (Novomix®)',
                        displayName: 'Current - Insulin aspart (Novomix®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Injection_Novomix',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Novomix_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Novomix',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Novomix® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Novomix_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Novomix_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Novomix® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Novomix_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Novomix_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Novomix_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Novomix_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Novomix® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Novomix_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Novomix_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novomix® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Novomix_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Novomix_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novomix® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Novomix_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Novomix_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novomix® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Novomix_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Novomix_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Novomix® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Novomix_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Novomix_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Novomix_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Novomix',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Novomix® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Novomix_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Novomix_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Novomix® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Novomix_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Novomix_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Novomix_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Novomix_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Novomix® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Novomix_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Novomix_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novomix® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Novomix_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Novomix_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novomix® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Novomix_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Novomix_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novomix® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Novomix_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Novomix_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Novomix® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Novomix_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Novomix_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Novomix_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Novomix',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Novomix® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Novomix_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Novomix_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Novomix® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Novomix_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Novomix_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Novomix_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Novomix_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Novomix® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Novomix_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Novomix_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novomix® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Novomix_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Novomix_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novomix® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Novomix_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Novomix_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novomix® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Novomix_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Novomix_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Novomix® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Novomix_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Novomix_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Lispro (Lyumjev®)',
                        displayName: 'Current - Insulin Lispro (Lyumjev®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Injection_Lyumjev',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Lyumjev_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Lyumjev',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Lyumjev® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Lyumjev_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Lyumjev_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Lyumjev® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Lyumjev_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Lyumjev_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Lyumjev_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Lyumjev® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Lyumjev_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lyumjev® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Lyumjev_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lyumjev® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Lyumjev_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lyumjev® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Lyumjev_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Lyumjev® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Lyumjev_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Lyumjev_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Lyumjev',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Lyumjev® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Lyumjev_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Lyumjev® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Lyumjev_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Lyumjev_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Lyumjev® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lyumjev® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lyumjev® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lyumjev® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Lyumjev® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Lyumjev_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Lyumjev',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Lyumjev® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Lyumjev_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Lyumjev® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Lyumjev_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Lyumjev_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Lyumjev® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lyumjev® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lyumjev® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lyumjev® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Lyumjev® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Lyumjev_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Lyumjev_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Lispro (Insulin Lispro Sanofi®)',
                        displayName: 'Current - Insulin Lispro (Insulin Lispro Sanofi®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Insulin_Lispro_Sanofi® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Insulin_Lispro_Sanofi® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Insulin_Lispro_Sanofi® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Lispro_Sanofi® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Lispro_Sanofi® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Lispro_Sanofi® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Insulin_Lispro_Sanofi® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Insulin_Lispro_Sanofi® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Insulin_Lispro_Sanofi® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Insulin_Lispro_Sanofi® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Lispro_Sanofi® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Lispro_Sanofi® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Lispro_Sanofi® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Insulin_Lispro_Sanofi® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Insulin_Lispro_Sanofi® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Insulin_Lispro_Sanofi® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Insulin_Lispro_Sanofi® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Lispro_Sanofi® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Lispro_Sanofi® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Lispro_Sanofi® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Insulin_Lispro_Sanofi® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Human (Humilin®)',
                        displayName: 'Current - Insulin Human (Humilin®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Injection_Humilin',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Humilin_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Humilin',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Humilin® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Humilin_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Humilin_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Humilin® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Humilin_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Humilin_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Humilin_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Humilin_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Humilin® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Humilin_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Humilin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humilin® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Humilin_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Humilin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humilin® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Humilin_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Humilin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humilin® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Humilin_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Humilin_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Humilin® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Humilin_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Humilin_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Humilin_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Humilin',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Humilin® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Humilin_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Humilin_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Humilin® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Humilin_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Humilin_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Humilin_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Humilin_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Humilin® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Humilin_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Humilin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humilin® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Humilin_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Humilin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humilin® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Humilin_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Humilin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humilin® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Humilin_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Humilin_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Humilin® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Humilin_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Humilin_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Humilin_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Humilin',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Humilin® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Humilin_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Humilin_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Humilin® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Humilin_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Humilin_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Humilin_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Humilin_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Humilin® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Humilin_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Humilin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humilin® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Humilin_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Humilin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humilin® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Humilin_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Humilin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humilin® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Humilin_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Humilin_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Humilin® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Humilin_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Humilin_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Human (Actraphane®)',
                        displayName: 'Current - Insulin Human (Actraphane®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Injection_Actraphane',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Actraphane_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Actraphane',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Actraphane® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Actraphane_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Actraphane_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Actraphane® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Actraphane_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Actraphane_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Actraphane_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Actraphane_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Actraphane® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Actraphane_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Actraphane_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actraphane® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Actraphane_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Actraphane_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actraphane® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Actraphane_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Actraphane_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actraphane® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Actraphane_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Actraphane_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Actraphane® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Actraphane_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Actraphane_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Actraphane_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Actraphane',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Actraphane® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Actraphane_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Actraphane_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Actraphane® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Actraphane_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Actraphane_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Actraphane_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Actraphane_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Actraphane® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Actraphane_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Actraphane_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actraphane® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Actraphane_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Actraphane_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actraphane® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Actraphane_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Actraphane_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actraphane® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Actraphane_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Actraphane_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Actraphane® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Actraphane_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Actraphane_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Actraphane_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Actraphane',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Actraphane® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Actraphane_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Actraphane_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Actraphane® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Actraphane_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Actraphane_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Actraphane_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Actraphane_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Actraphane® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Actraphane_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Actraphane_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actraphane® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Actraphane_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Actraphane_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actraphane® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Actraphane_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Actraphane_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actraphane® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Actraphane_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Actraphane_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Actraphane® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Actraphane_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Actraphane_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulan Human (Insulatard®)',
                        displayName: 'Current - Insulan Human (Insulatard®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Injection_Insulatard',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Insulatard_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Insulatard',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Insulatard® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulatard_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulatard_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Insulatard® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulatard_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulatard_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulatard_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulatard_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Insulatard® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulatard_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulatard_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulatard® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulatard_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulatard_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulatard® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulatard_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulatard_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulatard® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulatard_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulatard_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Insulatard® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulatard_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulatard_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Insulatard_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Insulatard',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Insulatard® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulatard_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulatard_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Insulatard® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulatard_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulatard_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulatard_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulatard_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Insulatard® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulatard_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulatard_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulatard® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulatard_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulatard_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulatard® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulatard_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulatard_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulatard® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulatard_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulatard_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Insulatard® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulatard_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulatard_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Insulatard_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Insulatard',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Insulatard® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulatard_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulatard_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Insulatard® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulatard_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulatard_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulatard_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulatard_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Insulatard® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulatard_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulatard_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulatard® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulatard_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulatard_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulatard® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulatard_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulatard_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulatard® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulatard_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Insulatard_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Insulatard® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulatard_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Insulatard_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Aspart/Degludeg (Ryzodeg®)',
                        displayName: 'Current - Insulin Aspart/Degludeg (Ryzodeg®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Injection_Ryzodeg',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Ryzodeg',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Ryzodeg® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ryzodeg® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ryzodeg® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ryzodeg® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ryzodeg® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ryzodeg® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ryzodeg® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Ryzodeg',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Ryzodeg® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ryzodeg® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ryzodeg® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ryzodeg® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ryzodeg® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ryzodeg® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ryzodeg® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Ryzodeg',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Ryzodeg® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ryzodeg® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ryzodeg® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ryzodeg® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ryzodeg® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ryzodeg® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ryzodeg® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Ryzodeg_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Glargine (Lantus®)',
                        displayName: 'Current - Insulin Glargine (Lantus®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Injection_Lantus',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Lantus_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Lantus',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Lantus® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Lantus_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Lantus_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Lantus® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Lantus_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Lantus_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Lantus_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Lantus_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Lantus® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Lantus_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Lantus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lantus® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Lantus_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Lantus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lantus® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Lantus_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Lantus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lantus® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Lantus_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Lantus_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Lantus® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Lantus_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Lantus_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Lantus_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Lantus',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Lantus® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Lantus_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Lantus_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Lantus® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Lantus_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Lantus_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Lantus_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Lantus_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Lantus® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Lantus_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Lantus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lantus® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Lantus_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Lantus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lantus® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Lantus_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Lantus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lantus® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Lantus_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Lantus_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Lantus® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Lantus_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Lantus_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Lantus_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Lantus',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Lantus® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Lantus_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Lantus_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Lantus® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Lantus_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Lantus_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Lantus_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Lantus_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Lantus® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Lantus_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Lantus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lantus® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Lantus_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Lantus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lantus® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Lantus_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Lantus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lantus® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Lantus_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Lantus_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Lantus® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Lantus_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Lantus_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Glargine (Toujeo®)',
                        displayName: 'Current - Insulin Glargine (Toujeo®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Injection_Toujeo',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Toujeo_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Toujeo',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Toujeo® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Toujeo_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Toujeo_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Toujeo® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Toujeo_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Toujeo_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Toujeo_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Toujeo_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Toujeo® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Toujeo_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Toujeo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Toujeo® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Toujeo_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Toujeo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Toujeo® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Toujeo_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Toujeo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Toujeo® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Toujeo_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Toujeo_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Toujeo® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Toujeo_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Toujeo_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Toujeo_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Toujeo',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Toujeo® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Toujeo_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Toujeo_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Toujeo® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Toujeo_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Toujeo_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Toujeo_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Toujeo_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Toujeo® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Toujeo_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Toujeo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Toujeo® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Toujeo_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Toujeo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Toujeo® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Toujeo_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Toujeo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Toujeo® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Toujeo_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Toujeo_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Toujeo® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Toujeo_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Toujeo_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Toujeo_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Toujeo',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Toujeo® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Toujeo_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Toujeo_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Toujeo® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Toujeo_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Toujeo_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Toujeo_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Toujeo_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Toujeo® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Toujeo_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Toujeo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Toujeo® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Toujeo_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Toujeo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Toujeo® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Toujeo_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Toujeo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Toujeo® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Toujeo_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Toujeo_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Toujeo® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Toujeo_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Toujeo_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Glargine (Abasaglar®)',
                        displayName: 'Current - Insulin Glargine (Abasaglar®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Injection_Abasaglar',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Abasaglar_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Abasaglar',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Abasaglar® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Abasaglar_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Abasaglar_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Abasaglar® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Abasaglar_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Abasaglar_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Abasaglar_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Abasaglar® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Abasaglar_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Abasaglar® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Abasaglar_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Abasaglar® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Abasaglar_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Abasaglar® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Abasaglar_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Abasaglar® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Abasaglar_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Abasaglar_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Abasaglar',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Abasaglar® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Abasaglar_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Abasaglar® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Abasaglar_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Abasaglar_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Abasaglar® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Abasaglar® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Abasaglar® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Abasaglar® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Abasaglar® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Abasaglar_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Abasaglar',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Abasaglar® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Abasaglar_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Abasaglar® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Abasaglar_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Abasaglar_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Abasaglar® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Abasaglar® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Abasaglar® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Abasaglar® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Abasaglar® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Abasaglar_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Abasaglar_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Detemir (Levemir®)',
                        displayName: 'Current - Insulin Detemir (Levemir®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Injection_Levemir',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Levemir_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Levemir',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Levemir® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Levemir_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Levemir_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Levemir® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Levemir_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Levemir_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Levemir_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Levemir_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Levemir® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Levemir_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Levemir_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Levemir® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Levemir_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Levemir_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Levemir® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Levemir_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Levemir_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Levemir® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Levemir_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Levemir_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Levemir® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Levemir_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Levemir_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Levemir_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Levemir',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Levemir® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Levemir_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Levemir_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Levemir® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Levemir_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Levemir_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Levemir_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Levemir_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Levemir® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Levemir_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Levemir_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Levemir® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Levemir_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Levemir_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Levemir® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Levemir_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Levemir_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Levemir® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Levemir_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Levemir_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Levemir® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Levemir_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Levemir_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Levemir_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Levemir',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Levemir® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Levemir_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Levemir_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Levemir® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Levemir_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Levemir_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Levemir_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Levemir_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Levemir® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Levemir_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Levemir_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Levemir® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Levemir_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Levemir_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Levemir® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Levemir_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Levemir_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Levemir® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Levemir_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Levemir_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Levemir® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Levemir_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Levemir_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Degludec (Tresiba®)',
                        displayName: 'Current - Insulin Degludec (Tresiba®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Injection_Tresiba',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Tresiba_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Tresiba',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Tresiba® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Tresiba_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Tresiba_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tresiba® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Tresiba_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Tresiba_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Tresiba_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Tresiba_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tresiba® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Tresiba_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Tresiba_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tresiba® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Tresiba_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Tresiba_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tresiba® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Tresiba_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Tresiba_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tresiba® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Tresiba_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Tresiba_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tresiba® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Tresiba_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Tresiba_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Tresiba_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Tresiba',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Tresiba® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Tresiba_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Tresiba_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tresiba® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Tresiba_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Tresiba_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Tresiba_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Tresiba_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tresiba® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Tresiba_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Tresiba_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tresiba® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Tresiba_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Tresiba_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tresiba® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Tresiba_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Tresiba_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tresiba® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Tresiba_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Tresiba_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tresiba® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Tresiba_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Tresiba_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Tresiba_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Tresiba',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Tresiba® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Tresiba_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Tresiba_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tresiba® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Tresiba_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Tresiba_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Tresiba_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Tresiba_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tresiba® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Tresiba_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Tresiba_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tresiba® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Tresiba_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Tresiba_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tresiba® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Tresiba_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Tresiba_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tresiba® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Tresiba_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Tresiba_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tresiba® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Tresiba_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Tresiba_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },

                      {
                        label: 'Corticotropin (Purified Cortrophin Gel®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Injection_Corticotropin',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Corticotropin_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Corticotropin',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Corticotropin® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Corticotropin_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Corticotropin_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Corticotropin® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Corticotropin_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Corticotropin_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Corticotropin_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Corticotropin® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Corticotropin_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Corticotropin® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Corticotropin_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Corticotropin® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Corticotropin_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Corticotropin® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Corticotropin_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Corticotropin® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Corticotropin_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Corticotropin_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Corticotropin',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Corticotropin® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Corticotropin_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Corticotropin® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Corticotropin_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Corticotropin_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Corticotropin® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Corticotropin® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Corticotropin® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Corticotropin® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Corticotropin® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Corticotropin_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Corticotropin',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Corticotropin® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Corticotropin_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Corticotropin® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Corticotropin_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Corticotropin_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Corticotropin® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Corticotropin® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Corticotropin® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Corticotropin® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Corticotropin® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Corticotropin_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Corticotropin_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Glatiramer Acetate (Copaxone®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Injection_Copaxone',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Copaxone_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Copaxone',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Copaxone® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Copaxone_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Copaxone_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Copaxone® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Copaxone_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Copaxone_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Copaxone_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Copaxone_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Copaxone® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Copaxone_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Copaxone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Copaxone® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Copaxone_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Copaxone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Copaxone® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Copaxone_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Copaxone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Copaxone® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Copaxone_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Copaxone_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Copaxone® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Copaxone_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Copaxone_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Copaxone_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Copaxone',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Copaxone® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Copaxone_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Copaxone_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Copaxone® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Copaxone_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Copaxone_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Copaxone_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Copaxone_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Copaxone® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Copaxone_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Copaxone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Copaxone® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Copaxone_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Copaxone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Copaxone® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Copaxone_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Copaxone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Copaxone® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Copaxone_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Copaxone_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Copaxone® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Copaxone_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Copaxone_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Copaxone_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Copaxone',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Copaxone® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Copaxone_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Copaxone_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Copaxone® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Copaxone_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Copaxone_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Copaxone_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Copaxone_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Copaxone® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Copaxone_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Copaxone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Copaxone® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Copaxone_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Copaxone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Copaxone® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Copaxone_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Copaxone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Copaxone® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Copaxone_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Copaxone_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Copaxone® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Copaxone_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Copaxone_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Interferon β-1a (Avonex®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Injection_Avonex',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Avonex_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Avonex',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Avonex® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Avonex_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Avonex_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Avonex® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Avonex_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Avonex_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Avonex_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Avonex_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Avonex® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Avonex_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Avonex_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avonex® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Avonex_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Avonex_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avonex® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Avonex_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Avonex_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avonex® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Avonex_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Avonex_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Avonex® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Avonex_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Avonex_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Avonex_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Avonex',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Avonex® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Avonex_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Avonex_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Avonex® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Avonex_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Avonex_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Avonex_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Avonex_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Avonex® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Avonex_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Avonex_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avonex® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Avonex_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Avonex_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avonex® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Avonex_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Avonex_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avonex® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Avonex_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Avonex_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Avonex® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Avonex_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Avonex_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Avonex_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Avonex',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Avonex® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Avonex_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Avonex_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Avonex® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Avonex_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Avonex_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Avonex_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Avonex_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Avonex® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Avonex_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Avonex_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avonex® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Avonex_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Avonex_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avonex® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Avonex_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Avonex_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avonex® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Avonex_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Avonex_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Avonex® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Avonex_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Avonex_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Interferon β-1a (Rebif®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Injection_Rebif',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Rebif_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Rebif',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Rebif® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Rebif_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Rebif_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Rebif® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Rebif_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Rebif_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Rebif_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Rebif_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Rebif® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Rebif_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Rebif_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rebif® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Rebif_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Rebif_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rebif® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Rebif_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Rebif_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rebif® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Rebif_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Rebif_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Rebif® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Rebif_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Rebif_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Rebif_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Rebif',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Rebif® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Rebif_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Rebif_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Rebif® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Rebif_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Rebif_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Rebif_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Rebif_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Rebif® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Rebif_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Rebif_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rebif® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Rebif_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Rebif_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rebif® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Rebif_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Rebif_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rebif® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Rebif_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Rebif_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Rebif® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Rebif_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Rebif_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Rebif_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Rebif',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Rebif® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Rebif_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Rebif_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Rebif® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Rebif_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Rebif_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Rebif_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Rebif_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Rebif® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Rebif_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Rebif_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rebif® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Rebif_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Rebif_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rebif® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Rebif_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Rebif_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rebif® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Rebif_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Rebif_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Rebif® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Rebif_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Rebif_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Interferon β-1b (Betaseron®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Injection_Betaseron',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Betaseron_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Betaseron',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Betaseron (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Betaseron_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Betaseron_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Betaseron® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Betaseron_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Betaseron_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Betaseron_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Betaseron_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Betaseron® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Betaseron_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Betaseron_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Betaseron® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Betaseron_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Betaseron_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Betaseron® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Betaseron_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Betaseron_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Betaseron® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Betaseron_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Betaseron_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Betaseron® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Betaseron_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Betaseron_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Betaseron_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Betaseron',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Betaseron® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Betaseron_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Betaseron_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Betaseron® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Betaseron_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Betaseron_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Betaseron_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Betaseron_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Betaseron® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Betaseron_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Betaseron_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Betaseron® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Betaseron_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Betaseron_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Betaseron® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Betaseron_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Betaseron_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Betaseron® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Betaseron_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Betaseron_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Betaseron® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Betaseron_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Betaseron_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Betaseron_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Betaseron',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Betaseron® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Betaseron_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Betaseron_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Betaseron® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Betaseron_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Betaseron_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Betaseron_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Betaseron_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Betaseron® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Betaseron_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Betaseron_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Betaseron® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Betaseron_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Betaseron_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Betaseron® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Betaseron_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Betaseron_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Betaseron® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Betaseron_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Betaseron_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Betaseron® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Betaseron_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Betaseron_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Interferon β-1b (Extavia®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Injection_Extavia',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Extavia_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Extavia',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Extavia® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Extavia_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Extavia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Extavia® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Extavia_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Extavia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Extavia_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Extavia_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Extavia® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Extavia_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Extavia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Extavia® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Extavia_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Extavia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Extavia® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Extavia_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Extavia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Extavia® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Extavia_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Extavia_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Extavia® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Extavia_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Extavia_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Extavia_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Extavia',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Extavia® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Extavia_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Extavia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Extavia® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Extavia_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Extavia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Extavia_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Extavia_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Extavia® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Extavia_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Extavia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Extavia® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Extavia_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Extavia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Extavia® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Extavia_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Extavia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Extavia® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Extavia_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Extavia_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Extavia® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Extavia_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Extavia_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Extavia_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Extavia',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Extavia® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Extavia_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Extavia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Extavia® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Extavia_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Extavia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Extavia_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Extavia_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Extavia® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Extavia_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Extavia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Extavia® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Extavia_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Extavia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Extavia® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Extavia_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Extavia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Extavia® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Extavia_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Extavia_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Extavia® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Extavia_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Extavia_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Ofatumumab (Kesimpta®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Injection_Kesimpta',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Kesimpta_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Kesimpta',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Kesimpta® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Kesimpta_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Kesimpta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Kesimpta® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Kesimpta_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Kesimpta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Kesimpta_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Kesimpta® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Kesimpta_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Kesimpta® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Kesimpta_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Kesimpta® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Kesimpta_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Kesimpta® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Kesimpta_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Kesimpta® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Kesimpta_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Kesimpta_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Kesimpta',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Kesimpta® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Kesimpta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Kesimpta® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Kesimpta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Kesimpta_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Kesimpta® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Kesimpta® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Kesimpta® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Kesimpta® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Kesimpta® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Kesimpta_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Kesimpta',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Kesimpta® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Kesimpta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Kesimpta® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Kesimpta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Kesimpta_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Kesimpta® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Kesimpta® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Kesimpta® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Kesimpta® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Kesimpta® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Kesimpta_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Kesimpta_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Peginterferon β‑1a (Plegridy®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Injection_Plegridy',
                        parent: 'Therapies_Current_Pharmaceutical_Injection',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Plegridy_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Plegridy',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Plegridy® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Plegridy_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Plegridy_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Plegridy® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Plegridy_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Plegridy_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Plegridy_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Plegridy_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Plegridy® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Plegridy_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Plegridy_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Plegridy® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Plegridy_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Plegridy_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Plegridy® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Plegridy_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Plegridy_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Plegridy® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Plegridy_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Plegridy_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Plegridy® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Plegridy_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Plegridy_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Plegridy_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Plegridy',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Plegridy® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Plegridy_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Plegridy_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Plegridy® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Plegridy_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Plegridy_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Plegridy_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Plegridy_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Plegridy® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Plegridy_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Plegridy_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Plegridy® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Plegridy_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Plegridy_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Plegridy® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Plegridy_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Plegridy_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Plegridy® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Plegridy_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Plegridy_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Plegridy® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Plegridy_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Plegridy_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Injection_Plegridy_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Injection_Plegridy',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Plegridy® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Plegridy_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Plegridy_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Plegridy® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Plegridy_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Plegridy_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Plegridy_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Plegridy_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Plegridy® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Plegridy_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Plegridy_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Plegridy® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Plegridy_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Plegridy_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Plegridy® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Plegridy_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Plegridy_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Plegridy® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Injection_Plegridy_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Injection_Plegridy_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Plegridy® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Injection_Plegridy_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Injection_Plegridy_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    label: 'Infusion',
                    multiSelect: false,
                    value: 'Therapies_Current_Pharmaceutical_Infusion',
                    parent: 'Therapies_Current_Pharmaceutical',
                    children: [
                      {
                        label: 'No other',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Infusion_No other',
                        parent: 'Therapies_Current_Pharmaceutical_Infusion',
                        displayName: 'No other infusion',
                        children: [],
                      },

                      {
                        label: 'Efgartigimod Alpha (Vyvgart®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Infusion_Vyvgart',
                        parent: 'Therapies_Current_Pharmaceutical_Infusion',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Vyvgart',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Vyvgart® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Vyvgart® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Vyvgart® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vyvgart® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vyvgart® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vyvgart® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Vyvgart® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Vyvgart',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Vyvgart® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Vyvgart® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Vyvgart® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vyvgart® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vyvgart® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vyvgart® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Vyvgart® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Vyvgart',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Vyvgart® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Vyvgart® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Vyvgart® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vyvgart® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vyvgart® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vyvgart® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Vyvgart® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Vyvgart_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Eculuzimab (Soliris®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Infusion_Soliris',
                        parent: 'Therapies_Current_Pharmaceutical_Infusion',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Soliris_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Soliris',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Soliris® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Soliris_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Soliris_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Soliris® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Soliris_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Soliris_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Soliris_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Soliris_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Soliris® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Soliris_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Soliris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Soliris® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Soliris_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Soliris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Soliris® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Soliris_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Soliris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Soliris® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Soliris_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Soliris_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Soliris® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Soliris_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Soliris_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Soliris_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Soliris',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Soliris® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Soliris_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Soliris_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Soliris® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Soliris_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Soliris_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Soliris_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Soliris_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Soliris® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Soliris_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Soliris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Soliris® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Soliris_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Soliris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Soliris® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Soliris_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Soliris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Soliris® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Soliris_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Soliris_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Soliris® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Soliris_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Soliris_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Soliris_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Soliris',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Soliris® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Soliris_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Soliris_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Soliris® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Soliris_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Soliris_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Soliris_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Soliris_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Soliris® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Soliris_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Soliris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Soliris® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Soliris_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Soliris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Soliris® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Soliris_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Soliris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Soliris® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Soliris_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Soliris_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Soliris® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Soliris_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Soliris_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Ravulizumab (Ultomiris®)',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Infusion_Ultomiris',
                        parent: 'Therapies_Current_Pharmaceutical_Infusion',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Ultomiris',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Ultomiris® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ultomiris® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ultomiris® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ultomiris® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ultomiris® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ultomiris® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ultomiris® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Ultomiris',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Ultomiris® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ultomiris® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ultomiris® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ultomiris® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ultomiris® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ultomiris® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ultomiris® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Ultomiris',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Ultomiris® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ultomiris® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ultomiris® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ultomiris® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ultomiris® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ultomiris® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ultomiris® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ultomiris_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Nipocalimab®',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab',
                        parent: 'Therapies_Current_Pharmaceutical_Infusion',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Nipocalimab® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Nipocalimab® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Nipocalimab® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nipocalimab® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nipocalimab® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nipocalimab® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Nipocalimab® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Nipocalimab® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Nipocalimab® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Nipocalimab® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nipocalimab® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nipocalimab® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nipocalimab® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Nipocalimab® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Nipocalimab® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Nipocalimab® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Nipocalimab® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nipocalimab® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nipocalimab® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nipocalimab® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Nipocalimab® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },

                      {
                        label: 'Alemtuzumab (Lemtrada®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Infusion_Lemtrada',
                        parent: 'Therapies_Current_Pharmaceutical_Infusion',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Lemtrada',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Lemtrada® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Lemtrada® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Lemtrada® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lemtrada® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lemtrada® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lemtrada® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Lemtrada® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Lemtrada',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Lemtrada® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Lemtrada® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Lemtrada® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lemtrada® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lemtrada® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lemtrada® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Lemtrada® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Lemtrada',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Lemtrada® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Lemtrada® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Lemtrada® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lemtrada® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lemtrada® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lemtrada® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Lemtrada® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Lemtrada_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Mitoxantrone®',
                        multiSelect: false,
                        value:
                          'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone',
                        parent: 'Therapies_Current_Pharmaceutical_Infusion',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Mitoxantrone® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Mitoxantrone® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Mitoxantrone® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mitoxantrone® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mitoxantrone® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mitoxantrone® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Mitoxantrone® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Mitoxantrone® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Mitoxantrone® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Mitoxantrone® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mitoxantrone® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mitoxantrone® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mitoxantrone® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Mitoxantrone® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Mitoxantrone® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Mitoxantrone® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Mitoxantrone® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mitoxantrone® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mitoxantrone® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mitoxantrone® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Mitoxantrone® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Natalizumab (Tysabri®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Infusion_Tysabri',
                        parent: 'Therapies_Current_Pharmaceutical_Infusion',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Tysabri_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Tysabri',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Tysabri® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Tysabri_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Tysabri_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tysabri® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Tysabri_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Tysabri_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Tysabri_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tysabri® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Tysabri_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tysabri® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Tysabri_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tysabri® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Tysabri_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tysabri® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Tysabri_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tysabri® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Tysabri_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Tysabri_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Tysabri',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Tysabri® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Tysabri_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tysabri® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Tysabri_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Tysabri_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tysabri® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tysabri® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tysabri® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tysabri® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tysabri® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Tysabri_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Tysabri',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Tysabri® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Tysabri_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tysabri® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Tysabri_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Tysabri_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tysabri® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tysabri® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tysabri® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tysabri® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tysabri® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Tysabri_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Tysabri_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Ocrelizumab (Ocrevus®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Infusion_Ocrevus',
                        parent: 'Therapies_Current_Pharmaceutical_Infusion',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Ocrevus',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Ocrevus® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ocrevus® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ocrevus® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ocrevus® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ocrevus® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ocrevus® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ocrevus® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Ocrevus',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Ocrevus® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ocrevus® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ocrevus® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ocrevus® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ocrevus® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ocrevus® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ocrevus® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Ocrevus',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Ocrevus® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ocrevus® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ocrevus® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ocrevus® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ocrevus® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ocrevus® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ocrevus® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Ocrevus_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Ublituximab-xiiy (Briumvi®)',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Infusion_Briumvi',
                        parent: 'Therapies_Current_Pharmaceutical_Infusion',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Briumvi_First therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Briumvi',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Briumvi® (Current - 1st therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Briumvi_First_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Briumvi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Briumvi® (Current - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Briumvi_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Briumvi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Briumvi_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Briumvi® (Current - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Briumvi_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Briumvi® (Current - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Briumvi_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Briumvi® (Current - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Briumvi_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Briumvi® (Current - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Briumvi_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Briumvi® (Current - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Briumvi_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Briumvi_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Second therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Briumvi',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Briumvi® (Current - 2nd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Second_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Briumvi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Briumvi® (Current - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Briumvi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Briumvi_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Briumvi® (Current - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Briumvi® (Current - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Briumvi® (Current - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Briumvi® (Current - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Briumvi® (Current - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Briumvi_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Third therapy',
                            parent:
                              'Therapies_Current_Pharmaceutical_Infusion_Briumvi',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Briumvi® (Current - 3rd therapy) Main',
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Third_Therapy_Main',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Briumvi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Briumvi® (Current - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Briumvi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Additionally',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Briumvi_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Briumvi® (Current - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Briumvi® (Current - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Briumvi® (Current - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Briumvi® (Current - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Briumvi® (Current - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Current_Pharmaceutical_Infusion_Briumvi_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Current_Pharmaceutical_Infusion_Briumvi_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                label: 'Surgical',
                multiSelect: false,
                value: 'Therapies_Current_Surgical',
                parent: 'Therapies_Current',
                children: [
                  {
                    label: 'Scalpel Incisions',
                    displayName: 'Current - Scalpel Incisions',
                    multiSelect: false,
                    value: 'Therapies_Current_Surgical_Scalpel',
                    parent: 'Therapies_Current_Surgical',
                    children: [],
                  },
                  {
                    displayName: 'Current - Laparoscopic',
                    label: 'Laparoscopic',
                    multiSelect: false,
                    value: 'Therapies_Current_Surgical_Laparoscopic',
                    parent: 'Therapies_Current_Surgical',
                    children: [],
                  },
                  {
                    displayName: 'Current - Suture Closure',
                    label: 'Suture Closure',
                    multiSelect: false,
                    value: 'Therapies_Current_Surgical_Suture',
                    parent: 'Therapies_Current_Surgical',
                    children: [],
                  },
                ],
              },
              {
                label: 'Rehabilitation',
                multiSelect: false,
                value: 'Therapies_Current_Rehabilitation',
                parent: 'Therapies_Current',
                children: [
                  {
                    displayName: 'Current - Physical Therapy',
                    label: 'Physical Therapy',
                    multiSelect: false,
                    value: 'Therapies_Current_Rehabilitation_Physical',
                    parent: 'Therapies_Current_Rehabilitation',
                    children: [],
                  },
                  {
                    displayName: 'Current - Occupational Therapy',
                    label: 'Occupational Therapy',
                    multiSelect: false,
                    value: 'Therapies_Current_Rehabilitation_Occupational',
                    parent: 'Therapies_Current_Rehabilitation',
                    children: [],
                  },
                  {
                    displayName: 'Current - Speech and Language',
                    label: 'Speech and Language',
                    multiSelect: false,
                    value: 'Therapies_Current_Rehabilitation_Speech',
                    parent: 'Therapies_Current_Rehabilitation',
                    children: [],
                  },
                ],
              },
              {
                label: 'Psychological',
                multiSelect: false,
                value: 'Therapies_Current_Psychological',
                parent: 'Therapies_Current',
                children: [
                  {
                    displayName: 'Current - Cognitive Behavioral Therapy (CBT)',
                    label: 'Cognitive Behavioral Therapy (CBT)',
                    multiSelect: false,
                    value: 'Therapies_Current_Psychological_ (CBT)',
                    parent: 'Therapies_Current_Psychological',
                    children: [],
                  },
                  {
                    displayName: 'Current - Humanistic Therapy',
                    label: 'Humanistic Therapy',
                    multiSelect: false,
                    value: 'Therapies_Current_Psychological_Humanistic',
                    parent: 'Therapies_Current_Psychological',
                    children: [],
                  },
                  {
                    displayName: 'Current - Psychodrama',
                    label: 'Psychodrama',
                    multiSelect: false,
                    value: 'Therapies_Current_Psychological_Psychodrama',
                    parent: 'Therapies_Current_Psychological',
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            label: 'Previous Therapies',
            multiSelect: false,
            value: 'Therapies_Previous',
            parent: 'Therapies',
            children: [
              {
                label: 'Pharmaceutical',
                multiSelect: false,
                value: 'Therapies_Previous_Pharmaceutical',
                parent: 'Therapies_Previous',
                children: [
                  {
                    label: 'Tablet',
                    multiSelect: false,
                    value: 'Therapies_Previous_Pharmaceutical_Tablet',
                    parent: 'Therapies_Previous_Pharmaceutical',
                    children: [
                      {
                        label: 'No other',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_No other',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        displayName: 'No other tablet',
                        children: [],
                      },

                      {
                        label: 'Pyridostigmine bromide (Mestinon®)',
                        displayName: 'Previous - Pyridostigmine bromide (Mestinon®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Mestinon',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Mestinon',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Mestinon® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Mestinon® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Mestinon® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mestinon® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mestinon® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mestinon® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Mestinon® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Mestinon',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Mestinon® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Mestinon® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Mestinon® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mestinon® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mestinon® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mestinon® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Mestinon® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Mestinon',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Mestinon® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Mestinon® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Mestinon® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mestinon® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mestinon® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mestinon® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Mestinon® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mestinon_Third_therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Pyridostigmine bromide (Timespan®)',
                        displayName: 'Previous - Pyridostigmine bromide (Timespan®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Timespan',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Timespan_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Timespan',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Timespan® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Timespan_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Timespan_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Timespan® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Timespan_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Timespan_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Timespan_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Timespan® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Timespan_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Timespan® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Timespan_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Timespan® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Timespan_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Timespan® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Timespan_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Timespan® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Timespan_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Timespan_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Timespan',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Timespan® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Timespan_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Timespan® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Timespan_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Timespan_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Timespan® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Timespan® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Timespan® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Timespan® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Timespan® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Timespan_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Timespan',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Timespan® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Timespan_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Timespan® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Timespan_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Timespan_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Timespan® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Timespan® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Timespan® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Timespan® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Timespan® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Timespan_Third_therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Prednisone (Generic)',
                        displayName: 'Previous - Prednisone (Generic)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Prednisone) (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Prednisone) (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Prednisone) (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Prednisone) (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Prednisone) (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Prednisone) (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Prednisone) (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Prednisone) (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Prednisone) (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Prednisone) (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Prednisone) (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Prednisone) (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Prednisone) (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Prednisone) (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Prednisone) (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Prednisone) (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Prednisone) (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Prednisone) (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Prednisone) (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Prednisone) (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Prednisone) (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)_Third_therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Azathioprine (Imuran®)',
                        displayName: 'Previous - Azathioprine (Imuran®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Imuran',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Imuran_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Imuran',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Imuran® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Imuran_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Imuran_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Imuran® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Imuran_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Imuran_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Imuran_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Imuran® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Imuran_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Imuran® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Imuran_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Imuran® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Imuran_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Imuran® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Imuran_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Imuran® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Imuran_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Imuran_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Imuran',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Imuran® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Imuran_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Imuran® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Imuran_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Imuran_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Imuran® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Imuran® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Imuran® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Imuran® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Imuran® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Imuran_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Imuran',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Imuran® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Imuran_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Imuran® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Imuran_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Imuran_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Imuran® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Imuran® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Imuran® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Imuran® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Imuran® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Imuran_Third_therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Azathioprine (Generic)',
                        displayName: 'Previous - Azathioprine (Generic)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Azathioprine) (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Azathioprine) (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Azathioprine) (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Azathioprine) (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Azathioprine) (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Azathioprine) (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Azathioprine) (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Azathioprine) (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Azathioprine) (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Azathioprine) (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Azathioprine) (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Azathioprine) (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Azathioprine) (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Azathioprine) (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Azathioprine) (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Azathioprine) (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Azathioprine) (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Azathioprine) (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Azathioprine) (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Azathioprine) (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Azathioprine) (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)_Third_therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Mycofenolaatmofetil (Cellcept®)',
                        displayName: 'Previous - Mycofenolaatmofetil (Cellcept®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Cellcept',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Cellcept',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Cellcept® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Cellcept® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Cellcept® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Cellcept® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Cellcept® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Cellcept® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Cellcept® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Cellcept',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Cellcept® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Cellcept® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Cellcept® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Cellcept® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Cellcept® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Cellcept® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Cellcept® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Cellcept',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Cellcept® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Cellcept® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Cellcept® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Cellcept® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Cellcept® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Cellcept® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Cellcept® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Cellcept_Third_therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Mycofenolaatmofetil (Generic)',
                        displayName: 'Previous - Mycofenolaatmofetil (Generic)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Mycofenolaatmofetil) (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Mycofenolaatmofetil) (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Mycofenolaatmofetil) (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Mycofenolaatmofetil) (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Mycofenolaatmofetil) (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Mycofenolaatmofetil) (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Mycofenolaatmofetil) (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Mycofenolaatmofetil) (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Mycofenolaatmofetil) (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Mycofenolaatmofetil) (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Mycofenolaatmofetil) (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Mycofenolaatmofetil) (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Mycofenolaatmofetil) (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Mycofenolaatmofetil) (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Mycofenolaatmofetil) (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Mycofenolaatmofetil) (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Mycofenolaatmofetil) (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Mycofenolaatmofetil) (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Mycofenolaatmofetil) (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Mycofenolaatmofetil) (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Mycofenolaatmofetil) (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)_Third_therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Cyclosporin (Generic)',
                        displayName: 'Previous - Cyclosporin (Generic)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Cyclosporin) (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Cyclosporin) (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Cyclosporin) (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Cyclosporin) (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Cyclosporin) (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Cyclosporin) (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Cyclosporin) (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Cyclosporin) (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Cyclosporin) (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Cyclosporin) (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Cyclosporin) (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Cyclosporin) (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Cyclosporin) (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Cyclosporin) (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Cyclosporin) (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Cyclosporin) (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Cyclosporin) (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Cyclosporin) (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Cyclosporin) (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Cyclosporin) (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Cyclosporin) (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)_Third_therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },

                      {
                        label: 'Semaglutide (Ozempic®)',
                        displayName: 'Previous - Semaglutide (Ozempic®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Ozempic',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Ozempic',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Ozempic® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ozempic® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ozempic® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ozempic® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ozempic® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ozempic® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ozempic® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Ozempic',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Ozempic® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ozempic® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ozempic® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ozempic® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ozempic® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ozempic® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ozempic® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Ozempic',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Ozempic® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ozempic® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ozempic® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ozempic® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ozempic® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ozempic® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ozempic® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ozempic_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Metformin (Generic)',
                        displayName: 'Previous - Metformin (Generic)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Metformin) (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Metformin) (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Metformin) (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Metformin) (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Metformin) (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Metformin) (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Metformin) (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Metformin) (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Metformin) (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Metformin) (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Metformin) (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Metformin) (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Metformin) (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Metformin) (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Metformin) (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Metformin) (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Metformin) (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Metformin) (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Metformin) (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Metformin) (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Metformin) (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Tolbutamide (Generic)',
                        displayName: 'Previous - Tolbutamide (Generic)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Tolbutamide) (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Tolbutamide) (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Tolbutamide) (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Tolbutamide) (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Tolbutamide) (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Tolbutamide) (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (Tolbutamide) (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (Tolbutamide) (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (Tolbutamide) (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (Tolbutamide) (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (Tolbutamide) (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (tolbutamide) (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (tolbutamide) (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (tolbutamide) (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (tolbutamide) (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (tolbutamide) (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (tolbutamide) (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (tolbutamide) (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (tolbutamide) (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (tolbutamide) (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (tolbutamide) (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Glimepiride (Amaryl®)',
                        displayName: 'Previous - Glimepiride (Amaryl®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Amaryl',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Amaryl',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Amaryl® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Amaryl® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Amaryl® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Amaryl® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Amaryl® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Amaryl® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Amaryl® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Amaryl',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Amaryl® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Amaryl® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Amaryl® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Amaryl® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Amaryl® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Amaryl® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Amaryl® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Amaryl',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Amaryl® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Amaryl® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Amaryl® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Amaryl® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Amaryl® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Amaryl® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Amaryl® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Amaryl_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Glimepiride (Generic)',
                        displayName: 'Previous - Glimepiride (Generic)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (glimepiride) (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (glimepiride) (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (glimepiride) (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glimepiride) (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glimepiride) (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glimepiride) (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (glimepiride) (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (glimepiride) (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (glimepiride) (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (glimepiride) (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glimepiride) (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glimepiride) (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glimepiride) (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (glimepiride) (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (glimepiride) (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (glimepiride) (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (glimepiride) (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glimepiride) (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glimepiride) (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glimepiride) (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (glimepiride) (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Glipizide (Glucotrol®)',
                        displayName: 'Previous - Glipizide (Glucotrol®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glucotrol® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glucotrol® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glucotrol® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glucotrol® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glucotrol® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glucotrol® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glucotrol® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glucotrol® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glucotrol® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glucotrol® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glucotrol® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glucotrol® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Glipizide (Glucotrol XL®)',
                        displayName: 'Previous - Glipizide (Glucotrol XL®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glucotrol XL® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glucotrol XL® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glucotrol XL® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol XL® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol XL® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol XL® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glucotrol XL® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glucotrol XL® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glucotrol XL® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glucotrol XL® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol XL® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol XL® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol XL® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glucotrol XL® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glucotrol XL® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glucotrol XL® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glucotrol XL® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol XL® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol XL® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucotrol XL® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glucotrol XL® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Glipizide (Generic)',
                        displayName: 'Previous - Glipizide (Generic)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (glipizide) (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (glipizide) (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (glipizide) (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glipizide) (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glipizide) (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glipizide) (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (glipizide) (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (glipizide) (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (glipizide) (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (glipizide) (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glipizide) (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glipizide) (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glipizide) (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (glipizide) (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (glipizide) (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (glipizide) (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (glipizide) (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glipizide) (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glipizide) (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glipizide) (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (glipizide) (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Glyburide (Micronase®)',
                        displayName: 'Previous - Glyburide (Micronase®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Micronase',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Micronase_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Micronase',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Micronase® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Micronase_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Micronase_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Micronase® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Micronase_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Micronase_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Micronase_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Micronase® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Micronase_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Micronase® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Micronase_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Micronase® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Micronase_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Micronase® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Micronase_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Micronase® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Micronase_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Micronase_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Micronase',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Micronase® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Micronase_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Micronase® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Micronase_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Micronase_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Micronase® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Micronase® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Micronase® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Micronase® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Micronase® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Micronase_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Micronase',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Micronase® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Micronase_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Micronase® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Micronase_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Micronase_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Micronase® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Micronase® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Micronase® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Micronase® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Micronase® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Micronase_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Micronase_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Glyburide (DiaBeta®)',
                        displayName: 'Previous - Glyburide (DiaBeta®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'DiaBeta® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'DiaBeta® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'DiaBeta® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'DiaBeta® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'DiaBeta® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'DiaBeta® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'DiaBeta® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'DiaBeta® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'DiaBeta® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'DiaBeta® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'DiaBeta® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'DiaBeta® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'DiaBeta® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'DiaBeta® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'DiaBeta® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'DiaBeta® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'DiaBeta® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'DiaBeta® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'DiaBeta® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'DiaBeta® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'DiaBeta® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Glyburide (Generic)',
                        displayName: 'Previous - Glyburide (Generic)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (glyburide) (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (glyburide) (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (glyburide) (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide) (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide) (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide) (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (glyburide) (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (glyburide) (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (glyburide) (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (glyburide) (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide) (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide) (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide) (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (glyburide) (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (glyburide) (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (glyburide) (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (glyburide) (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide) (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide) (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide) (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (glyburide) (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Glyburide, Micronized (Glynase PresTab®)',
                        displayName:
                          'Previous - Glyburide, Micronized (Glynase PresTab®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glynase PresTab® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glynase PresTab® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glynase PresTab® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glynase PresTab® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glynase PresTab® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glynase PresTab® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glynase PresTab® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glynase PresTab® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glynase PresTab® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glynase PresTab® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glynase PresTab® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glynase PresTab® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glynase PresTab® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glynase PresTab® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glynase PresTab® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glynase PresTab® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glynase PresTab® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glynase PresTab® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glynase PresTab® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glynase PresTab® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glynase PresTab® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Glyburide, Micronized (Generic)',
                        displayName: 'Previous - Glyburide, Micronized (Generic)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (glyburide, micronized) (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (glyburide, micronized) (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (glyburide, micronized) (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide, micronized) (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide, micronized) (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide, micronized) (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (glyburide, micronized) (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (glyburide, micronized) (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (glyburide, micronized) (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (glyburide, micronized) (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide, micronized) (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide, micronized) (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide, micronized) (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (glyburide, micronized) (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Generic (glyburide, micronized) (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Generic (glyburide, micronized) (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Generic (glyburide, micronized) (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide, micronized) (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide, micronized) (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Generic (glyburide, micronized) (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Generic (glyburide, micronized) (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Metformin (Glucophage®)',
                        displayName: 'Previous - Metformin (Glucophage®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Glucophage',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glucophage',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glucophage® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glucophage® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glucophage® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glucophage® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glucophage',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glucophage® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glucophage® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glucophage® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glucophage® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glucophage',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glucophage® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glucophage® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glucophage® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glucophage® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Metformin (Glucophage XR®)',
                        displayName: 'Previous - Metformin (Glucophage XR®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glucophage XR® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glucophage XR® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glucophage XR® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage XR® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage XR® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage XR® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glucophage XR® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glucophage XR® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glucophage XR® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glucophage XR® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage XR® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage XR® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage XR® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glucophage XR® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glucophage XR® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glucophage XR® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glucophage XR® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage XR® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage XR® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glucophage XR® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glucophage XR® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Metformin (Fortamet®)',
                        displayName: 'Previous - Metformin (Fortamet®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Fortamet',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Fortamet',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Fortamet® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Fortamet® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Fortamet® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fortamet® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fortamet® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fortamet® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Fortamet® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Fortamet',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Fortamet® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Fortamet® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Fortamet® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fortamet® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fortamet® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fortamet® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Fortamet® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Fortamet',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Fortamet® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Fortamet® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Fortamet® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fortamet® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fortamet® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fortamet® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Fortamet® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Fortamet_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Metformin (Glumetza®)',
                        displayName: 'Previous - Metformin (Glumetza®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Glumetza',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glumetza',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glumetza® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glumetza® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glumetza® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glumetza® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glumetza® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glumetza® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glumetza® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glumetza',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glumetza® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glumetza® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glumetza® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glumetza® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glumetza® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glumetza® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glumetza® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Glumetza',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Glumetza® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Glumetza® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Glumetza® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glumetza® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glumetza® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Glumetza® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Glumetza® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Glumetza_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Metformin (Riomet®)',
                        displayName: 'Previous - Metformin (Riomet®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Riomet',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Riomet_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Riomet',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Riomet® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Riomet_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Riomet_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Riomet® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Riomet_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Riomet_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Riomet_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Riomet® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Riomet_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Riomet® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Riomet_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Riomet® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Riomet_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Riomet® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Riomet_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Riomet® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Riomet_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Riomet_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Riomet',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Riomet® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Riomet_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Riomet® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Riomet_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Riomet_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Riomet® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Riomet® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Riomet® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Riomet® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Riomet® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Riomet_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Riomet',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Riomet® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Riomet_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Riomet® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Riomet_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Riomet_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Riomet® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Riomet® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Riomet® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Riomet® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Riomet® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Riomet_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Riomet_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Pioglitazone (Actos®)',
                        displayName: 'Previous - Pioglitazone (Actos®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Actos',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Actos_First therapy',
                            parent: 'Therapies_Previous_Pharmaceutical_Tablet_Actos',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Actos® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Actos_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Actos_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Actos® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Actos_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Actos_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Actos_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Actos_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Actos® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Actos_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Actos_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actos® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Actos_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Actos_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actos® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Actos_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Actos_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actos® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Actos_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Actos_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Actos® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Actos_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Actos_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Actos_Second therapy',
                            parent: 'Therapies_Previous_Pharmaceutical_Tablet_Actos',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Actos® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Actos_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Actos_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Actos® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Actos_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Actos_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Actos_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Actos_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Actos® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Actos_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Actos_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actos® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Actos_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Actos_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actos® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Actos_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Actos_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actos® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Actos_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Actos_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Actos® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Actos_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Actos_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Actos_Third therapy',
                            parent: 'Therapies_Previous_Pharmaceutical_Tablet_Actos',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Actos® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Actos_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Actos_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Actos® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Actos_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Actos_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Actos_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Actos_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Actos® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Actos_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Actos_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actos® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Actos_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Actos_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actos® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Actos_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Actos_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actos® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Actos_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Actos_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Actos® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Actos_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Actos_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Rosiglitazone (Avandia®)',
                        displayName: 'Previous - Rosiglitazone (Avandia®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Avandia',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Avandia_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Avandia',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Avandia® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Avandia_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Avandia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Avandia® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Avandia_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Avandia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Avandia_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Avandia® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Avandia_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avandia® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Avandia_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avandia® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Avandia_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avandia® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Avandia_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Avandia® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Avandia_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Avandia_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Avandia',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Avandia® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Avandia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Avandia® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Avandia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Avandia_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Avandia® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avandia® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avandia® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avandia® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Avandia® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Avandia_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Avandia',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Avandia® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Avandia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Avandia® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Avandia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Avandia_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Avandia® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avandia® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avandia® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avandia® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Avandia® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Avandia_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Avandia_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Alogliptin (Nesina®)',
                        displayName: 'Previous - Alogliptin (Nesina®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Nesina',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Nesina_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Nesina',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Nesina® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Nesina_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Nesina_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Nesina® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Nesina_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Nesina_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Nesina_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Nesina® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Nesina_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nesina® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Nesina_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nesina® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Nesina_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nesina® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Nesina_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Nesina® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Nesina_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Nesina_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Nesina',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Nesina® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Nesina_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Nesina® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Nesina_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Nesina_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Nesina® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nesina® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nesina® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nesina® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Nesina® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Nesina_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Nesina',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Nesina® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Nesina_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Nesina® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Nesina_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Nesina_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Nesina® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nesina® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nesina® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nesina® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Nesina® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Nesina_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Nesina_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Sitagliptin (Januvia®)',
                        displayName: 'Previous - Sitagliptin (Januvia®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Januvia',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Januvia_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Januvia',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Januvia® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Januvia_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Januvia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Januvia® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Januvia_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Januvia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Januvia_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Januvia® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Januvia_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Januvia® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Januvia_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Januvia® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Januvia_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Januvia® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Januvia_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Januvia® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Januvia_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Januvia_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Januvia',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Januvia® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Januvia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Januvia® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Januvia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Januvia_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Januvia® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Januvia® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Januvia® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Januvia® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Januvia® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Januvia_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Januvia',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Januvia® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Januvia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Januvia® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Januvia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Januvia_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Januvia® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Januvia® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Januvia® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Januvia® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Januvia® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Januvia_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Januvia_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Saxagliptin (Onglyza®)',
                        displayName: 'Previous - Saxagliptin (Onglyza®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Onglyza',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Onglyza',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Onglyza® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Onglyza® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Onglyza® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Onglyza® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Onglyza® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Onglyza® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Onglyza® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Onglyza',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Onglyza® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Onglyza® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Onglyza® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Onglyza® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Onglyza® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Onglyza® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Onglyza® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Onglyza',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Onglyza® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Onglyza® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Onglyza® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Onglyza® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Onglyza® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Onglyza® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Onglyza® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Onglyza_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Linagliptin (Tradjenta®)',
                        displayName: 'Previous - Linagliptin (Tradjenta®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Tradjenta® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tradjenta® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tradjenta® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tradjenta® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tradjenta® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tradjenta® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tradjenta® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Tradjenta® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tradjenta® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tradjenta® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tradjenta® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tradjenta® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tradjenta® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tradjenta® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Tradjenta® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tradjenta® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tradjenta® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tradjenta® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tradjenta® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tradjenta® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tradjenta® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta_Third therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Canagliflozin (Invokana®)',
                        displayName: 'Previous - Canagliflozin (Invokana®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Invokana',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Invokana_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Invokana',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Invokana® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Invokana_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Invokana_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Invokana® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Invokana_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Invokana_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Invokana_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Invokana® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Invokana_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Invokana® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Invokana_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Invokana® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Invokana_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Invokana® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Invokana_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Invokana® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Invokana_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Invokana_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Invokana',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Invokana® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Invokana_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Invokana® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Invokana_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Invokana_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Invokana® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Invokana® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Invokana® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Invokana® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Invokana® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Invokana_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Invokana',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Invokana® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Invokana_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Invokana® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Invokana_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Invokana_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Invokana® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Invokana® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Invokana® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Invokana® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Invokana® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Invokana_Third therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Dapagliflozin (Farxiga®)',
                        displayName: 'Previous - Dapagliflozin (Farxiga®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Farxiga',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Farxiga',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Farxiga® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Farxiga® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Farxiga® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Farxiga® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Farxiga® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Farxiga® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Farxiga® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Farxiga',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Farxiga® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Farxiga® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Farxiga® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Farxiga® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Farxiga® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Farxiga® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Farxiga® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Farxiga',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Farxiga® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Farxiga® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Farxiga® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Farxiga® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Farxiga® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Farxiga® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Farxiga® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Farxiga_Third therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Empagliflozin (Jardiance®)',
                        displayName: 'Previous - Empagliflozin (Jardiance®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Jardiance',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Jardiance',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Jardiance® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Jardiance® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Jardiance® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Jardiance® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Jardiance® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Jardiance® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Jardiance® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Jardiance',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Jardiance® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Jardiance® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Jardiance® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Jardiance® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Jardiance® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Jardiance® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Jardiance® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Jardiance',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Jardiance® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Jardiance® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Jardiance® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Jardiance® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Jardiance® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Jardiance® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Jardiance® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Jardiance_Third therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },

                      {
                        label: 'Cladribine (Mavenclad®) 10mg',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Mavenclad® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Mavenclad® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Mavenclad® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mavenclad® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mavenclad® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mavenclad® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Mavenclad® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Mavenclad® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Mavenclad® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Mavenclad® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mavenclad® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mavenclad® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mavenclad® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Mavenclad® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Mavenclad® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Mavenclad® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Mavenclad® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mavenclad® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mavenclad® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mavenclad® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Mavenclad® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Dimethyl Fumarate (Tecfidera®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Tecfidera® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tecfidera® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tecfidera® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tecfidera® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tecfidera® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tecfidera® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tecfidera® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Tecfidera® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tecfidera® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tecfidera® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tecfidera® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tecfidera® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tecfidera® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tecfidera® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Tecfidera® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tecfidera® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tecfidera® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tecfidera® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tecfidera® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tecfidera® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tecfidera® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Diroximel Fumarate (Vumerity®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Vumerity',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Vumerity',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Vumerity® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Vumerity® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Vumerity® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vumerity® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vumerity® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vumerity® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Vumerity® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Vumerity',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Vumerity® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Vumerity® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Vumerity® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vumerity® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vumerity® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vumerity® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Vumerity® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Vumerity',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Vumerity® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Vumerity® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Vumerity® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vumerity® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vumerity® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vumerity® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Vumerity® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Vumerity_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Fingolimod (Gilenya®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Gilenya',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Gilenya',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Gilenya® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Gilenya® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Gilenya® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Gilenya® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Gilenya® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Gilenya® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Gilenya® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Gilenya',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Gilenya® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Gilenya® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Gilenya® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Gilenya® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Gilenya® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Gilenya® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Gilenya® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Gilenya',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Gilenya® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Gilenya® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Gilenya® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Gilenya® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Gilenya® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Gilenya® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Gilenya® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Gilenya_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Fingolimod (Tascenso®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Tascenso',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Tascenso',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Tascenso® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tascenso® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tascenso® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tascenso® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tascenso® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tascenso® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tascenso® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Tascenso',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Tascenso® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tascenso® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tascenso® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tascenso® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tascenso® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tascenso® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tascenso® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Tascenso',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Tascenso® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tascenso® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tascenso® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tascenso® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tascenso® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tascenso® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tascenso® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Tascenso_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Monomethyl Fumarate (Bafiertam®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Bafiertam® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Bafiertam® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Bafiertam® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Bafiertam® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Bafiertam® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Bafiertam® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Bafiertam® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Bafiertam® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Bafiertam® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Bafiertam® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Bafiertam® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Bafiertam® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Bafiertam® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Bafiertam® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Bafiertam® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Bafiertam® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Bafiertam® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Bafiertam® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Bafiertam® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Bafiertam® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Bafiertam® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Ozanimod (Zeposia®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Zeposia',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Zeposia',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Zeposia® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Zeposia® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Zeposia® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zeposia® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zeposia® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zeposia® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Zeposia® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Zeposia',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Zeposia® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Zeposia® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Zeposia® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zeposia® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zeposia® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zeposia® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Zeposia® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Zeposia',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Zeposia® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Zeposia® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Zeposia® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zeposia® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zeposia® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zeposia® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Zeposia® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Zeposia_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Ponesimod (Ponvory®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Ponvory',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Ponvory',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Ponvory® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ponvory® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ponvory® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ponvory® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ponvory® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ponvory® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ponvory® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Ponvory',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Ponvory® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ponvory® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ponvory® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ponvory® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ponvory® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ponvory® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ponvory® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Ponvory',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Ponvory® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ponvory® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ponvory® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ponvory® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ponvory® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ponvory® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ponvory® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Ponvory_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Siponimod (Mayzent®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Mayzent',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Mayzent',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Mayzent® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Mayzent® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Mayzent® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mayzent® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mayzent® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mayzent® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Mayzent® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Mayzent',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Mayzent® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Mayzent® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Mayzent® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mayzent® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mayzent® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mayzent® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Mayzent® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Mayzent',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Mayzent® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Mayzent® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Mayzent® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mayzent® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mayzent® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mayzent® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Mayzent® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Mayzent_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Teriflunomide (Aubagio®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet_Aubagio',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Aubagio',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Aubagio® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Aubagio® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Aubagio® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Aubagio® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Aubagio® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Aubagio® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Aubagio® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Aubagio',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Aubagio® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Aubagio® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Aubagio® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Aubagio® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Aubagio® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Aubagio® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Aubagio® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Aubagio',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Aubagio® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Aubagio® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Aubagio® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Aubagio® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Aubagio® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Aubagio® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Aubagio® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Aubagio_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Unspecified',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Tablet_Unspecified',
                        parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Unspecified',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Unspecified (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Unspecified (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Unspecified (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Unspecified (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Unspecified (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Unspecified (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Unspecified (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Unspecified',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Unspecified (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Unspecified (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Unspecified (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Unspecified (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Unspecified (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Unspecified (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Unspecified (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Tablet_Unspecified',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Unspecified (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Unspecified (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Unspecified (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Unspecified (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Unspecified (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Unspecified (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Unspecified (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Tablet_Unspecified_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    label: 'Injection',
                    multiSelect: false,
                    value: 'Therapies_Previous_Pharmaceutical_Injection',
                    parent: 'Therapies_Previous_Pharmaceutical',
                    children: [
                      {
                        label: 'No other',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Injection_No other',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        displayName: 'No other injection',
                        children: [],
                      },

                      {
                        label: 'Rozanolixizumab-noli (Rystiggo®)',
                        displayName: 'Previous - Rozanolixizumab-noli (Rystiggo®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Injection_Rystiggo',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Rystiggo',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Rystiggo® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Rystiggo® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Rystiggo® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rystiggo® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rystiggo® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rystiggo® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Rystiggo® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Rystiggo',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Rystiggo® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Rystiggo® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Rystiggo® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rystiggo® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rystiggo® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rystiggo® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Rystiggo® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Rystiggo',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Rystiggo® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Rystiggo® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Rystiggo® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rystiggo® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rystiggo® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rystiggo® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Rystiggo® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rystiggo_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Zilucoplan (Zilbrysq®)',
                        displayName: 'Previous - Zilucoplan (Zilbrysq®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Zilbrysq® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Zilbrysq® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Zilbrysq® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zilbrysq® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zilbrysq® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zilbrysq® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Zilbrysq® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Zilbrysq® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Zilbrysq® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Zilbrysq® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zilbrysq® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zilbrysq® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zilbrysq® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Zilbrysq® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Zilbrysq® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Zilbrysq® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Zilbrysq® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zilbrysq® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zilbrysq® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Zilbrysq® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Zilbrysq® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },

                      {
                        label: 'Exenatide (Byetta®)',
                        displayName: 'Previous - Exenatide (Byetta®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Injection_Byetta',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Byetta_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Byetta',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Byetta® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Byetta_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Byetta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Byetta® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Byetta_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Byetta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Byetta_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Byetta_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Byetta® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Byetta_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Byetta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Byetta® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Byetta_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Byetta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Byetta® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Byetta_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Byetta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Byetta® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Byetta_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Byetta_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Byetta® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Byetta_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Byetta_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Byetta_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Byetta',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Byetta® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Byetta_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Byetta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Byetta® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Byetta_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Byetta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Byetta_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Byetta_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Byetta® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Byetta_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Byetta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Byetta® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Byetta_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Byetta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Byetta® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Byetta_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Byetta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Byetta® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Byetta_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Byetta_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Byetta® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Byetta_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Byetta_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Byetta_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Byetta',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Byetta® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Byetta_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Byetta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Byetta® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Byetta_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Byetta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Byetta_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Byetta_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Byetta® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Byetta_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Byetta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Byetta® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Byetta_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Byetta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Byetta® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Byetta_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Byetta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Byetta® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Byetta_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Byetta_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Byetta® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Byetta_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Byetta_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Liraglutide (Victoza®)',
                        displayName: 'Previous - Liraglutide (Victoza®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Injection_Victoza',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Victoza_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Victoza',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Victoza® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Victoza_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Victoza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Victoza® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Victoza_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Victoza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Victoza_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Victoza_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Victoza® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Victoza_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Victoza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Victoza® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Victoza_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Victoza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Victoza® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Victoza_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Victoza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Victoza® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Victoza_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Victoza_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Victoza® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Victoza_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Victoza_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Victoza_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Victoza',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Victoza® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Victoza_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Victoza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Victoza® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Victoza_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Victoza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Victoza_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Victoza_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Victoza® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Victoza_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Victoza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Victoza® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Victoza_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Victoza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Victoza® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Victoza_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Victoza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Victoza® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Victoza_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Victoza_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Victoza® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Victoza_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Victoza_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Victoza_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Victoza',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Victoza® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Victoza_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Victoza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Victoza® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Victoza_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Victoza_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Victoza_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Victoza_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Victoza® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Victoza_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Victoza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Victoza® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Victoza_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Victoza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Victoza® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Victoza_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Victoza_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Victoza® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Victoza_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Victoza_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Victoza® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Victoza_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Victoza_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Albiglutide (Tanzeum®)',
                        displayName: 'Previous - Albiglutide (Tanzeum®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Injection_Tanzeum',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Tanzeum',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Tanzeum® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tanzeum® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tanzeum® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tanzeum® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tanzeum® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tanzeum® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tanzeum® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Tanzeum',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Tanzeum® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tanzeum® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tanzeum® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tanzeum® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tanzeum® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tanzeum® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tanzeum® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Tanzeum',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Tanzeum® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tanzeum® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tanzeum® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tanzeum® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tanzeum® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tanzeum® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tanzeum® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tanzeum_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Dulaglutide (Trulicity®)',
                        displayName: 'Previous - Dulaglutide (Trulicity®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Injection_Trulicity',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Trulicity_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Trulicity',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Trulicity® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Trulicity_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Trulicity_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Trulicity® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Trulicity_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Trulicity_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Trulicity_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Trulicity® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Trulicity_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Trulicity® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Trulicity_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Trulicity® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Trulicity_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Trulicity® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Trulicity_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Trulicity® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Trulicity_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Trulicity_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Trulicity',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Trulicity® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Trulicity_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Trulicity® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Trulicity_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Trulicity_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Trulicity® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Trulicity® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Trulicity® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Trulicity® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Trulicity® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Trulicity_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Trulicity',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Trulicity® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Trulicity_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Trulicity® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Trulicity_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Trulicity_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Trulicity® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Trulicity® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Trulicity® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Trulicity® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Trulicity® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Trulicity_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Trulicity_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Glulisine (Apidra®)',
                        displayName: 'Previous - Insulin Glulisine (Apidra®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Injection_Apidra',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Apidra_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Apidra',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Apidra® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Apidra_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Apidra_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Apidra® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Apidra_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Apidra_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Apidra_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Apidra_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Apidra® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Apidra_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Apidra_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Apidra® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Apidra_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Apidra_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Apidra® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Apidra_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Apidra_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Apidra® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Apidra_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Apidra_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Apidra® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Apidra_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Apidra_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Apidra_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Apidra',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Apidra® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Apidra_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Apidra_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Apidra® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Apidra_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Apidra_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Apidra_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Apidra_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Apidra® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Apidra_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Apidra_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Apidra® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Apidra_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Apidra_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Apidra® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Apidra_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Apidra_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Apidra® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Apidra_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Apidra_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Apidra® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Apidra_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Apidra_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Apidra_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Apidra',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Apidra® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Apidra_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Apidra_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Apidra® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Apidra_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Apidra_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Apidra_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Apidra_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Apidra® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Apidra_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Apidra_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Apidra® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Apidra_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Apidra_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Apidra® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Apidra_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Apidra_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Apidra® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Apidra_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Apidra_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Apidra® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Apidra_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Apidra_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Lispro (Humalog®)',
                        displayName: 'Previous - Insulin Lispro (Humalog®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Injection_Humalog',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Humalog_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Humalog',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Humalog® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humalog_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humalog_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Humalog® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humalog_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humalog_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humalog_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humalog_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Humalog® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humalog_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humalog_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humalog® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humalog_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humalog_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humalog® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humalog_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humalog_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humalog® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humalog_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humalog_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Humalog® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humalog_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humalog_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Humalog_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Humalog',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Humalog® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humalog_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humalog_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Humalog® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humalog_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humalog_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humalog_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humalog_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Humalog® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humalog_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humalog_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humalog® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humalog_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humalog_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humalog® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humalog_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humalog_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humalog® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humalog_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humalog_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Humalog® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humalog_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humalog_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Humalog_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Humalog',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Humalog® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humalog_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humalog_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Humalog® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humalog_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humalog_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humalog_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humalog_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Humalog® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humalog_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humalog_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humalog® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humalog_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humalog_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humalog® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humalog_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humalog_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humalog® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humalog_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humalog_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Humalog® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humalog_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humalog_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Aspart (Novorapid®)',
                        displayName: 'Previous - Insulin Aspart (Novorapid®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Injection_Novorapid',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Novorapid_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Novorapid',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Novorapid® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novorapid_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novorapid_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Novorapid® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novorapid_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novorapid_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novorapid_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Novorapid® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novorapid_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novorapid® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novorapid_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novorapid® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novorapid_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novorapid® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novorapid_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Novorapid® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novorapid_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novorapid_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Novorapid',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Novorapid® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novorapid_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Novorapid® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novorapid_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novorapid_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Novorapid® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novorapid® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novorapid® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novorapid® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Novorapid® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novorapid_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Novorapid',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Novorapid® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novorapid_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Novorapid® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novorapid_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novorapid_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Novorapid® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novorapid® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novorapid® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novorapid® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Novorapid® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novorapid_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novorapid_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Aspart (Fiasp®)',
                        displayName: 'Previous - Insulin Aspart (Fiasp®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Injection_Fiasp',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Fiasp_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Fiasp',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Fiasp® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Fiasp_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Fiasp_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Fiasp® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Fiasp_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Fiasp_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Fiasp_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Fiasp® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Fiasp_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fiasp® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Fiasp_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fiasp® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Fiasp_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fiasp® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Fiasp_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Fiasp® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Fiasp_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Fiasp_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Fiasp',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Fiasp® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Fiasp_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Fiasp® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Fiasp_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Fiasp_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Fiasp® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fiasp® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fiasp® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fiasp® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Fiasp® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Fiasp_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Fiasp',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Fiasp® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Fiasp_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Fiasp® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Fiasp_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Fiasp_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Fiasp® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fiasp® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fiasp® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Fiasp® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Fiasp® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Fiasp_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Fiasp_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Aspart (Insulin Aspart Sanofi®)',
                        displayName: 'Previous - Insulin Aspart (Insulin Aspart Sanofi®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Insulin_Aspart_Sanofi® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Insulin_Aspart_Sanofi® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Insulin_Aspart_Sanofi® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Aspart_Sanofi® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Aspart_Sanofi® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Aspart_Sanofi® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Insulin_Aspart_Sanofi® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Insulin_Aspart_Sanofi® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Insulin_Aspart_Sanofi® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Insulin_Aspart_Sanofi® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Aspart_Sanofi® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Aspart_Sanofi® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Aspart_Sanofi® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Insulin_Aspart_Sanofi® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Insulin_Aspart_Sanofi® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Insulin_Aspart_Sanofi® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Insulin_Aspart_Sanofi® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Aspart_Sanofi® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Aspart_Sanofi® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Aspart_Sanofi® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Insulin_Aspart_Sanofi® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Aspart (Novomix®)',
                        displayName: 'Previous - Insulin aspart (Novomix®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Injection_Novomix',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Novomix_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Novomix',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Novomix® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novomix_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novomix_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Novomix® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novomix_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novomix_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novomix_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novomix_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Novomix® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novomix_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novomix_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novomix® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novomix_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novomix_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novomix® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novomix_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novomix_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novomix® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novomix_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novomix_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Novomix® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novomix_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novomix_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Novomix_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Novomix',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Novomix® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novomix_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novomix_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Novomix® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novomix_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novomix_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novomix_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novomix_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Novomix® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novomix_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novomix_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novomix® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novomix_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novomix_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novomix® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novomix_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novomix_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novomix® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novomix_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novomix_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Novomix® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novomix_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novomix_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Novomix_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Novomix',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Novomix® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novomix_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novomix_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Novomix® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novomix_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novomix_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novomix_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novomix_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Novomix® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novomix_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novomix_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novomix® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novomix_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novomix_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novomix® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novomix_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novomix_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Novomix® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novomix_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Novomix_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Novomix® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novomix_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Novomix_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Lispro (Lyumjev®)',
                        displayName: 'Previous - Insulin Lispro (Lyumjev®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Injection_Lyumjev',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Lyumjev',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Lyumjev® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Lyumjev® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Lyumjev® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lyumjev® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lyumjev® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lyumjev® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Lyumjev® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Lyumjev',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Lyumjev® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Lyumjev® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Lyumjev® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lyumjev® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lyumjev® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lyumjev® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Lyumjev® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Lyumjev',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Lyumjev® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Lyumjev® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Lyumjev® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lyumjev® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lyumjev® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lyumjev® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Lyumjev® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lyumjev_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Lispro (Insulin Lispro Sanofi®)',
                        displayName: 'Previous - Insulin Lispro (Insulin Lispro Sanofi®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Insulin_Lispro_Sanofi® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Insulin_Lispro_Sanofi® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Insulin_Lispro_Sanofi® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Lispro_Sanofi® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Lispro_Sanofi® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Lispro_Sanofi® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Insulin_Lispro_Sanofi® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Insulin_Lispro_Sanofi® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Insulin_Lispro_Sanofi® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Insulin_Lispro_Sanofi® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Lispro_Sanofi® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Lispro_Sanofi® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Lispro_Sanofi® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Insulin_Lispro_Sanofi® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Insulin_Lispro_Sanofi® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Insulin_Lispro_Sanofi® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Insulin_Lispro_Sanofi® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Lispro_Sanofi® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Lispro_Sanofi® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulin_Lispro_Sanofi® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Insulin_Lispro_Sanofi® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Human (Humilin®)',
                        displayName: 'Previous - Insulin Human (Humilin®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Injection_Humilin',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Humilin_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Humilin',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Humilin® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humilin_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humilin_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Humilin® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humilin_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humilin_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humilin_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humilin_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Humilin® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humilin_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humilin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humilin® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humilin_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humilin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humilin® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humilin_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humilin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humilin® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humilin_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humilin_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Humilin® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humilin_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humilin_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Humilin_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Humilin',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Humilin® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humilin_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humilin_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Humilin® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humilin_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humilin_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humilin_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humilin_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Humilin® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humilin_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humilin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humilin® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humilin_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humilin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humilin® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humilin_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humilin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humilin® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humilin_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humilin_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Humilin® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humilin_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humilin_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Humilin_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Humilin',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Humilin® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humilin_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humilin_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Humilin® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humilin_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humilin_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humilin_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humilin_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Humilin® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humilin_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humilin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humilin® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humilin_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humilin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humilin® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humilin_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humilin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Humilin® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humilin_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Humilin_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Humilin® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humilin_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Humilin_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Human (Actraphane®)',
                        displayName: 'Previous - Insulin Human (Actraphane®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Injection_Actraphane',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Actraphane_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Actraphane',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Actraphane® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Actraphane_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Actraphane_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Actraphane® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Actraphane_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Actraphane_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Actraphane_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Actraphane® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Actraphane_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actraphane® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Actraphane_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actraphane® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Actraphane_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actraphane® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Actraphane_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Actraphane® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Actraphane_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Actraphane_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Actraphane',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Actraphane® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Actraphane_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Actraphane® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Actraphane_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Actraphane_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Actraphane® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actraphane® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actraphane® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actraphane® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Actraphane® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Actraphane_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Actraphane',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Actraphane® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Actraphane_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Actraphane® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Actraphane_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Actraphane_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Actraphane® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actraphane® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actraphane® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Actraphane® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Actraphane® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Actraphane_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Actraphane_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulan Human (Insulatard®)',
                        displayName: 'Previous - Insulan Human (Insulatard®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Injection_Insulatard',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Insulatard_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Insulatard',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Insulatard® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulatard_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulatard_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Insulatard® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulatard_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulatard_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulatard_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Insulatard® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulatard_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulatard® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulatard_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulatard® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulatard_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulatard® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulatard_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Insulatard® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulatard_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulatard_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Insulatard',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Insulatard® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulatard_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Insulatard® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulatard_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulatard_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Insulatard® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulatard® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulatard® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulatard® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Insulatard® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulatard_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Insulatard',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Insulatard® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulatard_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Insulatard® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulatard_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulatard_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Insulatard® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulatard® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulatard® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Insulatard® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Insulatard® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulatard_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Insulatard_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Aspart/Degludeg (Ryzodeg®)',
                        displayName: 'Previous - Insulin Aspart/Degludeg (Ryzodeg®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Ryzodeg® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ryzodeg® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ryzodeg® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ryzodeg® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ryzodeg® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ryzodeg® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ryzodeg® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Ryzodeg® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ryzodeg® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ryzodeg® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ryzodeg® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ryzodeg® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ryzodeg® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ryzodeg® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Ryzodeg® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ryzodeg® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ryzodeg® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ryzodeg® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ryzodeg® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ryzodeg® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ryzodeg® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Glargine (Lantus®)',
                        displayName: 'Previous - Insulin Glargine (Lantus®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Injection_Lantus',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Lantus_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Lantus',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Lantus® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lantus_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lantus_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Lantus® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lantus_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lantus_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lantus_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lantus_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Lantus® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lantus_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lantus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lantus® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lantus_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lantus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lantus® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lantus_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lantus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lantus® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lantus_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lantus_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Lantus® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lantus_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lantus_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Lantus_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Lantus',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Lantus® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lantus_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lantus_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Lantus® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lantus_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lantus_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lantus_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lantus_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Lantus® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lantus_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lantus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lantus® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lantus_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lantus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lantus® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lantus_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lantus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lantus® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lantus_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lantus_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Lantus® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lantus_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lantus_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Lantus_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Lantus',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Lantus® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lantus_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lantus_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Lantus® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lantus_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lantus_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lantus_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lantus_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Lantus® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lantus_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lantus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lantus® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lantus_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lantus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lantus® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lantus_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lantus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lantus® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lantus_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Lantus_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Lantus® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lantus_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Lantus_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Glargine (Toujeo®)',
                        displayName: 'Previous - Insulin Glargine (Toujeo®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Injection_Toujeo',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Toujeo_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Toujeo',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Toujeo® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Toujeo_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Toujeo_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Toujeo® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Toujeo_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Toujeo_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Toujeo_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Toujeo® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Toujeo_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Toujeo® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Toujeo_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Toujeo® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Toujeo_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Toujeo® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Toujeo_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Toujeo® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Toujeo_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Toujeo_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Toujeo',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Toujeo® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Toujeo_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Toujeo® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Toujeo_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Toujeo_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Toujeo® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Toujeo® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Toujeo® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Toujeo® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Toujeo® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Toujeo_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Toujeo',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Toujeo® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Toujeo_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Toujeo® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Toujeo_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Toujeo_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Toujeo® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Toujeo® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Toujeo® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Toujeo® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Toujeo® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Toujeo_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Toujeo_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Glargine (Abasaglar®)',
                        displayName: 'Previous - Insulin Glargine (Abasaglar®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Injection_Abasaglar',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Abasaglar',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Abasaglar® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Abasaglar® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Abasaglar® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Abasaglar® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Abasaglar® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Abasaglar® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Abasaglar® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Abasaglar',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Abasaglar® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Abasaglar® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Abasaglar® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Abasaglar® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Abasaglar® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Abasaglar® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Abasaglar® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Abasaglar',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Abasaglar® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Abasaglar® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Abasaglar® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Abasaglar® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Abasaglar® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Abasaglar® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Abasaglar® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Abasaglar_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Detemir (Levemir®)',
                        displayName: 'Previous - Insulin Detemir (Levemir®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Injection_Levemir',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Levemir_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Levemir',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Levemir® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Levemir_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Levemir_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Levemir® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Levemir_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Levemir_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Levemir_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Levemir_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Levemir® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Levemir_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Levemir_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Levemir® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Levemir_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Levemir_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Levemir® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Levemir_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Levemir_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Levemir® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Levemir_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Levemir_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Levemir® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Levemir_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Levemir_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Levemir_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Levemir',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Levemir® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Levemir_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Levemir_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Levemir® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Levemir_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Levemir_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Levemir_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Levemir_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Levemir® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Levemir_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Levemir_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Levemir® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Levemir_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Levemir_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Levemir® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Levemir_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Levemir_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Levemir® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Levemir_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Levemir_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Levemir® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Levemir_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Levemir_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Levemir_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Levemir',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Levemir® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Levemir_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Levemir_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Levemir® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Levemir_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Levemir_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Levemir_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Levemir_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Levemir® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Levemir_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Levemir_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Levemir® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Levemir_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Levemir_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Levemir® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Levemir_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Levemir_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Levemir® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Levemir_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Levemir_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Levemir® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Levemir_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Levemir_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Insulin Degludec (Tresiba®)',
                        displayName: 'Previous - Insulin Degludec (Tresiba®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Injection_Tresiba',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Type 2 Diabetes",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Tresiba_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Tresiba',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Tresiba® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tresiba_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tresiba_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tresiba® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tresiba_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tresiba_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tresiba_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tresiba® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tresiba_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tresiba® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tresiba_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tresiba® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tresiba_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tresiba® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tresiba_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tresiba® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tresiba_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tresiba_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Tresiba',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Tresiba® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tresiba_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tresiba® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tresiba_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tresiba_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tresiba® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tresiba® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tresiba® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tresiba® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tresiba® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tresiba_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Tresiba',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Tresiba® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tresiba_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tresiba® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tresiba_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tresiba_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tresiba® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tresiba® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tresiba® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tresiba® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tresiba® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tresiba_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Tresiba_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },



                      {
                        label: 'Corticotropin (Purified Cortrophin Gel®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Injection_Corticotropin',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Corticotropin',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Corticotropin® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Corticotropin® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Corticotropin® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Corticotropin® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Corticotropin® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Corticotropin® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Corticotropin® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Corticotropin',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Corticotropin® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Corticotropin® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Corticotropin® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Corticotropin® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Corticotropin® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Corticotropin® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Corticotropin® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Corticotropin',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Corticotropin® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Corticotropin® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Corticotropin® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Corticotropin® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Corticotropin® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Corticotropin® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Corticotropin® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Corticotropin_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Glatiramer Acetate (Copaxone®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Injection_Copaxone',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Copaxone_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Copaxone',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Copaxone® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Copaxone_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Copaxone_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Copaxone® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Copaxone_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Copaxone_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Copaxone_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Copaxone® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Copaxone_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Copaxone® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Copaxone_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Copaxone® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Copaxone_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Copaxone® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Copaxone_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Copaxone® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Copaxone_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Copaxone_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Copaxone',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Copaxone® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Copaxone_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Copaxone® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Copaxone_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Copaxone_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Copaxone® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Copaxone® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Copaxone® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Copaxone® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Copaxone® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Copaxone_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Copaxone',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Copaxone® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Copaxone_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Copaxone® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Copaxone_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Copaxone_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Copaxone® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Copaxone® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Copaxone® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Copaxone® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Copaxone® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Copaxone_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Copaxone_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Interferon β-1a (Avonex®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Injection_Avonex',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Avonex_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Avonex',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Avonex® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Avonex_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Avonex_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Avonex® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Avonex_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Avonex_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Avonex_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Avonex_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Avonex® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Avonex_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Avonex_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avonex® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Avonex_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Avonex_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avonex® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Avonex_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Avonex_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avonex® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Avonex_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Avonex_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Avonex® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Avonex_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Avonex_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Avonex_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Avonex',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Avonex® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Avonex_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Avonex_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Avonex® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Avonex_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Avonex_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Avonex_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Avonex_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Avonex® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Avonex_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Avonex_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avonex® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Avonex_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Avonex_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avonex® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Avonex_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Avonex_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avonex® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Avonex_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Avonex_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Avonex® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Avonex_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Avonex_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Avonex_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Avonex',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Avonex® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Avonex_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Avonex_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Avonex® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Avonex_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Avonex_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Avonex_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Avonex_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Avonex® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Avonex_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Avonex_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avonex® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Avonex_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Avonex_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avonex® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Avonex_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Avonex_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Avonex® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Avonex_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Avonex_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Avonex® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Avonex_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Avonex_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Interferon β-1a (Rebif®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Injection_Rebif',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Rebif_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Rebif',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Rebif® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rebif_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rebif_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Rebif® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rebif_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rebif_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rebif_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rebif_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Rebif® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rebif_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rebif_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rebif® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rebif_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rebif_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rebif® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rebif_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rebif_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rebif® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rebif_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rebif_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Rebif® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rebif_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rebif_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Rebif_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Rebif',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Rebif® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rebif_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rebif_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Rebif® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rebif_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rebif_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rebif_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rebif_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Rebif® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rebif_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rebif_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rebif® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rebif_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rebif_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rebif® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rebif_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rebif_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rebif® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rebif_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rebif_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Rebif® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rebif_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rebif_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Rebif_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Rebif',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Rebif® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rebif_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rebif_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Rebif® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rebif_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rebif_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rebif_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rebif_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Rebif® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rebif_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rebif_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rebif® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rebif_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rebif_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rebif® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rebif_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rebif_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Rebif® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rebif_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Rebif_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Rebif® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rebif_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Rebif_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Interferon β-1b (Betaseron®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Injection_Betaseron',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Betaseron_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Betaseron',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Betaseron (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Betaseron_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Betaseron_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Betaseron® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Betaseron_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Betaseron_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Betaseron_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Betaseron® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Betaseron_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Betaseron® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Betaseron_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Betaseron® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Betaseron_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Betaseron® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Betaseron_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Betaseron® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Betaseron_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Betaseron_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Betaseron',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Betaseron® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Betaseron_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Betaseron® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Betaseron_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Betaseron_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Betaseron® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Betaseron® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Betaseron® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Betaseron® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Betaseron® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Betaseron_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Betaseron',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Betaseron® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Betaseron_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Betaseron® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Betaseron_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Betaseron_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Betaseron® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Betaseron® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Betaseron® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Betaseron® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Betaseron® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Betaseron_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Betaseron_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Interferon β-1b (Extavia®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Injection_Extavia',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Extavia_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Extavia',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Extavia® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Extavia_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Extavia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Extavia® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Extavia_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Extavia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Extavia_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Extavia_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Extavia® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Extavia_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Extavia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Extavia® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Extavia_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Extavia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Extavia® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Extavia_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Extavia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Extavia® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Extavia_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Extavia_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Extavia® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Extavia_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Extavia_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Extavia_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Extavia',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Extavia® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Extavia_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Extavia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Extavia® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Extavia_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Extavia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Extavia_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Extavia_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Extavia® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Extavia_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Extavia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Extavia® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Extavia_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Extavia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Extavia® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Extavia_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Extavia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Extavia® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Extavia_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Extavia_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Extavia® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Extavia_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Extavia_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Extavia_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Extavia',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Extavia® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Extavia_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Extavia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Extavia® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Extavia_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Extavia_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Extavia_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Extavia_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Extavia® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Extavia_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Extavia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Extavia® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Extavia_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Extavia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Extavia® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Extavia_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Extavia_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Extavia® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Extavia_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Extavia_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Extavia® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Extavia_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Extavia_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Ofatumumab (Kesimpta®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Injection_Kesimpta',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Kesimpta',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Kesimpta® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Kesimpta® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Kesimpta® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Kesimpta® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Kesimpta® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Kesimpta® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Kesimpta® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Kesimpta',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Kesimpta® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Kesimpta® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Kesimpta® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Kesimpta® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Kesimpta® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Kesimpta® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Kesimpta® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Kesimpta',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Kesimpta® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Kesimpta® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Kesimpta® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Kesimpta® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Kesimpta® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Kesimpta® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Kesimpta® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Kesimpta_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Peginterferon β‑1a (Plegridy®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Injection_Plegridy',
                        parent: 'Therapies_Previous_Pharmaceutical_Injection',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Plegridy_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Plegridy',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Plegridy® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Plegridy_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Plegridy_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Plegridy® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Plegridy_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Plegridy_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Plegridy_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Plegridy® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Plegridy_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Plegridy® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Plegridy_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Plegridy® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Plegridy_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Plegridy® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Plegridy_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Plegridy® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Plegridy_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Plegridy_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Plegridy',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Plegridy® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Plegridy_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Plegridy® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Plegridy_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Plegridy_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Plegridy® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Plegridy® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Plegridy® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Plegridy® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Plegridy® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Plegridy_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Injection_Plegridy',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Plegridy® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Plegridy_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Plegridy® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Plegridy_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Plegridy_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Plegridy® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Plegridy® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Plegridy® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Plegridy® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Plegridy® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Injection_Plegridy_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Injection_Plegridy_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    label: 'Infusion',
                    multiSelect: false,
                    value: 'Therapies_Previous_Pharmaceutical_Infusion',
                    parent: 'Therapies_Previous_Pharmaceutical',
                    children: [
                      {
                        label: 'No other',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Infusion_No other',
                        parent: 'Therapies_Previous_Pharmaceutical_Infusion',
                        displayName: 'No other infusion',
                        children: [],
                      },

                      {
                        label: 'Efgartigimod Alpha (Vyvgart®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart',
                        parent: 'Therapies_Previous_Pharmaceutical_Infusion',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Vyvgart® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Vyvgart® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Vyvgart® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vyvgart® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vyvgart® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vyvgart® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Vyvgart® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Vyvgart® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Vyvgart® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Vyvgart® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vyvgart® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vyvgart® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vyvgart® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Vyvgart® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Vyvgart® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Vyvgart® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Vyvgart® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vyvgart® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vyvgart® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Vyvgart® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Vyvgart® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Eculuzimab (Soliris®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Infusion_Soliris',
                        parent: 'Therapies_Previous_Pharmaceutical_Infusion',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Soliris_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Soliris',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Soliris® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Soliris_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Soliris_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Soliris® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Soliris_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Soliris_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Soliris_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Soliris® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Soliris_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Soliris® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Soliris_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Soliris® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Soliris_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Soliris® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Soliris_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Soliris® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Soliris_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Soliris_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Soliris',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Soliris® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Soliris_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Soliris® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Soliris_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Soliris_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Soliris® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Soliris® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Soliris® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Soliris® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Soliris® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Soliris_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Soliris',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Soliris® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Soliris_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Soliris® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Soliris_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Soliris_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Soliris® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Soliris® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Soliris® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Soliris® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Soliris® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Soliris_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Soliris_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Ravulizumab (Ultomiris®)',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris',
                        parent: 'Therapies_Previous_Pharmaceutical_Infusion',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Ultomiris® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ultomiris® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ultomiris® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ultomiris® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ultomiris® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ultomiris® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ultomiris® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Ultomiris® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ultomiris® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ultomiris® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ultomiris® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ultomiris® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ultomiris® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ultomiris® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Ultomiris® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ultomiris® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ultomiris® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ultomiris® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ultomiris® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ultomiris® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ultomiris® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Nipocalimab®',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab',
                        parent: 'Therapies_Previous_Pharmaceutical_Infusion',
                        belongsTo: "Myasthenia Gravis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Nipocalimab® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Nipocalimab® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Nipocalimab® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nipocalimab® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nipocalimab® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nipocalimab® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Nipocalimab® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Nipocalimab® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Nipocalimab® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Nipocalimab® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nipocalimab® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nipocalimab® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nipocalimab® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Nipocalimab® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Nipocalimab® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Nipocalimab® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Nipocalimab® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nipocalimab® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nipocalimab® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Nipocalimab® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Nipocalimab® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },

                      {
                        label: 'Alemtuzumab (Lemtrada®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada',
                        parent: 'Therapies_Previous_Pharmaceutical_Infusion',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Lemtrada® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Lemtrada® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Lemtrada® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lemtrada® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lemtrada® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lemtrada® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Lemtrada® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Lemtrada® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Lemtrada® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Lemtrada® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lemtrada® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lemtrada® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lemtrada® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Lemtrada® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Lemtrada® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Lemtrada® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Lemtrada® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lemtrada® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lemtrada® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Lemtrada® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Lemtrada® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Mitoxantrone®',
                        multiSelect: false,
                        value:
                          'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone',
                        parent: 'Therapies_Previous_Pharmaceutical_Infusion',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Mitoxantrone® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Mitoxantrone® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Mitoxantrone® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mitoxantrone® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mitoxantrone® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mitoxantrone® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Mitoxantrone® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Mitoxantrone® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Mitoxantrone® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Mitoxantrone® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mitoxantrone® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mitoxantrone® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mitoxantrone® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Mitoxantrone® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName:
                                  'Mitoxantrone® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Mitoxantrone® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Mitoxantrone® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mitoxantrone® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mitoxantrone® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Mitoxantrone® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Mitoxantrone® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Natalizumab (Tysabri®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Infusion_Tysabri',
                        parent: 'Therapies_Previous_Pharmaceutical_Infusion',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Tysabri',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Tysabri® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tysabri® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tysabri® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tysabri® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tysabri® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tysabri® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tysabri® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Tysabri',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Tysabri® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tysabri® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tysabri® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tysabri® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tysabri® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tysabri® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tysabri® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Tysabri',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Tysabri® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Tysabri® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Tysabri® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tysabri® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tysabri® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Tysabri® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Tysabri® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Tysabri_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Ocrelizumab (Ocrevus®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus',
                        parent: 'Therapies_Previous_Pharmaceutical_Infusion',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Ocrevus® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ocrevus® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ocrevus® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ocrevus® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ocrevus® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ocrevus® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ocrevus® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Ocrevus® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ocrevus® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ocrevus® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ocrevus® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ocrevus® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ocrevus® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ocrevus® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Ocrevus® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Ocrevus® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Ocrevus® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ocrevus® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ocrevus® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Ocrevus® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Ocrevus® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Ublituximab-xiiy (Briumvi®)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Infusion_Briumvi',
                        parent: 'Therapies_Previous_Pharmaceutical_Infusion',
                        belongsTo: "Multiple Sclerosis",
                        children: [
                          {
                            label: 'First therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_First therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Briumvi',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Briumvi® (Previous - 1st therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_First_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Briumvi® (Previous - 1st therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_First_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Briumvi® (Previous - 1st therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_First_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Briumvi® (Previous - 1st therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_First_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Briumvi® (Previous - 1st therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_First_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Briumvi® (Previous - 1st therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_First_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Briumvi® (Previous - 1st therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_First_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Second therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Second therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Briumvi',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Briumvi® (Previous - 2nd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Second_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Briumvi® (Previous - 2nd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Second_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Briumvi® (Previous - 2nd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Second_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Briumvi® (Previous - 2nd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Second_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Briumvi® (Previous - 2nd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Second_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Briumvi® (Previous - 2nd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Second_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Briumvi® (Previous - 2nd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Second_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_First therapy',
                                children: [],
                              },
                            ],
                          },
                          {
                            label: 'Third therapy',
                            multiSelect: false,
                            value:
                              'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Third therapy',
                            parent:
                              'Therapies_Previous_Pharmaceutical_Infusion_Briumvi',
                            children: [
                              {
                                label: 'Main therapy',
                                multiSelect: false,
                                displayName: 'Briumvi® (Previous - 3rd therapy) Main',
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Third_Therapy_Main',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Simultaneously Started',
                                displayName:
                                  'Briumvi® (Previous - 3rd therapy) Simultaneously started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Third_Therapy_Simultaneously_Started',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_First therapy',
                                children: [],
                              },
                              {
                                label: 'Additionally Started',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Additionally',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_First therapy',
                                children: [
                                  {
                                    displayName:
                                      'Briumvi® (Previous - 3rd therapy) Additionally 1st',
                                    label: 'First',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Third_Therapy_Additionally_Started_1st',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Briumvi® (Previous - 3rd therapy) Additionally 2nd',
                                    label: 'Second',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Third_Therapy_Additionally_Started_2nd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Briumvi® (Previous - 3rd therapy) Additionally 3rd',
                                    label: 'Third',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Third_Therapy_Additionally_Started_3rd',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Additionally',
                                    children: [],
                                  },
                                  {
                                    displayName:
                                      'Briumvi® (Previous - 3rd therapy) Additionally Unspecified',
                                    label: 'Unspecified',
                                    multiSelect: false,
                                    value:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Third_Therapy_Additionally_Started_Unspecified',
                                    parent:
                                      'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Additionally',
                                    children: [],
                                  },
                                ],
                              },
                              {
                                displayName:
                                  'Briumvi® (Previous - 3rd therapy) Unspecified',
                                label: 'Unspecified',
                                multiSelect: false,
                                value:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_Third_Therapy_Unspecified',
                                parent:
                                  'Therapies_Previous_Pharmaceutical_Infusion_Briumvi_First therapy',
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                label: 'Surgical',
                multiSelect: false,
                value: 'Therapies_Previous_Surgical',
                parent: 'Therapies_Previous',
                children: [
                  {
                    label: 'Scalpel Incisions',
                    displayName: 'Previous - Scalpel Incisions',
                    multiSelect: false,
                    value: 'Therapies_Previous_Surgical_Scalpel',
                    parent: 'Therapies_Previous_Surgical',
                    children: [],
                  },
                  {
                    displayName: 'Previous - Laparoscopic',
                    label: 'Laparoscopic',
                    multiSelect: false,
                    value: 'Therapies_Previous_Surgical_Laparoscopic',
                    parent: 'Therapies_Previous_Surgical',
                    children: [],
                  },
                  {
                    displayName: 'Previous - Suture Closure',
                    label: 'Suture Closure',
                    multiSelect: false,
                    value: 'Therapies_Previous_Surgical_Suture',
                    parent: 'Therapies_Previous_Surgical',
                    children: [],
                  },
                ],
              },
              {
                label: 'Rehabilitation',
                multiSelect: false,
                value: 'Therapies_Previous_Rehabilitation',
                parent: 'Therapies_Previous',
                children: [
                  {
                    displayName: 'Previous - Physical Therapy',
                    label: 'Physical Therapy',
                    multiSelect: false,
                    value: 'Therapies_Previous_Rehabilitation_Physical',
                    parent: 'Therapies_Previous_Rehabilitation',
                    children: [],
                  },
                  {
                    displayName: 'Previous - Occupational Therapy',
                    label: 'Occupational Therapy',
                    multiSelect: false,
                    value: 'Therapies_Previous_Rehabilitation_Occupational',
                    parent: 'Therapies_Previous_Rehabilitation',
                    children: [],
                  },
                  {
                    displayName: 'Previous - Speech and Language',
                    label: 'Speech and Language',
                    multiSelect: false,
                    value: 'Therapies_Previous_Rehabilitation_Speech',
                    parent: 'Therapies_Previous_Rehabilitation',
                    children: [],
                  },
                ],
              },
              {
                label: 'Psychological',
                multiSelect: false,
                value: 'Therapies_Previous_Psychological',
                parent: 'Therapies_Previous',
                children: [
                  {
                    displayName: 'Previous - Cognitive Behavioral Therapy (CBT)',
                    label: 'Cognitive Behavioral Therapy (CBT)',
                    multiSelect: false,
                    value: 'Therapies_Previous_Psychological_ (CBT)',
                    parent: 'Therapies_Previous_Psychological',
                    children: [],
                  },
                  {
                    displayName: 'Previous - Humanistic Therapy',
                    label: 'Humanistic Therapy',
                    multiSelect: false,
                    value: 'Therapies_Previous_Psychological_Humanistic',
                    parent: 'Therapies_Previous_Psychological',
                    children: [],
                  },
                  {
                    displayName: 'Previous - Psychodrama',
                    label: 'Psychodrama',
                    multiSelect: false,
                    value: 'Therapies_Previous_Psychological_Psychodrama',
                    parent: 'Therapies_Previous_Psychological',
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            label: 'No other therapies',
            multiSelect: true,
            displayName: 'No other therapies',
            value: 'Therapies_No_Other_Therapies',
            parent: 'Therapies',
            children: [],
          },
          {
            label: 'All Therapies',
            multiSelect: true,
            displayName: 'All Therapies',
            value: 'Therapies_All therapies',
            parent: 'Therapies',
            children: [],
          },
          {
            label: 'No Therapies',
            multiSelect: true,
            displayName: 'No Therapies',
            value: 'Therapies_No therapies',
            parent: 'Therapies',
            children: [],
          },
        ],
      },
      {
        label: 'Applications',
        multiSelect: true,
        value: 'Applications',
        children: [
          {
            label: 'Health',
            multiSelect: false,
            value: 'Applications_Health',
            parent: 'Applications',
            children: [
              {
                label: 'Apple Health',
                multiSelect: false,
                value: 'Applications_Health_Apple',
                parent: 'Applications_Health',
                children: [
                  {
                    label: 'Steps',
                    multiSelect: true,
                    value: 'Applications_Health_Apple_Steps',
                    parent: 'Applications_Health_Apple',
                    children: [],
                  },
                  {
                    label: 'Flights Climbed',
                    multiSelect: true,
                    value: 'Applications_Health_Apple_FlightsClimbed',
                    parent: 'Applications_Health_Apple',
                    children: [],
                  },
                  {
                    label: 'Sleep',
                    multiSelect: true,
                    value: 'Applications_Health_Apple_Sleep',
                    parent: 'Applications_Health_Apple',
                    children: [],
                  },
                ],
              },
              {
                label: 'Google Fit',
                multiSelect: false,
                value: 'Applications_Health_Google',
                parent: 'Applications_Health',
                children: [
                  {
                    label: 'Steps',
                    multiSelect: true,
                    value: 'Applications_Health_Google_Steps',
                    parent: 'Applications_Health_Google',
                    children: [],
                  },
                  {
                    label: 'Elevation',
                    multiSelect: true,
                    value: 'Applications_Health_Google_Elevation',
                    parent: 'Applications_Health_Google',
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            label: 'Location',
            multiSelect: false,
            value: 'Applications_Location',
            parent: 'Applications',
            children: [
              // {
              //   label: 'Google Timeline',
              //   multiSelect: false,
              //   value: 'Applications_Location_Google_Timeline',
              //   parent: 'Applications_Location',
              //   children: [],
              // },
              {
                label: 'Google Location History',
                multiSelect: false,
                value: 'Applications_Location_History',
                parent: 'Applications_Location',
                children: [
                  {
                    label: 'Raw',
                    multiSelect: false,
                    value: 'Applications_Location_History_Raw',
                    parent: 'Applications_Location_History',
                    children: [],
                  },
                  {
                    label: 'Activity segments',
                    multiSelect: false,
                    value: 'Applications_Location_History_Activity segments',
                    parent: 'Applications_Location_History',
                    children: [],
                  },
                  {
                    label: 'Place visits',
                    multiSelect: false,
                    value: 'Applications_Location_History_Place visits',
                    parent: 'Applications_Location_History',
                    children: [],
                  },
                  {
                    label: 'Number of places visited',
                    multiSelect: false,
                    value: 'Applications_Location_History_Number of places visited',
                    parent: 'Applications_Location_History',
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: 'Questionnaires',
        multiSelect: true,
        value: 'Questionnaires',
        children: [
          {
            label: 'Quality of Life',
            multiSelect: false,
            value: 'Questionnaires_Quality',
            parent: 'Questionnaires',
            children: [
              {
                label: 'SF-36',
                multiSelect: false,
                value: 'Questionnaires_Quality_SF-36',
                parent: 'Questionnaires_Quality',
                children: [],
              },
              {
                label: 'MSQOL-54',
                multiSelect: false,
                value: 'Questionnaires_Quality_MSQOL-54',
                parent: 'Questionnaires_Quality',
                children: [],
              },
            ],
          },
        ],
      },
      {
        label: 'Analysis',
        multiSelect: true,
        value: 'Analysis',
        children: [
          {
            label: 'Genetic Test',
            multiSelect: false,
            value: 'Analysis_Genetic',
            parent: 'Analysis',
            children: [
              {
                label: 'HLA-DRB1*15:01',
                multiSelect: false,
                value: 'Analysis_Genetic_HLA-DRB1*15:01',
                parent: 'Analysis_Genetic',
                children: [],
              },
            ],
          },
          {
            label: 'Radiology',
            multiSelect: false,
            value: 'Analysis_Radiology',
            parent: 'Analysis',
            children: [
              {
                label: 'Radiology opt1',
                multiSelect: false,
                value: 'Analysis_Radiology_Radiology 1',
                parent: 'Analysis_Radiology',
                children: [],
              },
            ],
          },
          {
            label: 'Lab',
            multiSelect: false,
            value: 'Analysis_Lab',
            parent: 'Analysis',
            children: [
              {
                label: 'Lab opt1',
                multiSelect: false,
                value: 'Analysis_Lab_Lab 1',
                parent: 'Analysis_Lab',
                children: [],
              },
            ],
          },
        ],
      },
      {
        label: 'Prediction',
        multiSelect: true,
        value: 'Prediction',
        children: [
          {
            label: 'Predict',
            multiSelect: false,
            value: 'Prediction_Predict',
            parent: 'Prediction',
            children: [],
          },
        ],
      },
    ];

  return dataItemsForTherapies;
};

export default dataItemsForTherapies;
