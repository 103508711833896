import React from 'react';
import PropTypes from 'prop-types';
import './style.scss'; 
import { Loader } from '../../components/Loader';

const CustomButton = ({ label, onClick, color, textcolor, type , width, float, disabled, isLoading, backgroundColor, height, display, alignItems, justifyContent}) => {
  return (
    <button
      className={`custom-button`} style={{backgroundColor:`${backgroundColor}`, color: `${textcolor}`, width: `${width}`, float: `${float}`,height: `${height}`, display: `${display}`, justifyContent: `${justifyContent}`, alignItems: `${alignItems}` }} 
      onClick={onClick}
      type={type} 

      disabled={disabled || isLoading} 
    >
 {isLoading ? <Loader /> : label}
    </button>
  );
};


export default React.memo(CustomButton);
