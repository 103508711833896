import React, { useEffect, useState, useCallback } from "react";
import { Container, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.scss";
// import BottomTab from "../../components/bottomtab/BottomTab";
import PhoneInput from "react-phone-number-input";
import cat2 from "../../assets/images/cat2.jpg";
import { Images } from "../../assets/images";
import {
  useGetUserQuery,
  useCreatePaymentMethodMutation,
  useRemovePaymentMethodMutation,
  useCardDetailsMutation,
} from "../../store/api";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Loader } from "../../components/Loader";
import Form from "react-bootstrap/Form";
import CustomButton from "../../components/custombutton/CustomButton";
import { useFormik } from "formik";
import * as Yup from "yup";

import CardSection from "./CardSection";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Modal from "react-modal";

import toast from "react-hot-toast";

const WalletPage = () => {
  const [
    CardDetails,
    {
      isError: isCardDetailsError,
      isSuccess: isCardDetailsSuccess,
      refetch: refetchForCardDetails,
    },
  ] = useCardDetailsMutation();

  useEffect(() => {
    if (isCardDetailsSuccess) {
      toast.success("Card Details Added!");
      refetchForCardDetails();
    } else if (isCardDetailsError) {
      toast.error("Error Adding Card Details");
    }
  }, [isCardDetailsSuccess, isCardDetailsError]);

  const userRole = useSelector((state) => state.auth?.user?.user?.role);
  const isBuyer = userRole === "buyer"; // Replace with your first condition
  const isSeller = userRole === "seller";

  const elements = useElements();
  const [
    createPaymentMethod,
    { isSuccess: isPaymentSuccess, isError: isPaymentError, isLoading },
  ] = useCreatePaymentMethodMutation();

  const [
    RemovePaymentMethodMutation,
    {
      isSuccess: removeCardSuccess,
      isError: removeCardError,
      isLoading: removeCardLoading,
    },
  ] = useRemovePaymentMethodMutation();

  const stripe = useStripe();
  // const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state?.auth?.user?.user?.id);

  const { data, isSuccess, isError, refetch, error } =
    useGetUserQuery(userData);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isRemoveCardModalOpen, setisRemoveCardModalOpen] = useState(false);
  const [OpenSellerModal, setOpenSellerModal] = useState(false);

  const handleSellerModal = () => {
    setOpenSellerModal(true);
  };

  const handleModal = useCallback(() => {
    setModalOpen(true);
  }, [isModalOpen]);

  const handleCloseRemoveCardButton = useCallback(() => {
    setisRemoveCardModalOpen(false);
  }, [isRemoveCardModalOpen]);

  const handleRemoveCardModal = useCallback(() => {
    setisRemoveCardModalOpen(true);
  }, [isRemoveCardModalOpen]);

  useEffect(() => {
    if (isPaymentSuccess) {
      toast.success("payment method added!");
      refetch();
    } else if (isPaymentError) {
      alert("paymment error");
    }
  }, [isPaymentError, isPaymentSuccess]);

  useEffect(() => {
    if (isSuccess) {
      // console.log("data", data);
    } else if (isError) {
      console.log(error.data.message);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (removeCardSuccess) {
      refetch(userData);
      toast.success("Card Removed Successfully!");
    } else if (removeCardError) {
      toast.error("Error removing card!");
    }
  }, [removeCardSuccess, removeCardError]);

  const handleRemoveButton = useCallback(async () => {
    await RemovePaymentMethodMutation();
    setisRemoveCardModalOpen(false);
  }, [RemovePaymentMethodMutation, setisRemoveCardModalOpen]);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      // setLoading(true); // Start loading

      // console.log('stripe:: ', stripe);
      // console.log('elements ', elements);
      const { paymentMethod, error: errorpayment } =
        await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
        });

      // console.log('error::', error);

      if (errorpayment) {
        // Show error to your customer (for example, insufficient funds)
        toast.error(error.message);
      } else {
        const paymentMethodPayloadd = {
          paymentMethodPayload: paymentMethod,
        };
        await createPaymentMethod(paymentMethodPayloadd);
        // console.log('paymentMethodPayloadd', paymentMethodPayloadd);
        setModalOpen(false);
      }
    },
    [stripe, elements, createPaymentMethod, setModalOpen]
  );

  const paymentVerified = data?.paymentDetails?.isPaymentVerified;
  // console.log("paymentVerified", paymentVerified);

  // const paymentVerified = false;

  const initialValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",

    city: "",
    postalcode: "",
    line1: "",

    dob: "",
    accountNumber: "",
  };

  const onSubmit = async (individual) => {
    const valuesToSend = {
      individual: {
        firstName: individual.firstName,
        lastName: individual.lastName,
        phoneNumber: individual.phoneNumber,
        email: individual.email,

        address: {
          city: individual.city,
          postalcode: individual.postalcode,
          line1: individual.line1,
        },
        dob: individual.dob,
        external_account: {
          accountNumber: individual.accountNumber,
        },
      },
    };
    await CardDetails(valuesToSend);
    setOpenSellerModal(false);
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    phoneNumber: Yup.string().required("Phone Number is required"),
    email: Yup.string().required("Email is required"),
    city: Yup.string().required("City is required"),
    postalcode: Yup.string().required("Postal Code is required"),
    line1: Yup.string().required("Line Number is required"),
    dob: Yup.string().required("Date of birth is required"),
    accountNumber: Yup.string().required("Account Numberis required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <>
      <main id="main">
        <section className="wallet_sec1">
          <Container>
            <h1>Wallet</h1>
            <Card className="balance-card">
              <Card.Body
                className="balance-card-body"
                style={{ height: "100px" }}
              >
                <div className="bal">
                  <h3> Balance</h3>
                  <p>0</p>
                </div>
                <div>
                  <a href="#">
                    <i className="fas fa-chevron-right"></i>
                  </a>
                </div>
              </Card.Body>
            </Card>
          </Container>
        </section>

        <div>
          {paymentVerified ? (
            <section className="wallet_sec mb-5">
              <Container className="container2">
                <h2>Payment Method</h2>
                <Card className="mb-1">
                  <Card.Body>
                    <div>
                      <p
                        style={{ cursor: "pointer", fontWeight: "bold" }}
                        onClick={handleRemoveCardModal}
                        className="d-flex align-items-center"
                      >
                        <img
                          src={Images.wallet2}
                          alt=""
                          width={"35px"}
                          className="img-fluid me-3"
                        />
                        **** {data?.paymentDetails?.last4}
                      </p>

                      <Modal
                        isOpen={isRemoveCardModalOpen}
                        onRequestClose={() => setisRemoveCardModalOpen(false)}
                        style={{
                          overlay: {
                            backgroundColor: "rgba(0, 0, 0, 0.6)",
                          },
                          content: {
                            width: "59vw",
                            margin: "auto",
                            height: "30vh",
                            padding: "20px",
                          },
                        }}
                      >
                        <div className="close">
                          {removeCardLoading ? (
                            <Loader />
                          ) : (
                            <span style={{ fontWeight: "bold" }}>
                              Do you want to remove card?{" "}
                            </span>
                          )}

                          <div className="close1">
                            <button
                              className="closeremovecardbutton"
                              onClick={handleCloseRemoveCardButton}
                            >
                              No
                            </button>

                            <button
                              className="closeremovecardbutton"
                              onClick={handleRemoveButton}
                              style={{ backgroundColor: "red" }}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      </Modal>
                    </div>
                    <div>
                      <a href="#" className="arrow-icon">
                        <i className="fas fa-chevron-right"></i>
                      </a>
                    </div>
                  </Card.Body>
                </Card>
              </Container>
            </section>
          ) : (
            <div className="adddetails">
              {/* <button onClick={handleModal}>Add Card Details</button> */}
              {/* <button
                className="add-card"
                color={"#fffff"}
                textcolor={"white"}
                // width={"0%"}
                onClick={isBuyer ? handleModal : handleSellerModal}
              >
                "Add Card Details"

              </button> */}

              {/* <div
                className="addCard"
                
              >
                <h2 className="addCard-text">Add Payment Method</h2>

                <a href="#" className="arrow-icon">
                  <i className="fas fa-chevron-right"></i>
                </a>
              </div> */}
              <section className="method-sec">
                <Container>
                  <Card
                    className="addMethod-card"
                    onClick={isBuyer ? handleModal : handleSellerModal}
                  >
                    <Card.Body
                      className="addMethod-card-body"
                      style={{ height: "100px" }}
                    >
                      <div>
                        <h3> Add Payment Method</h3>
                      </div>
                      <div>
                        <a href="#">
                          <i className="fas fa-chevron-right"></i>
                        </a>
                      </div>
                    </Card.Body>
                  </Card>
                </Container>
              </section>

              {!isCardDetailsSuccess ? (
                <Modal
                  isOpen={OpenSellerModal}
                  onRequestClose={() => setOpenSellerModal(false)}
                  style={{
                    overlay: {
                      backgroundColor: "rgba(0, 0, 0, 0.6)",
                    },
                    content: {
                      width: "45vw",
                      margin: "auto",
                      height: "80vh",
                      padding: "20px",
                    },
                  }}
                >
                  <div className="container-form">
                    <Form
                      className="AcceptBidForm"
                      style={{ paddingTop: "40px" }}
                      onSubmit={formik.handleSubmit}
                    >
                      <h2 style={{ textAlign: "center" }}>
                        Please Enter Details for Card
                      </h2>
                      <Form.Group>
                        <Form.Label className="label">First Name</Form.Label>
                        <Form.Control
                          placeholder="Your first name"
                          type="text"
                          name="firstName"
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.firstName && formik.errors.firstName ? (
                          <div className="error">{formik.errors.firstName}</div>
                        ) : null}
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="label">Last Name</Form.Label>
                        <Form.Control
                          placeholder="Your last name"
                          type="text"
                          name="lastName"
                          value={formik.values.lastName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.lastName && formik.errors.lastName ? (
                          <div className="error">{formik.errors.lastName}</div>
                        ) : null}
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="label">Phone Number</Form.Label>

                        <PhoneInput
                          className="react-phone-input"
                          placeholder="Enter phone number"
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry="US"
                          name="phoneNumber"
                          value={formik.values.phoneNumber}
                          onChange={(value) =>
                            formik.setFieldValue("phoneNumber", value)
                          }
                          onBlur={formik.handleBlur}
                        />

                        {formik.touched.phoneNumber &&
                        formik.errors.phoneNumber ? (
                          <div className="error">
                            {formik.errors.phoneNumber}
                          </div>
                        ) : null}
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="label">Email</Form.Label>
                        <Form.Control
                          placeholder="Your first email"
                          type="email"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.phoneNumber && formik.errors.email ? (
                          <div className="error">{formik.errors.email}</div>
                        ) : null}
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="label">Address</Form.Label>
                        <div className="address">
                          <Form.Control
                            placeholder="Your city"
                            type="text"
                            name="city"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.city && formik.errors.city ? (
                            <div className="error">{formik.errors.city}</div>
                          ) : null}
                          <Form.Control
                            placeholder="Your postal code"
                            type="text"
                            name="postalcode"
                            value={formik.values.postalcode}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.phoneNumber &&
                          formik.errors.postalcode ? (
                            <div className="error">
                              {formik.errors.postalcode}
                            </div>
                          ) : null}
                          <Form.Control
                            placeholder="Your Line 1"
                            type="text"
                            name="line1"
                            value={formik.values.line1}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.line1 && formik.errors.line1 ? (
                            <div className="error">{formik.errors.line1}</div>
                          ) : null}
                        </div>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="label">Date of Birth</Form.Label>
                        <Form.Control
                          placeholder="Your first email"
                          type="date"
                          name="dob"
                          value={formik.values.dob}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.dob && formik.errors.dob ? (
                          <div className="error">{formik.errors.dob}</div>
                        ) : null}
                      </Form.Group>
                      <Form.Group>
                        <Form.Label className="label">
                          Account Number
                        </Form.Label>
                        <Form.Control
                          placeholder="Your Account Number"
                          type="text"
                          name="accountNumber"
                          value={formik.values.accountNumber}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.accountNumber &&
                        formik.errors.accountNumber ? (
                          <div className="error">
                            {formik.errors.accountNumber}
                          </div>
                        ) : null}
                      </Form.Group>

                      <CustomButton
                        type="submit"
                        label="Submit"
                        color="#5b99a6"
                        textcolor="white"
                        width="27vw"
                      />

                      <CustomButton
                        type="submit"
                        label="Close"
                        color="#5b99a6"
                        textcolor="white"
                        onClick={() => {
                          setOpenSellerModal(false);
                        }}
                        width="27vw"
                      />
                    </Form>
                  </div>
                </Modal>
              ) : (
                <p
                  style={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={handleRemoveCardModal}
                  className="d-flex align-items-center"
                >
                  <img
                    src={Images.wallet2}
                    alt=""
                    width={"35px"}
                    className="img-fluid me-3"
                  />
                  ****
                  {/* {data?.paymentDetails?.last4} */}
                </p>
              )}

              <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setModalOpen(false)}
                style={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                  },

                  content: {
                    width: "59vw",
                    margin: "auto",
                    height: "55vh",
                    padding: "20px",
                    borderRadius: "10px",
                  },
                }}
              >
                <div className="verifypayment">
                  <h2 className="add-card">Add Card Details</h2>
                </div>
                <form onSubmit={handleSubmit}>
                  <CardSection />
                  <div className="addCard-buttons">
                    <button className="confirmbutton" disabled={!stripe}>
                      {isLoading ? <Loader /> : "Confirm Order"}
                    </button>
                  </div>
                </form>
                <div className="addCard-buttons">
                  <button
                    className="closebutton"
                    onClick={() => {
                      setModalOpen(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </Modal>
            </div>
          )}
        </div>
      </main>
    </>
  );
};

export default React.memo(WalletPage);
