import React from 'react';
import { Images } from '../../assets/images';
import './style.scss';
import { Card, Col, Container, Row, CardImg } from 'react-bootstrap';

export const HowItWorks = () => {
  const cards1 = [
    {
      image: Images.helooo,
      title: <strong>1. Sign up</strong>,
      body: (
        <p>
          {' '}
          Download the application, sign up, and select which data you want to share.
        </p>
      ),
    },
    {

      image: Images.signupp,
      title: <strong>2. Contribute</strong>,
      body: (
        <p>
          {' '}

          Grow the value of the network by sharing your data directly with users.


        </p>
      ),
    },
    {

      
      image:  Images.engage,
      title: <strong>3. Benefit</strong>,
      body: <p> Gain valuable personal insights through the network effects of the platform.

      </p>,
    },
  ];
  const cards2 = [
    {
      image: Images.signup2,
      title: <strong>1. Sign up</strong>,
      body: (
        <p>
          {' '}
          Download the application, sign up, and select data of interest.
          </p>
      ),
    },
    {
      image: Images.request,
      title: <strong>2. Contribute</strong>,
      body: (
        <p>
          {' '}
          Grow the value of the network by requesting data directly from users.
          </p>
      ),
    },
    {
      image: Images.access,
      title: <strong>3. Benefit</strong>,
      body: (
        <p>
          {' '}
          Leverage the network effects of data at scale.
        </p>
      ),
    },
  ];
  return (
    <div className="main-container">
      <img className="coverimage" src={Images.howitworks} alt="sadsa" />
      <div class="text-overlay">

      Unlock personal data by sharing and requesting directly between users.

      </div>

      {/* <div className="info">
        <Container>
          <div className="sharedata">
            <h1>Easily Share Data</h1>
          </div>
          <Card.Body>
            <Row>
              {cards1.map((card) => (
                <Col xs={12} md={4} lg={4}>
                  <Card style={{ border: 'none', boxShadow: 'none' }}>
                    <CardImg className="card-img-custom" variant="top" src={card.image} alt="Card image" />
                    <Card.Header style={{ fontSize: "larger" }}>{card.title}</Card.Header>
                    <Card.Body>
                      <Card.Text style={{ color: "rgba(117,117,117)", fontSize: "larger" }}>{card.body}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Container>

        <hr />


        <Container>
          <div className="buydata">
            <h1>Easily Request Data</h1>
          </div>

          <Card.Body>
            <Row>
              {cards2.map((card) => (
                <Col xs={12} md={4} lg={4}>
                  <Card style={{ border: 'none', boxShadow: 'none' }}>
                    <CardImg className="card-img-custom" variant="top" src={card.image} alt="Card image" />
                    <Card.Header style={{ fontSize: "larger" }}>{card.title}</Card.Header>
                    <Card.Body>
                      <Card.Text style={{ color: "rgba(117,117,117)", fontSize: "larger" }}>{card.body}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Container>
      </div> */}
    </div>
  );
};