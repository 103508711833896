import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import * as Yup from "yup"; // Import Yup
import CustomTextField from "../../components/customtextfield/CustomTextField";
import CustomButton from "../../components/custombutton/CustomButton";
import RouteNames from "../../routes/RouteNames";
import { Images } from "../../assets/images";
import "./style.scss";

import { Loader } from "../../components/Loader";
import {
  useCreateBidMutation,
  useGetMyBidsQuery,
  useLoginMutation,
} from "../../store/api";
import { setUser } from "../../store/reducers/authSlice";

import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import socket from "../../store/actions/socket";
import { setUserIdForTracking, trackEvent } from "../../utils/amplitude";
import { Events } from "../../utils/events";


export default function Login() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1); // Navigate to the previous page
  };

  const [privacyChecked, setPrivacyChecked] = useState(false);

  const handlePrivacyChange = (event) => {
    setPrivacyChecked(event.target.checked);
  };

  const [loginMutation, { isError, isSuccess, isLoading, error, data }] =
    useLoginMutation();

  const [createBidMutation, { isError: err, isSuccess: suc, error: e }] =
    useCreateBidMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess) {
      dispatch(setUser(data));
      setUserIdForTracking(data?.user?.username)
      setTimeout(() => {
        trackEvent(Events.USER_LOGIN,{
          username: data?.user?.username,
          role:data?.user?.role,
          deviceType:data?.user?.deviceType,
        })
      }, 500);
      if (
        JSON.parse(localStorage.getItem("createdBid")) &&
        data.user.role === "buyer"
      ) {
        const valuesToSend = JSON.parse(localStorage.getItem("createdBid"));
        createBidMutation(valuesToSend, { fixedCacheKey: "create-bid" });
      } else {
        localStorage.removeItem("createdBid");
        toast.success("welcome!");
        socket.getSocket()
        
        setTimeout(() => {
          navigate(RouteNames.Default.route);
        }, 1000);
      }
    } else if (isError) {
      trackEvent(Events.USER_LOGIN_ERROR,{
        error: error?.data?.message
       })
      toast.error(error?.data?.message);
    }
  }, [isError, isLoading, isSuccess, error]);
  const { refetch } = useGetMyBidsQuery();

  //saving local bid
  useEffect(() => {
    if (err) {
      toast.error(e.data.message);
      toast.error("Your bid wasn't created --TRY AGAIN--");
      localStorage.removeItem("createdBid");

      setTimeout(() => {
        navigate(RouteNames.Default.route);
      }, 500);
    } else if (suc) {
      toast.success(
        "Bid is uploaded that you have created -please re-assign the user"
      );
      // console.log(value.id, "recievinggg");

      refetch();
      localStorage.removeItem("createdBid");

      setTimeout(() => {
        navigate(-2);
      }, 500);
    }
  }, [err, suc, e]);

  const initialValues = {
    username: "",
    password: "",
  };

  // Define validation schema using Yup
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    try {
      const response = await loginMutation(values);
    } catch (error) {
      toast.error(error);
    }

    resetForm();
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div className="login_body">
      <div className="logo">
        <div className="backarrow" onClick={handleClick}>
          <IoMdArrowRoundBack
            size={20}
            style={{ color: "#5B99A6", cursor: "pointer" }}
          />
        </div>

        <img src={Images.logo_} alt="LiberDat" className="logoimage" />
      </div>

      <Form
        className="container1"
        style={{ paddingTop: "40px" }}
        onSubmit={formik.handleSubmit}
      >
        <div style={{ width: "80%"}}>
          <CustomTextField
            name="username"
            label="Username"
            type="text"
            placeholder="Enter your username"
            formik={formik}
          />
          <div className="mt-2" />
          <CustomTextField
            name="password"
            label="Password"
            type="password"
            placeholder="Enter your password"
            formik={formik}
          />
          <div className="login-link">
            <p
              onClick={() => {
                navigate(RouteNames.ForgetPassword.route);
              }}
            >
              Forgot your password?
            </p>
            <br />
          </div>
        </div>

        {isLoading ? (
          <div className="loader-container">
            <Loader />
          </div>
        ) : (
          <CustomButton
            style={{ paddingBottom: "15px" }}
            label={"Login"}
            color={"#333333"}
            textcolor={"white"}
            backgroundColor={"#333333"}
            type="submit"
            width="75%"
          />
        )}
      </Form>
      <p>
        Create a new Account?&nbsp;
        <span
          onClick={() => navigate(RouteNames.Signup.route)}
          style={{ color: "#5B99A6", cursor: "pointer" }}
        >
          Sign up
        </span>
      </p>
      <p>
        <img src={Images.liberdaticon} alt="LiberDat" className="lowerimage" />
      </p>
    </div>
  );
}
