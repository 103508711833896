import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Dropdown, Form } from "react-bootstrap";
import { Tooltip } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import "bootstrap/dist/css/bootstrap.min.css";
import StepsFlights from "./stepsFlights";
import Skeleton from "react-loading-skeleton";
import { IoIosSettings } from "react-icons/io";
import { AiOutlineUserAdd } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { IoIosInformationCircle } from "react-icons/io";
import Customcard from "../../components/customcard/Customcard";
import Modal from "react-modal";
import "./style.scss";
import {
  useLazyGetMyBidsQuery,
  useGetAllQuestionnaireQuery,
  useAssignFromSurveyTitleMutation,
  useConfigureMutation,
  useUpdateSellersGraphNotificationsMutation
} from "../../store/api";

import RouteNames from "../../routes/RouteNames";
import { useSelector } from "react-redux";
import { Loader } from "../../components/Loader";
import CustomDropdown from "../../components/customdropdown/CustomDropDown";

function MyBids() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSettingModalOpen2, setIsSettingModalOpen2] = useState(false);
  const [isSettingModalOpen3, setIsSettingModalOpen3] = useState(false);
  const [isSettingModalOpen4, setIsSettingModalOpen4] = useState(false);
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
  const [bidInfo, setBidInfo] = useState("");

  const [showCustomCard, setShowCustomCard] = useState(false);
  const [selectedUsersRef, setSelectedUsersRef] = useState(null);

  const openUserModal = (assignedUsers) => {
    setSelectedUsersRef(assignedUsers);
    setShowCustomCard(true);
  };


  const [isflightsClimbed, setIsflightsClimbed] = useState(false);
  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);
  const [selectedBid, setSelectedBid] = useState();
  const [loadingButtonId, setLoadingButtonId] = useState(null);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [checkboxValues, setCheckboxValues] = useState("");
  const [Configure, { isSuccess:isSuccessAbsolute, isError:isErrorAbsolute, isLoading:isLoadingAbsolute }] = useConfigureMutation();
  const [UpdateSellers, { isSuccess:isSuccessUpdateSellers, isError:isErrorUpdateSellers, isLoading:isLoadingUpdateSellers }] = useUpdateSellersGraphNotificationsMutation();
useEffect(() => {
  
  if (isSuccessUpdateSellers) {
    toast.success('Notification sent to all Sharers of this bid')
  }
  }, [isSuccessUpdateSellers]);

  
  useEffect(() => {
    if (isSuccessAbsolute) {
      // toast.success('Configuration Successful');
      getData();
      const valueConfigMap = {
        'Applications_Health_Apple_Steps': 'steps',
        'Applications_Health_Google_Steps': 'steps',
        'Applications_Health_Google_Elevation': 'elevation',
        'Applications_Health_Apple_FlightsClimbed': 'flights',
        'Applications_Location_History_Number of places visited': 'noOfPlacesVisited',
        'Applications_Location_History_Place visits': 'placesVisited'
    };

    const allConfigured = selectedBid?.dataItem?.specifiedValues.every(item => {
        const key = valueConfigMap[item.value];
        if (key) {
            const configured = selectedBid?.configure?.[key];
            console.log(`Checking ${item.value}: ${configured}`);
            return configured;
        }
        return true;
    });

    if (allConfigured) {
        navigate(RouteNames.AssignUser.route, {
              state: { selectedBid },
            })// replace 'NextPage' with your actual route name
    } else {  toast.success('Configuration Successful');

    }
 
      setTimeout(() => { setIsModalOpen(false) }, 100);
    } else if (isErrorAbsolute) {
      toast.error('Configuration Unsuccessful');
    }
  }, [isSuccessAbsolute,isErrorAbsolute]);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const openDescriptionModal = (info) => {
    setBidInfo(info);
    setIsDescriptionModalOpen(true);
  };

  const closeDescriptionModal = () => {
    setBidInfo("No Description Available");
    setIsDescriptionModalOpen(false);
  };
  const handleSelect = (bid, selected) => {
    setSelectedOption(selected);
    setSelectedBid(bid);
    openModal();
  };


  const navigate = useNavigate();
  const [getData, { data, isLoading, isError, isSuccess }] =
    useLazyGetMyBidsQuery({
      fetchPolicy: "network-only", // Always fetch from the network
    });
  const extractLastWord = useCallback((str) => {
    const words = str?.split("_");
    const lastThree = words?.slice(-1);
    return lastThree?.join(" ") ?? "";
  }, []);
  const {
    data: AllQuestionnairesData,
    isSuccess: AllQuestionnairesSuccess,
    refetch: AllQuestionnairesRefetch,
  } = useGetAllQuestionnaireQuery();
  const [
    AssignBid,
    {
      // data: AssignFromSurveyTitleData,
      isSuccess: AssignFromSurveyTitleSuccess,
      // isLoading: AssignLoading,
    },
  ] = useAssignFromSurveyTitleMutation();



  const [mappingdata, setMappingdata] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedBidDetails, setSelectedBidDetails] = useState(null);

  console.log("mapping data:", mappingdata);
  const user = useSelector((state) => state?.auth?.user?.user);

  useEffect(() => {
    if (isSuccess) {
      setMappingdata(data);
    } else if (isError) {
    }
  }, [isSuccess, isError, mappingdata, data, AssignFromSurveyTitleSuccess]);

  useEffect(() => {
    if (AllQuestionnairesSuccess) {
      AllQuestionnairesRefetch();
    }
  }, [AllQuestionnairesData, AllQuestionnairesSuccess]);

  useEffect(() => {
    if (AssignFromSurveyTitleSuccess) {
      toast.success("Selected Survey Assigned");
    }
  }, [AssignFromSurveyTitleSuccess]);



  const handleSettingsModal = (bid) => {
    setSelectedBidDetails(bid);
    setIsSettingModalOpen(true);
  };

  const AssignSurvey = (bid, item) => {
    const bidId = bid._id;
    const questionnaireId = item._id;
    setLoadingButtonId(bidId);
    AssignBid({ bidId, questionnaireId })
      .unwrap()
      .then((response) => {
        setLoadingButtonId(null);
      })
      .catch((error) => {
        console.error("Error assigning survey:", error);

        setLoadingButtonId(null);
      });
  };
  const handleNewQuestionnaire = (bid) => {
    try {
      navigate(RouteNames.Survey.route, {
        state: { bidId: bid._id },
      });
    } catch (error) {
      console.error("Error navigating:", error);
    }
  };

  useEffect(() => {
    if (user) {
      getData();
    } else {
      // console.log("in else");
      let arr = [];
      arr.push(JSON.parse(localStorage.getItem("createdBid") || "[]"));
      setMappingdata(arr);
      // console.log(mappingdata);
    }
  }, [user, AssignFromSurveyTitleSuccess]);

  const initialValues = {
    medical: "",
  };

  const formik = useFormik({
    initialValues,
  });

  const dropdownFlightClimbedRef = useRef(null);

  const handleMedicalSelect = (value) => {
    formik.setFieldValue("medical", value);
    toggleDropdownMed(); // Close the dropdown after selection

    setIsSettingModalOpen(false);

    setIsSettingModalOpen2(!isSettingModalOpen2);
  };

  const toggleDropdownMed = () => {
    setIsflightsClimbed(!isflightsClimbed);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownFlightClimbedRef.current &&
        !dropdownFlightClimbedRef.current.contains(event.target)
      ) {
        setIsflightsClimbed(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      if (dropdownFlightClimbedRef.current) {
        document.removeEventListener("click", handleOutsideClick);
      }
    };
  }, [dropdownFlightClimbedRef]);

  // const handleSelect = (eventKey) => {
  //   switch (eventKey) {
  //     case 'questionnaire':
  //       // Handle the selection for 'Questionnaire'
  //       // console.log('Selected Questionnaire');
  //       break;
  //     case 'newQuestionnaire':
  //       // Handle the selection for 'New Questionnaire'
  //       // console.log('Selected New Questionnaire');
  //       break;
  //     case 'steps':
  //       // Handle the selection for 'Steps'
  //       // console.log('Selected Steps');
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const flightsClimbedItems = [
    { label: "Flights Climbed", value: "Flights Climbed" },
  ];

  return (
    <>
      <main id="main">
        <section className="my_bids_sec">
          <Container style={{ width: "100%" }}>
            <h1>
              {isLoading ? (
                <>
                  <Skeleton className="mb-3" height={150} count={10} />{" "}
                </>
              ) : (
                "My Requests"
              )}
            </h1>

            <Row>
              {mappingdata && mappingdata[0] !== "" ? (
                mappingdata.map((bid) => (
                  <Col key={bid.id} lg={12} className="mb-3">
                    <div className="bid-list-item">
                      <div className="title">
                        <p className="bidtitle">{bid?.diagnosis}</p>

                        {/* Uncomment the following lines if needed */}
                        {/* 
                      <p>
                        <strong>Duration:</strong> {bid?.duration?.number}{' '}
                        {bid?.duration?.interval}
                      </p>
                    */}

                        <p>
                          <strong>Data: </strong>{" "}
                          {bid?.dataItem?.specifiedValues?.reduce(
                            (acc, click) => {
                              if (
                                click?.value?.includes?.("Therapies") ||
                                click?.value?.includes?.("Apple") ||
                                click?.value?.includes?.("Google") ||
                                click?.value?.includes?.("Questionnaire") ||
                                click?.value?.includes?.("Location")
                              ) {
                                if (acc.length > 0) {
                                  if (
                                    acc?.includes?.("Therapies") &&
                                    click?.value?.includes?.("Therapies")
                                  ) {
                                    return acc;
                                  } else {
                                    return (
                                      acc + ", " + extractLastWord(click?.value)
                                    );
                                  }
                                } else {
                                  if (click?.value?.includes?.("Therapies")) {
                                    return extractLastWord("Therapies");
                                  } else {
                                    return extractLastWord(click?.value);
                                  }
                                }
                              } else {
                                return acc;
                              }
                            },
                            ""
                          )}
                        </p>
                        <p>
                          <strong>Price:</strong> {bid?.price?.amount===0?'Free': bid?.price?.amount + bid?.price?.currency}
                          
                        </p>
                        <p>
                          <strong>Date(Created Bid):</strong>{" "}
                          {bid?.createdAt
                            ? new Date(bid?.createdAt).toLocaleDateString()
                            : ""}
                        </p>
                      </div>
                      <div className="Settings">
                        <div className="button">
                          <div className="bold-text-green text-end mt-2">
                            {bid.assignedUsers}
                            <AiOutlineUserAdd
                              size={25}
                              // onClick={() => {
                              //   const assignedUsersRef =
                              //     mappingdata.find(
                              //       (item) => item._id === bid._id
                              //     )?.assignedUsersRef || [];
                              //   console.log(
                              //     "assignedUserRef",
                              //     assignedUsersRef
                              //   );
                              //   setSelectedUsersRef(assignedUsersRef);
                              //   setShowCustomCard(true);
                              //   openUserModal(assignedUsersRef);
                              // }}
                              
                              className="bid-icons"
                            />
                          </div>
                          <div style={{ marginTop: "-5px" }}>
                            <button
                              style={{ boxShadow: "none", padding: "inherit" }}
                              onClick={() =>{
                                const valueConfigMap = {
                                  'Applications_Health_Apple_Steps': 'steps',
                                  'Applications_Health_Google_Steps': 'steps',
                                  'Applications_Health_Google_Elevation': 'elevation',
                                  'Applications_Health_Apple_FlightsClimbed': 'flights',
                                  'Applications_Location_History_Number of places visited': 'noOfPlacesVisited',
                                  'Applications_Location_History_Place visits': 'placesVisited'
                              };
                          
                              const allConfigured = bid?.dataItem?.specifiedValues.every(item => {
                                  const key = valueConfigMap[item.value];
                                  if (key) {
                                      const configured = bid?.configure?.[key];
                                      console.log(`Checking ${item.value}: ${configured}`);
                                      return configured;
                                  }
                                  return true;
                              });
                          
                              if (allConfigured && !bid?.dataItem?.specifiedValues?.some(i=>i.value?.includes('Questionnaires')) ) {
                                  console.log('All values configured, navigating to next page');
                                  navigate(RouteNames.AssignUser.route, {
                                        state: { bid },
                                      })
                              }
                              else if(allConfigured && (bid?.dataItem?.specifiedValues?.some(i=>i.value?.includes('Questionnaire'))&& 
                              bid?.surveyTitle)){
                                navigate(RouteNames.AssignUser.route, {
                                  state: { bid },
                                })
                              }
                               else {
                                  console.log('Some values need to be configured');
                                  toast.error('Selected Bid needs to be configured');
                              }
                              //   if(bid?.dataItem?.specifiedValues?.some(i=>i.value?.includes?.('Steps') && bid?.configure?.steps))
                              //   navigate(RouteNames.AssignUser.route, {
                              //     state: { bid },
                              //   })
                              }
                                
                              }
                              className="configurebutton"
                            >
                              Users
                            </button>
                            {loadingButtonId === bid._id ? (
                              <Loader color="black" />
                            ) : (
                              // bid?.source?.questionnaire?.length !== 0 && (
                              <Dropdown>
                                <Dropdown.Toggle
                                  className="configurebutton"
                                  style={{
                                    boxShadow: "none",
                                    padding: "inherit",
                                    backgroundColor: "black",
                                    border: "none",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Configure
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {bid?.dataItem?.specifiedValues?.some((i) =>
                                    i?.value?.includes(
                                      "Applications_Health_Apple_Steps"
                                    )
                                  ) && (
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleSelect(
                                          bid,
                                          "Applications_Health_Apple_Steps"
                                        )
                                      }
                                      disabled={bid?.configure?.steps}
                                    >
                                      Steps{" "}
                                      {bid?.configure?.steps ? (
                                        <span className="bold-text-green">
                                          {" "}
                                          {"  "} Configured
                                        </span>
                                      ) : null}
                                    </Dropdown.Item>
                                  )}
                                  {bid?.dataItem?.specifiedValues?.some((i) =>
                                    i?.value?.includes(
                                      "Applications_Health_Google_Steps"
                                    )
                                  ) && (
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleSelect(
                                          bid,
                                          "Applications_Health_Google_Steps"
                                        )
                                      }
                                      disabled={bid?.configure?.steps}
                                    >
                                      Steps{" "}
                                      {bid?.configure?.steps ? (
                                        <span className="bold-text-green">
                                          {" "}
                                          Configured
                                        </span>
                                      ) : null}
                                    </Dropdown.Item>
                                  )}
                                  {bid?.dataItem?.specifiedValues?.some((i) =>
                                    i?.value?.includes(
                                      "Applications_Health_Google_Elevation"
                                    )
                                  ) && (
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleSelect(
                                          bid,
                                          "Applications_Health_Google_Elevation"
                                        )
                                      }
                                      disabled={bid?.configure?.elevation}
                                    >
                                      Elevation{" "}
                                      {bid?.configure?.elevation ? (
                                        <span className="bold-text-green">
                                          {" "}
                                          Configured
                                        </span>
                                      ) : null}
                                    </Dropdown.Item>
                                  )}
                                  {bid?.dataItem?.specifiedValues?.some((i) =>
                                    i?.value?.includes(
                                      "Applications_Health_Apple_FlightsClimbed"
                                    )
                                  ) && (
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleSelect(
                                          bid,
                                          "Applications_Health_Apple_FlightsClimbed"
                                        )
                                      }
                                      disabled={bid?.configure?.flights}
                                    >
                                      Flights Climbed{" "}
                                      {bid?.configure?.flights ? (
                                        <span className="bold-text-green">
                                          {" "}
                                          Configured
                                        </span>
                                      ) : null}
                                    </Dropdown.Item>
                                  )}

                                  {/* {(bid?.dataItem?.specifiedValues?.some(i=>i?.value?.includes('Applications_Health_Apple_Sleep'))) && (
                                    <Dropdown.Item
                                      onClick={() => handleSelect(bid, "Sleep")} 
                                    >
                                      Sleep
                                    </Dropdown.Item>
                                  )} */}
                                  {bid?.dataItem?.specifiedValues?.some((i) =>
                                    i?.value?.includes(
                                      "Applications_Location_History_Number of places visited"
                                    )
                                  ) && (
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleSelect(
                                          bid,
                                          "Applications_Location_History_Number of places visited"
                                        )
                                      }
                                      disabled={
                                        bid?.configure?.noOfPlacesVisited
                                      }
                                    >
                                      Number of places visited{" "}
                                      {bid?.configure?.noOfPlacesVisited ? (
                                        <span className="bold-text-green">
                                          {" "}
                                          Configured
                                        </span>
                                      ) : null}
                                    </Dropdown.Item>
                                  )}
                                  {bid?.dataItem?.specifiedValues?.some((i) =>
                                    i?.value?.includes(
                                      "Applications_Location_History_Place visits"
                                    )
                                  ) && (
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleSelect(
                                          bid,
                                          "Applications_Location_History_Place visits"
                                        )
                                      }
                                      disabled={bid?.configure?.placesVisited}
                                    >
                                      Places visited{" "}
                                      {bid?.configure?.placesVisited ? (
                                        <span className="bold-text-green ">
                                          {" "}
                                          Configured
                                        </span>
                                      ) : null}
                                    </Dropdown.Item>
                                  )}
                                  {/* {item?.dataItem?.specifiedValues?.some(i=>i?.value?.includes('Applications_Location_History_Number of places visited'))?
            <TouchableOpacity
              style={styles.configureButton}
              onPress={item?.configure?.noOfPlacesVisited ?null:() => handleConfigureOptionSelect('Applications_Location_History_Number of places visited')}>
                 <Text style={styles.configureText}>Number of places visited  {item?.configure?.noOfPlacesVisited ? <Text style={styles.configured}>   Configured</Text>: null}</Text>
            </TouchableOpacity> : null} */}
                                  {/* <StepsFlights
                                    // isOpen={isModalOpen}
                                    isOpen={false}

                                    onRequestClose={() => setIsModalOpen(false)}
                                    selectedOption={selectedOption}
                                    bid={selectedBid}
                                  /> */}

                                  {/* {bid?.specifiedValues?.includes(
                                    "Applications_Health_Apple_FlightsClimbed"
                                  ) && (
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleSelect(bid, "flightsClimbed")
                                      } // Pass the bid and the selected option
                                    >
                                      Flights Climbed
                                    </Dropdown.Item>
                                  )} */}
                                  {bid?.dataItem?.specifiedValues?.some((i) =>
                                    i?.value?.includes("Questionnaire")
                                  ) && (
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleNewQuestionnaire(bid)
                                      }
                                      eventKey="newQuestionnaire"
                                    >
                                      New Questionnaire
                                    </Dropdown.Item>
                                  )}
                                  {AllQuestionnairesData?.length>0 && (
                                  <Dropdown.Item
                                    style={{ fontWeight: "bold" }}
                                    disabled
                                  >
                                    Survey Titles
                                  </Dropdown.Item>)}

                                  {AllQuestionnairesData?.map((item, index) => (
                                    <Dropdown.Item
                                      key={index}
                                      onClick={() => AssignSurvey(bid, item)}
                                      eventKey={item.surveyTitle}
                                      style={{
                                        fontWeight:
                                          bid?.surveyTitle === item?.surveyTitle
                                            ? "bold"
                                            : "normal",
                                      }}
                                    >
                                      {item.surveyTitle}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                              // )
                            )}
                          </div>
                        </div>
                        <div className="bid-settings">
                          <IoIosInformationCircle
                            size={25}
                            onClick={() =>
                              openDescriptionModal(bid?.information)
                            }
                            className="bid-icons"
                          />

                          <FaRegEdit
                            size={24}
                            onClick={() => {}}
                            className="bid-icons"
                          />
                          <IoIosSettings
                            size={26}
                            // onClick={() => handleSettingsModal(bid)}
                            className="bid-icons"
                          />
                          <MdDelete
                            // bid={bid}
                            size={26}
                            // onClick={() => handleSettingsModal(bid)}
                            className="bid-icons"
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                ))
              ) : (
                <>You haven't uploaded any bids</>
              )}

              <Modal
                isOpen={isSettingModalOpen}
                onRequestClose={() => {
                  setIsSettingModalOpen(false);
                  setSelectedBidDetails(null); // Reset the selectedBidDetails when closing the modal
                }}
                style={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                  },
                  content: {
                    width: "fit-content",
                    margin: "auto",
                    height: "45vh",
                    padding: "20px",
                  },
                }}
              >
                <div className="configure-moda">
                  <Row>
                    <h2>Configure</h2>
                    <Col xs={12}>
                      <Form.Label
                        style={{ fontWeight: "bold", marginTop: "6px" }}
                      >
                        Health
                      </Form.Label>

                      {/* <div
                        className="custom-select-medical"
                        onClick={toggleDropdownMed}
                        ref={dropdownFlightClimbedRef}
                      > */}

                      <CustomDropdown
                        id="medical"
                        name="medical"
                        options={flightsClimbedItems}
                        formik={formik}
                        onSelect={(value) => {
                          if (value === "Flights Climbed") {
                            handleMedicalSelect(value);
                          }
                        }}
                      />
                      {/* {formik.values.medical || "Select an option"}
                        {isflightsClimbed ? (
                          <IoIosArrowUp style={{ marginLeft: "2px" }} />
                        ) : (
                          <IoIosArrowDown style={{ marginLeft: "2px" }} />
                        )}
                      </div>
                      {isflightsClimbed && (
                        <div className="medical-dropdown">
                          <div
                            className="medical-dropdown-items"
                            onClick={() => handleMedicalSelect("")}
                          >
                            Select an option
                          </div>
                          <div
                            className="medical-dropdown-items"
                            onClick={() =>
                              handleMedicalSelect("Flights Climbed")
                            }
                          >
                            Flights Climbed
                          </div>
                        </div>
                      )} */}
                    </Col>

                    <Col xs={12}>
                      <Form.Label
                        style={{ fontWeight: "bold", marginTop: "6px" }}
                      >
                        Questionnaire
                      </Form.Label>

                      <div className="questionnaire">
                        <p
                          id="TooltipExample"
                          style={{
                            marginBottom: "7px",
                            cursor: "pointer",
                            width: "15vw",
                          }}
                          onClick={toggle}
                        >
                          New Questionnaire
                          <Tooltip
                            isOpen={tooltipOpen}
                            target="TooltipExample"
                            autohide={false}
                            toggle={toggle}
                          >
                            You can create questionnaire through Liberdat
                            website
                          </Tooltip>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>

                {/* {selectedBidDetails && (
                  <div>
                    <h2>{selectedBidDetails.episode}</h2>
                    <p>
                      <strong>Price:</strong> {selectedBidDetails.price?.amount}
                      {selectedBidDetails.price?.currency}
                    </p>
                    
                  </div>
                )} */}
                <button
                  className="closebutton"
                  onClick={() => {
                    setIsSettingModalOpen(false);
                  }}
                >
                  Close
                </button>
              </Modal>

              <Modal
                // isOpen={isSettingModalOpen2}
                isOpen={isModalOpen}
                // onRequestClose={() => setIsSettingModalOpen2(false)}
                onRequestClose={() => setIsModalOpen(false)}
                style={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                  },
                  content: {
                    width: "30vw",
                    margin: "auto",
                    height: "fit-content",
                    padding: "20px",
                    borderRadius: "10px",
                  },
                }}
              >
                <h2>{extractLastWord(selectedOption)}</h2>

                <div className="checkboxes" style={{ padding: "22px" }}>
                  <Form.Check
                    type="checkbox"
                    id="absoluteCheckbox"
                    label="Absolute"
                    onClick={() => {
                      const vts={
                        configType: 'Absolute',
                        name:selectedOption,
                        }
                      console.log("VTS",vts)
                      Configure({ vts, id: selectedBid?._id });
                      // navigate(RouteNames.AssignUser.route)
                    }}
                  />
                  <Form.Check
                    type="checkbox"
                    id="relativeCheckbox"
                    label="Relative"
                    onClick={() => {
                      setIsSettingModalOpen3(true);
                      // setIsSettingModalOpen2(false);
                      setIsModalOpen(false);
                    }}
                  />
                </div>

                {/* <button
                  className="closebutton"
                  onClick={() => {
                    setIsSettingModalOpen2(false);
                  }}
                >
                  Close
                </button> */}
              </Modal>

              <Modal
                isOpen={isSettingModalOpen3}
                onRequestClose={() => setIsSettingModalOpen3(false)}
                style={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                  },
                  content: {
                    width: "30vw",
                    margin: "auto",
                    height: "fit-content",
                    padding: "20px",
                    borderRadius: "10px",
                  },
                }}
              >
                <h2>Base Time</h2>
                <div className="checkboxes" style={{ padding: "22px" }}>
                  <Form.Check
                    type="checkbox"
                    id="Present"
                    value="Present"
                    label="Present"
                    onClick={() => {
                      setIsSettingModalOpen4(true);
                      setIsSettingModalOpen3(false);
                    }}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setCheckboxValues(e.target.value);
                      }
                    }}
                  />
                  {selectedBid?.dataItem?.specifiedValues?.some((i) =>
                    i?.value?.includes("Analysis")
                  ) && (
                    <Form.Check
                      type="checkbox"
                      id="Date of Analysis"
                      label="Date of Analysis"
                      value="Date of Analysis"
                      onClick={() => {
                        setIsSettingModalOpen4(true);
                        setIsSettingModalOpen3(false);
                      }}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setCheckboxValues(e.target.value);
                        }
                      }}
                    />
                  )}

                  <Form.Check
                    type="checkbox"
                    id="Date of Diagnosis"
                    label="Date of Diagnosis"
                    value="Date of Diagnosis"
                    onClick={() => {
                      setIsSettingModalOpen4(true);
                      setIsSettingModalOpen3(false);
                    }}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setCheckboxValues(e.target.value);
                      }
                    }}
                  />
                  {(selectedBid?.dataItem?.specifiedValues?.some((i) =>
                    i?.value?.includes("Therapies_Current")
                  ) ||
                    selectedBid?.dataItem?.specifiedValues?.some((i) =>
                      i?.value?.includes("Therapies_Previous")
                    )) && (
                    <Form.Check
                      type="checkbox"
                      id="Date of Therapy start"
                      label="Date of Therapy start"
                      value="Date of Therapy start"
                      onClick={() => {
                        setIsSettingModalOpen4(true);
                        setIsSettingModalOpen3(false);
                      }}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setCheckboxValues(e.target.value);
                        }
                      }}
                    />
                  )}
                </div>

                {/* <button
                  className="closebutton"
                  onClick={() => {
                    setIsSettingModalOpen3(false);
                  }}
                >
                  Close
                </button> */}
              </Modal>
              <Modal
                isOpen={isDescriptionModalOpen}
                onRequestClose={closeDescriptionModal}
                ariaHideApp={false}
                style={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    zIndex: 1000,
                  },
                  content: {
                    top: "50%", // Centered vertically
                    left: "50%", // Centered horizontally
                    transform: "translate(-50%, -50%)",
                    border: "none",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    zIndex: 9999,
                  },
                }}
              >
                <div className="description-content">
                  <h2>Information</h2>
                  <p>{bidInfo}</p>
                </div>
              </Modal>

              <Modal
                isOpen={showCustomCard}
                onRequestClose={() => setShowCustomCard(false)}
                ariaHideApp={false}
                style={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                  content: {
                    position: "relative",
                    width: "500px",
                    height: "500px",
                    margin: "0",
                    padding: "32px",
                    borderRadius: "12px",
                    background: "#ffffff",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    border: "none",
                  },
                }}
              >
                <h2
                  style={{
                    marginTop: 0,
                    marginBottom: "24px",
                    textAlign: "center",
                  }}
                >
                  User Details
                </h2>
                <button
                  onClick={() => setShowCustomCard(false)}
                  style={{
                    position: "absolute",
                    top: "16px",
                    right: "16px",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 6L6 18"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6 6L18 18"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div
                  style={{ maxHeight: "calc(100% - 100px)", overflowY: "auto" }}
                >
                  {selectedUsersRef?.length > 0 ? (
                    selectedUsersRef.map((user, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "16px",
                          padding: "12px",
                          borderRadius: "8px",
                          background: "#f0f0f0",
                        }}
                      >
                        <h5 style={{ margin: 0 }}>{user?.username}</h5>
                        <button
                          onClick={() => {
                            navigate("/user-prediction", { state: { user } });
                          }}
                          style={{
                            padding: "8px 16px",
                            background: "#5b99a6",
                            color: "#ffffff",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                          }}
                        >
                          Predict
                        </button>
                      </div>
                    ))
                  ) : (
                    <p style={{ textAlign: "center", color: "#666" }}>
                      No Users Assigned
                    </p>
                  )}
                  {selectedUsersRef?.length > 0 && (
                    
                  <div style={{width:'100%', justifyContent:'center',display:'flex',position:'absolute',bottom:'25px',left:'0px'}}>
                  <button
                          onClick={async () => {
                            const userIds=selectedUsersRef.map((user, index) => user?._id)

                            const vts={
                              sellers:userIds
                            }
                            await UpdateSellers(vts);
                            setShowCustomCard(false)
                            // console.log(userIds)
                          }}
                          style={{
                            width:'80%',
                            background: "#5b99a6",
                            color: "#ffffff",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                          }}
                        >
                          Notify everyone to update their data
                        </button>
                        </div>)}
                </div>
              </Modal>
              <StepsFlights
                isOpen={isSettingModalOpen4}
                onRequestClose={() => setIsSettingModalOpen4(false)}
                bid={selectedBid}
                getData={getData}
                selectedOption={selectedOption}
                checkboxValues={checkboxValues}
              />
            </Row>
          </Container>
        </section>
      </main>
      {showCustomCard && selectedUsersRef && (
        <Customcard bid={selectedUsersRef} />
      )}
    </>
  );
}

export default React.memo(MyBids);
